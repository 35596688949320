import * as actionTypes from './actionTypes';

export const setSelectedCartTyre = ( selectedTyre, amount ) => ( {
    type: actionTypes.SET_SELECTED_CART_selectedTyre,
    selectedTyre,
    amount
  } );

export const setSelectedCartRims = ( selectedRim, amount ) => ( {
    type: actionTypes.SET_SELECTED_CART_selectedRim,
    selectedRim,
    amount
  } );

export const setSelectedAmount = ( computedSelectionAmount, id ) => ( {
    type: actionTypes.SET_SELECTED_AMOUNT,
    computedAmount: computedSelectionAmount,
    tyreId: id
  } );

export const setShoppingCartTireSelectedAmount = ( amount, tyreId ) => ( {
    type: actionTypes.SET_SHOPPING_CART_TIRE_SELECTED_AMOUNT,
    amount,
    tyreId
  } );

export const setShoppingCartTireChanged = ( tyre ) => ( {
    type: actionTypes.SET_SHOPPING_CART_TIRE_CHANGED,
    selectedTyre: tyre
  } );

export const setShoppingCartRimChanged = ( rim ) => ( {
    type: actionTypes.SET_SHOPPING_CART_RIM_CHANGED,
    selectedRim: rim
} );

export const removeCartTire = index => ( {
    type: actionTypes.DELETE_CART_TIRE,
    cartTireIndex: index
  } );

export const removeCartRim = index => ( {
    type: actionTypes.DELETE_CART_RIM,
    cartRimIndex: index
} );

export const amountSelectionChanged = amount => ( {
    type: actionTypes.AMOUNT_SELECTION_CHANGED,
    amountSelectionChanged: amount
  } );

export const countAmount = () => ( {
    type: actionTypes.AMOUNT_COUNT
  } );

export const updateTotalPrice = ( ) => ( {
    type: actionTypes.UPDATE_TOTAL_PRICE
  } );

export const saveButtonState = state => ( {
    type: actionTypes.SAVE_BUTTON_STATE,
    buttonState: state
  } );

export const resetSc = () => ( {
    type: actionTypes.RESET_SC
  } );

export const createTimeStamp = () => ( {
    type: actionTypes.CREATE_TIME_STAMP
  } );
export const selectedAssemblyCost = ( assemblyCosts, tireId, selectedAssemblyCosts ) => ( {
    type: actionTypes.SELECTED_ASSEMBLY_COST,
    assemblyCosts,
    tireId,
    selectedAssemblyCosts
  } );

export const assemblyCosts = ( assemblyCosts ) => ( {
    type: actionTypes.ASSEMBLY_COSTS,
    assemblyCosts
  } );
