import React from "react";
import {connect} from "react-redux";
import * as actionCreators from "../../../redux-store/actions";
import {NavLink} from "react-router-dom";

class Header extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         burgerMenuOpen: false,
         scTires: this.props.scTires,
         scRims: this.props.scRims,
         totalCount: this.props.totalCount,
         firmData: this.props.firmData
      };
      this.drawerToggleClickHandler = () => {
         this.setState(prevState => {
            return {burgerMenuOpen: !prevState.burgerMenuOpen};
         });
      };
      this.resetSessionStorage = this.resetSessionStorage.bind(this);
   }

   computeAmount() {
      this.props.onAmountCount();
      return this.props.totalCount;
   }

   componentDidMount() {
      this.setNavigation = location => {
         if (this.props.navigation == "/") {
            this.props.onHomeSearch();
         }
         this.props.onSetNavigation(location);
         this.state.burgerMenuOpen === true ? this.drawerToggleClickHandler() : "";
      };

      if (this.props.scTires.length == 0) {
         if (this.props.timestamp == null) {
            this.props.onCreateTimeStamp();
         }
      }
   }

   resetSessionStorage() {
      this.props.onReset();
      this.props.onResetFilterSelections();
   }

   render() {
      let time = new Date().getTime();
      if (this.props.timestamp != null) {
         if (time > this.props.timestamp) {
            this.resetSessionStorage();
         }
      }
      if (this.props.timestamp == null) {
         this.props.onCreateTimeStamp();
      }
      const firmData = (this.props.firmData ? (this.props.firmData.length > 0 && this.props.firmData[0]["AllgemeineDaten"] ? this.props.firmData[0]["AllgemeineDaten"] : "") : (""));

      return (
          <nav className="header navbar navbar-expand-lg navbar-light bg-white">
             {this.props.firmData ?
                 <div className={"container justify-content-between"}>
                    <NavLink to="/" onClick={() => this.setNavigation("/")} className={"navbar-brand"}>
                       <div className={"header-logo"}/>
                    </NavLink>
                    <button className={"navbar-toggler"} type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                       <span className={"navbar-toggler-icon"}/>
                    </button>
                    <div className={"collapse navbar-collapse"} id="navbarNav">
                       <ul className={"navbar-nav ml-auto"}>
                          <li className={"nav-item"}>
                             <NavLink to="/" exact onClick={() => this.setNavigation("/")} className={"nav-link"}>
                                Startseite
                             </NavLink>
                          </li>
                          <li className={"nav-item"}>
                             <NavLink to={this.props.url} onClick={() => this.setNavigation(this.props.url)}
                                      className={"nav-link" + (this.props.loc.includes("/tyre") ? " active" : "")}>
                                Reifen
                             </NavLink>
                          </li>
                          <li className={"nav-item"}>
                             <NavLink to="/rimSearch" onClick={() => this.setNavigation("/rimSearch")} className={"nav-link"}>
                                Felgen
                             </NavLink>
                          </li>
                          <li className={"nav-item"}>
                             <NavLink to="/contact" onClick={() => this.setNavigation("/contact")} className={"nav-link"}>
                                Kontakt
                             </NavLink>
                          </li>
                          <li className={"nav-item"}>
                             <NavLink to="/shoppingcart" onClick={() => this.setNavigation("/shoppingcart")}
                                      className={"nav-link pr-0"}>
                                Warenkorb
                                <div className={"ml-3 d-inline-block position-relative"}>
                                   <i className="fas fa-shopping-cart"/>
                                   <span className={"amount" + (this.computeAmount() < 10 ? " single" : " multi")}>
                                    {this.computeAmount()}
                                 </span>
                                </div>
                             </NavLink>
                          </li>
                       </ul>
                    </div>
                 </div>
                 :
                 <div className={"container"}>
                    <NavLink to="/" onClick={() => this.setNavigation("/")}>
                       <div className={"header-logo"}/>
                    </NavLink>
                 </div>
             }
          </nav>
      );
   }

}

const mapStateToProps = state => ({
       scTires: state.shoppingcart.shoppingCartTyres,
       scRims: state.shoppingcart.shoppingCartRims,
       totalCount: state.shoppingcart.totalCount,
       firmData: state.firmData,
       url: state.routeChanged.url,
       timestamp: state.shoppingcart.timestamp
    }

);

const mapDispatchToProps = dispatch => {
   return {
      dispatch,
      onAmountCount: () => dispatch(actionCreators.countAmount()),
      onSetNavigation: loc => dispatch(actionCreators.setNavigation(loc)),
      onHomeSearch: () => dispatch(actionCreators.homeSearch()),
      onReset: () => dispatch(actionCreators.resetSc()),
      onResetFilterSelections: () => dispatch(actionCreators.resetFilterSelections()),
      onCreateTimeStamp: () => dispatch(actionCreators.createTimeStamp())

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
