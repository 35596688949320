import React from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import RimFilter from './components/RimFilter';
import Rims from './components/Rims';
import CarView from './components/CarView';
import {createBrowserHistory} from 'history';
import * as actionCreators from '../../../../redux-store/actions';
import {getCarColors, getCarDetails, getRimDetails, getRims} from '../../../../redux-store/api';


let history;
let timeStamp = Date.now();

class SelectRim extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         rims: null,
         carId: this.props.match.params.id,
         rimId: this.props.match.params.rimId,
         hiddenHandler: false,
         filter: null,
         arr: [],
         loadingRims: false,
         filterSelection: [],
         loading: true,
         data: [],
         error: null,
         carDetails: null,
         color: null,
         rimSize: null,
         limit: 14,
         offset: 0,
         filterHider: false,
         selectedSize: null,
         isFavoriteView: false,
         enableSwitch: false,
         specificRimId: null,
         imgSrc: '/auto.png',
         filterLoader: false,
         rimPath: '',
         loadPage: 14,
         filteredRimsFound: true,
         fetchRims: false,
         lowering: 0,
         images: {
            backgroundImageUrl: null,
            rimsImageUrl: null,
            foregroundImageUrl: null
         },
         base64img: null
      };
      this.fetchRims = this.fetchRims.bind(this);
      this.fetchCarDetails = this.fetchCarDetails.bind(this);
      this.fetchCarColors = this.fetchCarColors.bind(this);
      this.createCarCanvas = this.createCarCanvas.bind(this);
      this.rimSizeCallBack = this.rimSizeCallBack.bind(this);
      this.favRimCallBack = this.favRimCallBack.bind(this);
      this.loadMore = this.loadMore.bind(this);
      this.filterSelectionCallBack = this.filterSelectionCallBack.bind(this);
      this.filterCallBack = this.filterCallBack.bind(this);
      this.carImageCallback = this.carImageCallback.bind(this);
      this.loweringCallback = this.loweringCallback.bind(this);

      this.selectRim = (rim, rimSizeId, previewOnly) => {
         let carId = this.state.carId;
         let rimId = rim.rimId;
         this.rimHandler(rimId, carId, rimSizeId, false, previewOnly ? previewOnly : '');
         if (!previewOnly) {
            history.push(this.state.rimPath + `/car/${carId}/${rimId}`);
         }
         this.setState({
            selectedRimId: rimId,
            specificRimId: rimSizeId ? rimSizeId : this.state.specificRimId,
            loading: true,
            filterHider: previewOnly ? false : true,
         }, this.setRimsImageUrl);
      };
   }

   componentDidMount() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");
      this.scrollToTop()
      // If Internet Explorer
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
         this.setState({isIE: true});
      }
      history = createBrowserHistory();
      // Suche ohne Filter
      this.fetchRims();

      let reqBody = {
         rimId: this.state.rimId,
         carId: this.state.carId
      };
      if (this.state.carDetails === null) {
         this.fetchCarDetails(reqBody);
         this.fetchCarColors(reqBody);
      }

      if (process.env.REACT_APP_CLUB == "ps-rims" || process.env.REACT_APP_CLUB == "am-rims") {
         this.setState({rimPath: ''});
      } else if (process.env.REACT_APP_CLUB == "points" || process.env.REACT_APP_CLUB == "automeister") {
         this.setState({rimPath: '/rimconfigurator'});
      }
      this.setBackgroundImageUrl();
      // this.setForegroundImageUrl();
   }

   scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
   }

   getImages() {
      this.setBackgroundImageUrl();
      this.setRimsImageUrl();
      this.setForegroundImageUrl();
   }

   setBackgroundImageUrl() {
      const backgroundImageUrl = '/api/image/car/' + this.state.carId + '/' + this.state.specificRimId + '/' + this.state.color + '/' + this.state.rimSize + '/background.png';
      this.setState(prevState => ({
         images: {
            ...prevState.images,
            backgroundImageUrl
         }
      }), this.createCarCanvas);
   }

   setRimsImageUrl() {
      const rimsImageUrl = '/api/image/car/' + this.state.carId + '/' + this.state.specificRimId + '/' + this.state.color + '/' + this.state.rimSize + '/rims.png';
      this.setState(prevState => ({
         images: {
            ...prevState.images,
            rimsImageUrl
         }
      }), this.createCarCanvas);
   }

   setForegroundImageUrl() {
      const foregroundImageUrl = '/api/image/car/' + this.state.carId + '/' + this.state.specificRimId + '/' + this.state.color + '/' + this.state.rimSize + '/foreground.png';
      this.setState(prevState => ({
         images: {
            ...prevState.images,
            foregroundImageUrl
         }
      }), this.createCarCanvas);
   }

   loadMore(e) {
      if (this.state.fetchRims || this.state.loadingRims) {
         return
      }
      if (this.state.rims.length < this.state.data.totalCount) {
         let newstate = this.state;
         newstate.offset = this.state.offset + e + 1;
         newstate.loadPage = this.state.loadPage + e;
         this.setState(newstate);
         this.setState({fetchRims: true})
      }
   }

   loweringCallback(lowering) {
      if (this.state.lowering != lowering) {
         this.setState({
            lowering
         }, this.createCarCanvas);
      }
   }

   carImageCallback(loading, color) {
      if (this.state.loading != loading) {
         this.setState({
            loading
         });
      }
      if (this.state.color != color) {
         this.setState({
            color
         }, this.setForegroundImageUrl);
      }
   }

   createCarCanvas() {
      if (this.state.isIE === true) {
         return;
      }
      if (this.state.images.backgroundImageUrl && this.state.images.rimsImageUrl && this.state.images.foregroundImageUrl) {
         let c = document.createElement('canvas');
         c.width = 790;
         c.height = 268;
         const ctx = c.getContext("2d");
         const backgroundImage = new Image();
         const rimsImage = new Image();
         const foregroundImage = new Image();
         let base64img;
         backgroundImage.src = this.state.images.backgroundImageUrl;
         backgroundImage.onload = () => {
            ctx.drawImage(backgroundImage, 0, 0, c.width, c.height);
            rimsImage.src = this.state.images.rimsImageUrl;
            rimsImage.onload = () => {
               ctx.drawImage(rimsImage, 0, 0, c.width, c.height);
               foregroundImage.src = this.state.images.foregroundImageUrl;
               foregroundImage.onload = () => {
                  ctx.drawImage(foregroundImage, 0, this.state.lowering ? this.state.lowering : 0, c.width, c.height);
                  base64img = c.toDataURL();
                  this.setState({base64img});
               };
            };
         };
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.match.params.rimId && this.state.rims) {
         for (let rim of this.state.rims) {
            if ((this.state.selectedRimId !== this.props.match.params.rimId) && (rim.rimId === this.props.match.params.rimId)) {
               this.selectRim(rim, rim.items[0].rimId);
            }
         }
      }
      if (!this.state.filteredRimsFound) {
         this.setState({filteredRimsFound: true});
      }
      //select first rim
      if (this.state.rims && prevState.rims) {
         let rims = this.state.rims;
         rims = rims.filter(rim => rim.retailPrice);
         rims = rims.filter(rim => (rim.items.length > 0));
         if (rims && (!this.state.selectedRimId)) {
            this.selectRim(rims[0], rims[0].items[0].rimId, true);
         }
         if (this.state.isFavoriteView) {
            rims = this.props.favRims[this.state.carId];
            let selectedRim = rims[0];
            for (let i of rims) {
               if (i.id == this.state.selectedRimId) {
                  selectedRim = i;
               }
            }
            if (this.state.rimSize != selectedRim.items[0].size) {
               this.setState({rimSize: selectedRim.items[0].size}, this.setForegroundImageUrl);
            }
            if (rims && (this.state.isFavoriteView !== prevState.isFavoriteView)) {
               if (rims && rims[0] && rims[0].items[0] && (this.state.specificRimId !== rims[0].items[0].rimId)) {
                  this.selectRim(rims[0], rims[0].items[0].rimId, true)
               }
            }
         } else if (rims && rims[0] && (rims[0] !== prevState.rims[0]) && (rims[0].items[0] && rims[0].items[0].rimId) && (this.state.specificRimId != rims[0].items[0].rimId)) {
            this.selectRim(rims[0], rims[0].items[0].rimId, true)
         }
      }
      // end select first rim
      if (!this.state.loadingRims && this.state.fetchRims && !this.state.isFavoriteView) {
         this.fetchRims(this.state);
         this.setState({loadingRims: true, fetchRims: false});
      }
      if (this.state.carDetails && this.state.rims && this.state.rims.length > 0 && prevState.selectedRimId !== this.state.selectedRimId) {
         let rims = this.state.rims.filter(rim => (rim.items.length > 0));
         let _rimId = rims[0].items[0].rimId;
         if (this.state.specificRimId) {
            _rimId = this.state.specificRimId;
         } else if (this.state.rimId) {
            for (let rim of rims) {
               if (rim.rimId === this.state.rimId) {
                  _rimId = rim.items[0].rimId;
               }
            }
         }
         this.setState({
            specificRimId: _rimId,
            rims
         })
      }
   }

   filterSelectionCallBack(e) {
      if (Date.now() - timeStamp > 500) {
         let state = this.state;
         state.filterSelection = e;
         state.offset = 0;
         state.filterLoader = true;
         if (e.RIMSIZE) {
            this.setState({
               zoll: e.RIMSIZE[0],
               rimSize: e.RIMSIZE[0],
            })
         }
         this.setState(state, this.fetchRims);
         timeStamp = Date.now();
      }
   }


   fetchCarDetails(requestBody) {
      this.setState({loading: true});
      setTimeout(
         function () {
            getCarDetails(requestBody)
            .then(
               (res) => {
                  if (res.Error != '' && res.Error != null && res.Error != undefined) {
                     this.setState({
                        error: res.Error,
                     });
                  } else {
                     this.setState({
                        carDetails: res,
                     });
                  }
               }
            )
            .catch(error => {
               this.setState({
                  error: error,
               });
            });
         }
         .bind(this),
         500
      );
   }

   fetchCarColors(requestBody) {
      this.setState({loading: true});
      setTimeout(
         function () {
            getCarColors(requestBody)
            .then(
               (res) => {
                  if (res.Error != '' && res.Error != null && res.Error != undefined) {
                     this.setState({
                        error: res.Error,
                     });
                  } else {
                     this.setState({
                        carColors: res,
                        color: res[0].name
                     });
                  }
               }
            )
            .catch(error => {
               this.setState({
                  error: error,
               });
            });
         }
         .bind(this),
         500
      );
   }

   fetchRims() {
      const requestBody = this.state;
      setTimeout(
         function () {
            try {
               getRims(requestBody)
               .then(
                  (res) => {
                     if (res.Error != '' && res.Error != null && res.Error != undefined) {
                        this.setState({
                           error: res.Error,
                        });
                     } else if (res.results.length < 1) {
                        if (this.state.filter) {
                           this.setState({
                              filterNoMatchText: 'Für diese Filter sind keine Felgen vorhanden...',
                           });
                        } else {
                           this.setState({error: true});
                        }
                     } else {
                        let rims = res.results;
                        res.results = rims;
                        if (!this.state.data.groups) {
                           this.state.data.groups = [];
                        }
                        if (requestBody.offset > 0) {
                           res.results = this.state.data.groups.concat(res.results);
                        }
                        this.setState({
                           data: res,
                           filterNoMatchText: null,
                           filter: res.filterDefinitions,
                           filterLoader: false,
                           rims: res.results,
                           rimDetails: res.rimDetails,
                           specificRimId: this.state.specificRimId ? this.state.specificRimId : res.results[0].items[0].rimId,
                           rimSize: this.state.rimSize ? this.state.rimSize : res.results[0].items[0].size,
                           loadingRims: false
                        }, this.setForegroundImageUrl);
                     }

                  }
               )
               .catch(error => {
                  this.setState({
                     error: error
                  });
               });
            } catch (error) {
               console.log(error);
            }
         }
         .bind(this),
         500
      );
   }

   rimDetailHandler(reqbody) {
      this.setState({loading: true});
      getRimDetails(reqbody)
      .then(
         (res) => {
            if (res.Error != '' && res.Error != null && res.Error != undefined) {
               this.setState({
                  error: res.Error,
               });
            } else {
               this.setState({
                  test: res,
               });
            }
         }
      )
      .catch(error => {
         this.setState({
            error: error,
         });
      });
   }

   favRimCallBack(isFavoriteView, enableSwitch) {
      if (isFavoriteView !== enableSwitch) {
         enableSwitch = isFavoriteView
         this.setState({isFavoriteView, enableSwitch})
      }
      this.setState({isFavoriteView, enableSwitch})
   }

   rimHandler(rimId, carId, specificRimId, filterHider, previewOnly) {
      if (filterHider != undefined) {
         this.setState({filterHider: filterHider});
      }

      let currentRimSize = this.state.rims[0].items[0] ? this.state.rims[0].items[0].size : "";

      for (let rim of this.state.rims) {
         if (rim && (rim.rimId === rimId)) {
            currentRimSize = rim.items[0].size;
         }
      }
      if (!previewOnly) {
         this.setState({
            rimId,
            carId,
            specificRimId: specificRimId ? specificRimId : this.state.specificRimId,
            rimSize: currentRimSize,
            loading: true
         }, this.setForegroundImageUrl());
      }
   }

   filterCallBack(e) {
      history.push(`${this.state.rimPath}/car/${this.state.carId}`);
      this.setState({
         rimId: undefined,
         filterHider: false,
      });
   }

   rimSizeCallBack(size) {
      this.setState({rimSize: size}, this.setForegroundImageUrl);
   }

   render() {
      if (this.props.favRims && this.props.favRims[this.state.carId] && this.props.favRims[this.state.carId].length > 0 && !this.state.enableSwitch) {
         this.setState({enableSwitch: true})
      }
      let content;
      let loadingCards = [];
      for (let i = 0; i < 25; i++) {
         loadingCards.push(
            <div className={'rim-card-loader'} key={i + "_loading_cards"}/>
         );
      }

      if (!this.state.error) {
         content = (
            <div>
               {this.state.rimDetails && this.state.color ?
                  <CarView rimPath={this.state.rimPath}
                           car={this.state.carDetails}
                           carId={this.state.carId}
                           rimId={this.state.rimDetails.rimSizeId}
                           rimSize={this.state.rimSize}
                           rims={this.state.rims}
                           loading={this.state.loading}
                           color={this.state.color}
                           carColors={this.state.carColors}
                           carImageCallback={this.carImageCallback}
                           base64img={this.state.base64img}
                           history={this.props.history}
                           lowering={this.state.lowering}
                           loweringCallback={this.loweringCallback}
                           images={this.state.images}
                  />
                  :
                  <div className={'car-view-loading'}>
                     <div className={'row'}>
                        <div className={'col-12 col-sm-12 col-md-12 col-lg-2 col-xl-3 pos-bottom'}>
                           <div className={'box-left-loading'}/>
                        </div>
                        <div className={'col-12 col-sm-12 col-md-12 col-lg-7 col-xl-6'}>
                           <div className={'car-loading'}/>
                        </div>
                        <div className={'col-12 d-none d-lg-block col-lg-3 col-xl-3'}>
                           <div className={'box-right-loading'}/>
                        </div>
                        <div className={'col-12 d-block d-lg-none'}>
                           <div className={'box-left-loading'}/>
                        </div>
                     </div>
                  </div>
               }
               {this.state.filterXXX ?
                  <RimFilter filterSelection={this.filterSelectionCallBack}
                             loading={this.state.loading} filter={this.state.filter}
                             callBack={this.filterCallBack} carId={this.state.carId}
                             filterHider={this.state.filterHider} rims={this.state.rims}
                             totalCount={this.state.data.totalCount}
                             isFavoriteView={this.state.isFavoriteView} enableSwitch={this.state.enableSwitch}
                             favRimCallBack={this.favRimCallBack} filteredRimsFound={this.state.filteredRimsFound}
                  />
                  :
                  <div className={'filter-loader'}/>
               }
               {this.state.rims ?
                  !this.state.filterNoMatchText ?
                     <Rims rimId={this.state.rimId} rims={this.state.rims} selectedRim={this.state.selectedRimId}
                           selectRim={this.selectRim} carImage={this.state.carImage}
                           carId={this.state.carId} searchResult={this.state.searchResult}
                           loading={this.state.loading} loadMore={this.loadMore}
                           loadPage={this.state.loadPage} loadLess={() => this.loadLess()}
                           filterLoader={this.state.filterLoader}
                           totalCount={this.state.data.totalCount} isDetailView={this.state.filterHider}
                           filterSelections={this.state.filterSelection} car={this.state.carDetails}
                           isFavoriteView={this.state.isFavoriteView} enableSwitch={this.state.enableSwitch}
                           favRimCallBack={this.favRimCallBack} rimSize={this.state.rimSize}
                           rimSizeCallBack={this.rimSizeCallBack}
                           base64img={this.state.base64img} loadingRims={this.state.loadingRims}
                     />
                     :
                     <div className={'text-center mt-4'}>
                        <h3>{this.state.filterNoMatchText}</h3>
                        <button className={'btn btn-primary mb-4'}
                                onClick={() => this.setState({filteredRimsFound: false})}>
                           Filter zurücksetzen
                        </button>
                     </div>
                  :
                  <div className={'rim-card-wrapper-loader'}>
                     {loadingCards}
                  </div>
               }
               {this.state.carDetails ?
                  <Helmet>
                     <title>{this.state.carDetails.salesDescription}</title>
                  </Helmet>
                  : ''
               }
            </div>
         );
      } else {
         content = (<Redirect to={'/noRimsFound'}/>);
      }
      return content;
   }
}

const mapStateToProps = state => ({
// TODO delete rims from store (changes only in state)
   favRims: state.favRims
});

const mapDispatchToProps = dispatch => {
   // TODO delete onSearchRequest, onSearchResponse, onSearchError (write changes only in state)
   return {
      onSearchRequest: () => dispatch(actionCreators.searchRequest()),
      onSearchResponse: (json) => dispatch(actionCreators.searchSuccess(json)),
      onSearchError: (error) => dispatch(actionCreators.searchError(error))
   };

};

export default connect(mapStateToProps, mapDispatchToProps)(SelectRim);
