import React from 'react';
import Helmet from 'react-helmet';

const TermsOfUse = () => (
   <div className="container">
      <div className="site-wrapper">
         <h1>Datenschutzerklärung</h1>
         <h2>I. Allgemein</h2>
         <h2>1. Information über die Erhebung personenbezogener Daten</h2>


         <div><h3>Allgemeines</h3>
            <p>Die
               Automobilgruppe Nord AG ist Verantwortlich im Sinne der Europäischen Datenschutzgrundverordnung
               (DSGVO)
               für die Datenverarbeitung auf dieser Website. Wir achten Ihre Persönlichkeitsrechte. Wir wissen um die
               Bedeutung persönlicher Daten, die wir von Ihnen als Nutzer unserer Website erhalten. Wir respektieren
               den
               Schutz Ihrer personenbezogenen Daten und werden sämtliche erlangten Daten ausschließlich nach Maßgabe
               der
               einschlägigen datenschutzrechtlichen Bestimmungen im Rahmen unseres Geschäftszweckes erheben,
               speichern
               oder verarbeiten.</p></div>
         <div><h3>Definitionen</h3><p>Als
            personenbezogene Daten gelten alle Informationen, die sich auf eine identifizierte oder identifizierbare
            natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder
            indirekt, insbesondere mittels Zuordnung zu einer Kennung, wie einem Namen, einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert
            werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
            kulturellen oder sozialen Identität dieser natürlichen Person sind.</p></div>
         <div><h3>Rechtsgrundlagen der
            Verarbeitung</h3><p>Sofern wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung von
            Ihnen einholen, dient Art. 6 Abs. 1 S. 1 lit. a der Europäischen Datenschutzgrundverordnung (DSGVO) als
            Rechtsgrundlage.</p><p>Die Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages
            mit Ihnen erforderlich sind, stützt sich auf Art. 6 Abs. 1 S. 1 lit. b DSGVO. Dies gilt auch für
            Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.</p><p>Soweit
            eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,
            denen unser Unternehmen unterliegt, geschieht dies im Rahmen von Art. 6 Abs. 1 S. 1 lit. c DSGVO.</p>
            <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses von uns oder einem Dritten erforderlich
               und überwiegen Ihre Interessen, Grundrechte und Grundfreiheiten das berechtigte Interesse nicht, so
               dient Art. 6 Abs. 1 S. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung. Das berechtige
               Interesse unseres Unternehmens liegt in der Regel in der Erbringung unserer geschuldeten Leistungen
               und/oder laufender Optimierung unserer Dienste und Darstellungen.</p></div>

         <div><h3>Datenlöschung und
            Speicherdauer</h3><p>Ihre personenbezogenen Daten werden gelöscht oder gesperrt, sobald der Zweck der
            Speicherung entfällt. Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den
            europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
            Vorschriften vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch
            die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur
            weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.</p></div>

         <div><h3>Erhebung personenbezogener
            Daten</h3><p>Grundsätzlich erheben und verwenden wir bei Besuch unserer Website keine personenbezogenen
            Daten. Dies geschieht nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer
            Inhalte und Leistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer
            Nutzer erfolgt regelmäßig nur nach deren Einwilligung. Anderes gilt in solchen Fällen, in denen eine
            vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung
            der Daten durch gesetzliche Vorschriften gestattet ist.</p><p>Nachfolgend möchten wir Sie über Art,
            Umfang und Zweck unseres Datenumgangs im Rahmen dieser Website informieren:</p></div>

         <div><h3>Server-Log-Files</h3>
            <p>Bei jedem Aufruf unserer Website werden die zur Inanspruchnahme und zur Abrechnung der Nutzung
               erforderlichen
               Zugriffsdaten des Nutzers auf unserem Server in einer Protokolldatei (Log-Datei), die Ihr Browser
               automatisch an uns übermittelt, gespeichert.<br/> Dies sind:</p>
            <ul>
               <li>Browsertyp/ Browserversion</li>
               <li>verwendetes Betriebssystem</li>
               <li>Hostname des zugreifenden Rechners</li>
               <li>Datum und Uhrzeit der Serveranfrage</li>
               <li>IP-Adresse des Rechners, der die Website anfordert;</li>
               <li>Website, von der der Zugriff erfolgte (Referrer URL)</li>
               <li>aufgerufene Dateien;</li>
               <li>übertragene Datenmenge.</li>
            </ul>
            <p>Die Speicherung der Log-Datei dient folgenden Zwecken:</p>
            <ul>
               <li>Auswertung Dateienabrufs zu statistischen Zwecken;</li>
               <li>Systemsicherheit und Stabilität der Website</li>
               <li>Überprüfung auf vertragswidrige oder sonst rechtswidrige Nutzung, sofern hierfür tatsächliche
                  Anhaltspunkte vorliegen.
               </li>
            </ul>
            <p>Die Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser
               berechtigtes Interesse folgt aus oben genannten Zwecken zur Datenerhebung. In keinem Fall verwenden
               wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen. Eine Zusammenführung
               dieser Daten mit anderen Datenquellen nehmen wir nicht vor.</p>
         </div>
         <div><h3>Cookies</h3><p>Nach dem
            Einloggen des Nutzers auf unserer Website werden so genannte „Cookies“ auf dem Computer des Nutzers
            gespeichert.</p><p>Bei einem Cookie handelt es sich um eine kleine Textdatei in einem dafür vorgesehenen
            Dateiverzeichnis des Computers. Diese Datei dient dazu, den Computer des Nutzers während der Dauer der
            Sitzung zu identifizieren. Cookies können keine Manipulationen am jeweiligen Endgerät des Nutzers
            erzeugen und können – am einfachsten im Browser – jederzeit manuell gelöscht werden.</p><p>Die Handhabung
            von Cookies können Sie in Ihrem Internetbrowser individuell so einstellen, dass Cookies abgelehnt oder
            nur nach Bestätigung akzeptiert werden. Die Cookies, hier sog. „Session-Cookies“, dienen dem Zweck der
            Funktionserweiterung unseres Internetangebotes sowie dazu, Ihnen die Nutzung möglichst komfortabel zu
            gestalten. Wir weisen Sie darauf hin, dass bei Ablehnung von Cookies nicht alle Bestandteile unserer
            Anwendung störungsfrei funktionieren können.</p><p>Die Verarbeitung der Daten durch Cookies ist für die
            genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie ggf. solcher Dritter nach Art. 6 Abs.
            1 S. 1 lit. f DSGVO erforderlich.</p></div>

         <div><h3>Nutzungsdaten</h3><p>Wir
            erheben und verwenden personenbezogene Daten auf Grundlage von Art. 6 Abs. 1 S. 1 b) DSGVO von Ihnen,
            soweit dies erforderlich ist, um die Inanspruchnahme unseres Internetangebotes zu ermöglichen
            (Nutzungsdaten).</p><p>Für Zwecke der Werbung, der Marktforschung und zur bedarfsgerechten Gestaltung
            unseres Internetangebotes dürfen wir bei Verwendung von Pseudonymen auch Nutzungsprofile erstellen.</p>
            <p>Sie haben das Recht, dieser Verwendung Ihrer Daten jederzeit zu widersprechen. Die Nutzungsprofile
               dürfen wir nicht mit Daten über den Träger des Pseudonyms zusammenführen.</p></div>

         <div><h3>Daten im Zusammenhang mit
            Ihrer Kontaktaufnahme</h3><p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
            Angaben aus dem Formular, oder über die bereitgestellte E-Mail-Adresse zur Kontaktaufnahme, inklusive der
            von Ihnen dort angegebenen Kontaktdaten allein zu Zwecken der Bearbeitung der jeweiligen Anfrage und für
            den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung an
            Dritte weiter. Die entsprechende Datennutzung erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1 b) DSGVO im
            Rahmen der Bearbeitung Ihres Anliegens.</p></div>

         <div><h3>Webshop</h3><p>Wenn Sie in
            unserem Webshop bestellen möchten, ist es für den Vertragsabschluss erforderlich, dass Sie
            personenbezogene Daten angeben, die wir für die Abwicklung Ihrer Bestellung benötigen. Für die Abwicklung
            der Verträge notwendige Pflichtangaben sind gesondert markiert, weitere Angaben sind freiwillig. Die von
            Ihnen angegebenen Daten verarbeiten wir zur Abwicklung Ihrer Bestellung. Rechtsgrundlage hierfür ist Art.
            6 Abs. 1 S. 1 lit. b DSGVO.</p><p>Wir können die von Ihnen angegebenen Daten zudem verarbeiten, um Sie
            über weitere interessante Produkte aus unserem Portfolio zu informieren oder Ihnen E-Mails mit
            technischen Informationen zukommen lassen.</p><p>Wir sind aufgrund handels- und steuerrechtlicher
            Vorgaben verpflichtet, Ihre Adress-, Zahlungs- und Bestelldaten für die Dauer von zehn Jahren zu
            speichern. Allerdings nehmen wir nach zwei Jahren eine Einschränkung der Verarbeitung vor, d.h. Ihre
            Daten werden nur zur Einhaltung der gesetzlichen Verpflichtungen eingesetzt.</p><p>Zur Verhinderung
            unberechtigter Zugriffe Dritter auf Ihre persönlichen Daten, insbesondere Finanzdaten, wird der
            Bestellvorgang per TLS-Technik verschlüsselt.</p></div>

         <div><h3>Ihre Rechte</h3><p>Soweit wir
            Ihre personenbezogenen Daten auf unserer Website verarbeiten, sind Sie „Betroffener“ im Sinne der DSGVO.
            Ihnen stehen folgende Rechte gegenüber uns zu:</p></div>

         <div><h3>Auskunftsrecht</h3><p>Sie
            können von uns eine Bestätigung darüber verlangen, ob wir personenbezogene Daten von Ihnen verarbeiten.
            Sollte eine solche Verarbeitung vorliegen, können Sie von uns über folgende Informationen Auskunft
            verlangen:</p>
            <ul>
               <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
               <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
               <li>die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen Ihre personenbezogenen Daten
                  offengelegt wurden oder noch offengelegt werden;
               </li>
               <li>die geplante Dauer der Speicherung Ihrer personenbezogenen Daten oder, falls konkrete Angaben
                  hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
               </li>
               <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
               <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht
                  bei Ihnen erhoben werden;
               </li>
               <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22
                  Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die
                  involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen
                  Verarbeitung für Sie.
               </li>
            </ul>
            <p>Weiterhin steht Ihnen das Recht zu, Auskunft darüber zu verlangen, ob Ihre personenbezogenen Daten in
               ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang
               können Sie verlangen über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
               Übermittlung unterrichtet zu werden.</p></div>

         <div><h3>Recht auf Berichtigung</h3>
            <p>Sie haben gegenüber uns ein Recht auf Berichtigung und/oder Vervollständigung Ihrer personenbezogenen
               Daten, sofern Ihre verarbeiteten Daten unrichtig oder unvollständig sind. Sollte dies der Fall sein,
               werden wir die Berichtigung unverzüglich vornehmen.</p></div>

         <div><h3>Recht auf Einschränkung der
            Verarbeitung</h3><p>Sie haben unter folgenden Voraussetzungen das Recht die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn:</p>
            <ul>
               <li>Sie die Richtigkeit Ihrer personenbezogenen Daten für eine Dauer bestreiten, die es uns
                  ermöglicht, die Richtigkeit der Daten zu überprüfen;
               </li>
               <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung Ihrer<br/>
                  personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung verlangen;
               </li>
               <li>wir Ihre personenbezogenen Daten für die Zwecke der<br/>
                  Verarbeitung nicht länger benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen benötigen, oder
               </li>
               <li>Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht
                  feststeht, ob unsere berechtigten Gründe gegenüber Ihren Gründen überwiegen.
               </li>
            </ul>
            <p>Haben Sie die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangt, dürfen diese
               Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
               oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
               juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines
               Mitgliedstaats verarbeitet werden. Sie werden von uns unterrichtet bevor die Einschränkung aufgehoben
               wird.</p></div>
         <div><h3>Recht auf Löschung</h3><p>Sie
            können von uns die unverzügliche Löschung Ihrer personenbezogenen Daten verlangen. Wir sind verpflichtet
            diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:</p>
            <ul>
               <li>Ihre personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise
                  verarbeitet wurden, nicht mehr notwendig.
               </li>
               <li>Sie widerrufen eine ggf. bestehende Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1
                  S. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen
                  Rechtsgrundlage für die weitere Verarbeitung.
               </li>
               <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein, und es liegen keine
                  vorrangigen berechtigten Gründe für die Verarbeitung vor.
               </li>
               <li>Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung im Wege einer Direktwerbung
                  ein.
               </li>
               <li>Ihre personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
               <li>Die Löschung Ihrer personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach
                  dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem wir unterliegen.
               </li>
               <li>Ihre personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft
                  gemäß Art. 8 Abs. 1 DSGVO erhoben.
               </li>
            </ul>
            <p>Haben wir Ihre personenbezogenen Daten öffentlich gemacht und sind wir gem. Art. 17 Abs. 1 DSGVO zu
               deren Löschung verpflichtet, so treffen wir, unter Berücksichtigung der verfügbaren Technologie und
               der Implementierungskosten, angemessene Maßnahmen, um die für die Datenverarbeitung Verantwortliche/n
               darüber zu informieren, dass Sie von ihm/ihnen die Löschung aller Links zu diesen personenbezogenen
               Daten, Kopien oder Replikationen verlangt haben.</p><p>Ihr Recht auf Löschung besteht nicht, soweit
               die Verarbeitung erforderlich ist</p>
            <ul>
               <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
               <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder
                  der Mitgliedstaaten, dem wir unterliegen, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
                  öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen
                  wurde;
               </li>
               <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2
                  lit. h und i sowie Art. 9 Ab. 3 DSGVO;
               </li>
               <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
                  Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das in Abs. 1
                  genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht
                  oder ernsthaft beeinträchtigt, oder
               </li>
               <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
            </ul>
         </div>

         <div><h3>Recht auf Unterrichtung</h3>
            <p>Haben Sie Ihr Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber uns
               geltend gemacht, sind wir verpflichtet, allen Empfängern, denen Ihre personenbezogenen Daten
               offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung
               mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen
               Aufwand verbunden.</p><p>Ihnen steht gegenüber uns das Recht zu, dass wir Sie über diese Empfänger
               unterrichten.</p></div>

         <div><h3>Recht auf
            Datenübertragbarkeit</h3><p>Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns ggf.
            bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
            Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu
            übermitteln, sofern</p>
            <ul>
               <li>die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO oder Art. 9 Abs. 2
                  lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO beruht und
               </li>
               <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
            </ul>
            <p>Ferner haben Sie diesbezüglich das Recht, die Übermittlung Ihrer personenbezogenen Daten direkt von
               uns an einen anderen Verantwortlichen zu erwirken, soweit dies technisch machbar ist. Die Rechte und
               Freiheiten anderer Personen dürfen hierdurch nicht beeinträchtigt werden.</p><p>Das Recht auf
               Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung
               einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
               Gewalt erfolgt, die uns übertragen wurde.</p><p>Im Rahmen des Angebotes unserer Website gehen wir
               derzeit nicht davon aus, dass dem Recht auf Datenübertragbarkeit unterliegende Daten verarbeitet
               werden.</p></div>


         <div><h3>Widerspruchsrecht</h3><p>Sie
            haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung Ihrer personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 S. 1 lit. e oder f DSGVO
            erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>
            <p>Wir verarbeiten Ihre personenbezogenen Daten dann nicht mehr, es sei denn, wir können zwingende
               schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
               überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
               Rechtsansprüchen.</p><p>Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
               betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
               personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das damit ggf. in
               Verbindung stehende Profiling.</p><p>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung,
               so werden Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p><p>Sie haben die
               Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet
               der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
               denen technische Spezifikationen verwendet werden.</p></div>

         <div><h3>Recht auf Widerruf der
            datenschutzrechtlichen Einwilligungserklärung</h3><p>Sie haben das Recht, Ihre datenschutzrechtliche
            Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
         </div>


         <div><h3>Automatisierte Entscheidung im
            Einzelfall einschließlich Profiling</h3><p>Sie haben das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden,
            die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
            Solche Verarbeitungen nehmen wir nicht vor.</p></div>


         <div><h3>Recht auf Beschwerde bei einer
            Aufsichtsbehörde</h3><p>Ihnen steht unbeschadet ggf. weiterer Beschwerderechte das Recht auf Beschwerde
            bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer
            personenbezogenen Daten gegen die DSGVO verstößt.</p></div>


         <div><h3>Verantwortung für verlinkte
            Inhalte</h3><p>Auf unserer Website nutzen wir ggf. auch Links zu Websites anderer Anbieter. Insoweit gilt
            diese Datenschutzerklärung nicht. Sofern bei der Nutzung der Websites dieser anderen Anbieter eine
            Erhebung, Verarbeitung oder Nutzung personenbezogener Daten erfolgt, so beachten Sie bitte die Hinweise
            zum Datenschutz der jeweiligen Anbieter. Für deren Datenschutzhandhabung sind wir nicht
            verantwortlich.</p></div>

         <div><h3>Weitergabe personenbezogener
            Daten an Dritte</h3><p>Ihre personenbezogenen Daten werden ausschließlich auf unseren Servern bzw. auf in
            unserem Auftrag genutzten Servern gespeichert. Der Zugriff hierauf und die Verwendung der Daten ist nur
            einem dazu berechtigten Mitarbeiterkreis bzw. Dienstleisterkreis möglich und ist auch nur auf diejenigen
            Daten begrenzt, die zur Erfüllung der jeweiligen Aufgabe notwendig sind.</p><p>Eine Übermittlung Ihrer
            Daten an Dritte erfolgt ohne Ihre Einwilligung nicht. Eine Datenübermittlung in Drittstaaten (Staaten
            außerhalb des Europäischen Wirtschaftsraums – EWR) findet, soweit nicht in dieser Datenschutzerklärung
            anders angegeben, nicht statt und ist auch künftig nicht beabsichtigt.</p></div>

         <div><h3>Datensicherheit</h3><p>Zum
            Schutz Ihrer personenbezogenen Daten haben wir technische und organisatorische Maßnahmen getroffen, um
            dafür Sorge zu tragen, dass Ihre Daten vor zufälligen oder vorsätzlichen Verlusten, Zerstörungen oder
            Manipulationen sowie den Zugriff nicht berechtigter Personen geschützt sind. Unsere Schutzmaßnahmen
            werden in regelmäßigen Abständen überprüft und bei Bedarf dem technischen Fortschritt angepasst.</p>
         </div>
         <div><h3>Datenschutzbeauftragter</h3>
            <p>Sollten Sie darüber hinaus Fragen im Bezug zur Verarbeitung Ihrer personenbezogenen Daten haben, so
               wenden Sie sich bitte an unseren Datenschutzbeauftragten:</p><p>compolicy GmbH<br/>
               Dr. Christian Wolff<br/>
               An den Eichen 15<br/>
               24248 Mönkeberg<br/>
               info@compolicy.de</p></div>
         <div><h3>Änderung der
            Datenschutzerklärung</h3><p>Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit bei
            Bedarf und in Ansehung der zum Änderungszeitpunkt jeweils geltenden Datenschutzvorschriften zu
            ändern.</p></div>

      </div>
      <Helmet>
         <title>Nutzungsbedingungen</title>
         <meta
            name="description"
            content="This is a proof of concept for React SSR"
         />
      </Helmet>
   </div>
);

export default TermsOfUse;
