import * as actionTypes from "../actions/actionTypes";

const initialState = {
   tyres: [],
   isLoading: false,
   filters: null,
   error: null,
   totalCount: null
};

const reducer = (state = initialState, action) => {
   let _selectedTyres = state.tyres;
   let _error = state.error;
   let _totalCount = state.totalCount;
   switch (action.type) {
      case actionTypes.STORE_selectedTyreS:
         _totalCount = action.tyres ? action.tyres.pop() : 0;
         _selectedTyres = action.tyres;
         return {
            tyres: _selectedTyres,
            filters: action.filters ? action.filters : null,
            isLoading: false,
            totalCount: _totalCount,
            error: null
         };

      case actionTypes.LOAD_selectedTyreS:
         return {
            isLoading: true
         };

      case actionTypes.FETCH_selectedTyreS_ERROR:
         _error = action.error;
         return {
            error: _error,
            isLoading: false,
            totalCount: null,
            tyres: [],
            filters: null
         };

      default:
         break;
   }
   return state;
};

export default reducer;
