import React from 'react';

class RimCardButtons extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         firstSize: 0
      };
   }

   setFirstSize(index) {
      this.setState({firstSize: index});
   }

   priceHandler(rim) {
      for (let sizes of rim.items) {
         if (sizes.size == this.props.rimSize) {
            return sizes.retailPrice ? sizes.retailPrice : 'Preis auf Anfrage';
         }
      }
      return rim.items[0].retailPrice ? rim.items[0].retailPrice : 'Preis auf Anfrage'
   }

   render() {
      let firstSize = this.state.firstSize;
      let active = this.props.active;
      let visible = this.props.visible;
      let ar = this.props.ar;
      let price = this.priceHandler(this.props.rim);
      const visLeft = firstSize === 0 ? false : true;
      const visRight = (ar ? (ar.length > 3 || firstSize === (ar.length - 3)) ? true : false : '');
      return (
         <div style={{'visibility': visible && !active ? 'visible' : 'hidden'}}>
            <div className={'mt-2'}>
               <div className={'row'}>
                  <div style={{'width': '100%'}} className={'rim-size-slide-wrapper mb-2'}>
                     <div className={'sideColumn'}>
                        <div className={'sideSlider'}>
                           <button className={'navBtn right' + (visRight ? ' active' : '')}
                                   onClick={() => this.setFirstSize(firstSize + 1)}
                                   style={{'display': visRight ? 'inherit' : 'none'}}>
                              {'>'}
                           </button>
                           <button
                              className={'navBtn left' + (visLeft ? ' active' : '')}
                              onClick={() => this.setFirstSize(firstSize - 1)}
                              style={{'display': visLeft ? 'inherit' : 'none'}}>
                              {'<'}
                           </button>
                           <div className={'rimWrapper'}
                                style={{'transform': `translateX(-${firstSize * (100 / 3)}%`}}>
                              {ar}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={'m-auto btn-wrapper'}>
                     <button onClick={() => this.props.goToDetail()}
                             disabled={this.props.loadingRims}
                             className={'btn btn-outline-primary'}> Details
                     </button>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default (RimCardButtons);
