import React from 'react';
import ReactTooltip from 'react-tooltip';
import Magnifier from 'react-magnifier';

class RimCardDetails extends React.Component {

   availabilityHandler(rim) {
      let returnValue = '';
      let chosenItem = this.props.chosenItem;
      if (!chosenItem) {
         if (this.props.rimSize) {
            let i = 0;
            while (i < rim.length) {
               if (rim[i].size == this.props.rimSize) {
                  chosenItem = i;
                  i = rim.length;
               } else {
                  i++
               }
            }
         } else chosenItem = 0;
      }
      if (!rim[chosenItem]) {
         chosenItem = 0;
      }
      if (rim[chosenItem].status == 'NO_STOCK') {
         returnValue = 'availability yellow';
      } else if (rim[chosenItem].status == 'PARTIAL_STOCK') {
         returnValue = 'availability blue';
      } else if (rim[chosenItem].status == 'IN_STOCK') {
         returnValue = 'availability green';
      }
      return returnValue;
   }

   priceHandler(rim) {
      for (let sizes of rim.items) {
         if (sizes.size == this.props.rimSize) {
            return sizes.retailPrice ? sizes.retailPrice : 'Preis auf Anfrage';
         }
      }
      return (rim.items[0].retailPrice ? rim.items[0].retailPrice : 'Preis auf Anfrage'
      );
   }

   render() {
      let {visible, active, rim, rimSize} = this.props;
      let price = this.priceHandler(rim);
      let favRims = this.props.favRims;
      let favRimIds = [];
      if (favRims[this.props.carId] && favRims[this.props.carId][0] && favRims[this.props.carId][0].id) {
         for (let x of favRims[this.props.carId]) {
            favRimIds.push(x.id)
         }
      }
      return (
         <div className={'clickable'}>
            <div className={'row'}>
               <div className={'col ' + this.availabilityHandler(rim.items)}>
                  {this.availabilityHandler(rim.items) === 'availability green' ?
                     <span>sofort verfügbar</span> :
                     this.availabilityHandler(rim.items) === 'availability blue' ?
                        <span>wenige verfügbar</span> :
                        <span>auf Anfrage</span>
                  }
               </div>
               <div className={'col-2 favorite text-right'}
                    onClick={() => this.props.favoriteCallBack(rim)}>
                  <i className={this.props.favRims[this.props.carId] && favRimIds.includes(rim.id) ? 'fas fa-star' : 'far fa-star'}
                     data-tip data-for={'favorite'}/>
                  <ReactTooltip id='favorite' place={'bottom'}>
                     <span>
                        Zu Favoriten hinzufügen/entfernen
                     </span>
                  </ReactTooltip>
               </div>
            </div>
            <div onClick={!this.props.hoverState ? () => this.props.setRim() : console.log()}>
               <div className={'image-wrapper'}>
                  {!active ?
                     <img className={'img rim-image ' + (active ? 'large' : 'thumbnail')}
                          src={rim.imageFrontUrl ? '/ggi/' + rim.imageFrontUrl : '/noimage.png'}/>
                     :
                     <Magnifier style={{'position': 'relative', 'top': 0}}
                                mgWidth={220} mgHeight={220} mgTouchOffsetX={15} mgTouchOffsetY={0}
                                zoomFactor={0.9}
                                src={rim.imageFrontUrl ? '/ggi/' + rim.imageFrontUrl : '/noimage.png'}
                                zoomImgSrc={rim.imageFrontUrl ? '/ggi/' + rim.imageFrontUrl : '/noimage.png'}
                                className={'car-view-image'}
                                mgShape='square'
                                mgShowOverflow={true}/>
                  }
               </div>
               <div style={{'visibility': visible && !active ? 'visible' : 'hidden'}}>
                  <div className={'row rim-description'}>
                     <div className={'col-5'}>
                        <h3>{rim.manufacturer}</h3>
                        <div>{rim.name}</div>
                        <div>{rim.colorGroup}</div>
                     </div>
                     <div className={'col-7 text-right pt' + (price == 'Preis auf Anfrage' ? ' poa' : '')}>
                        {
                           price !== 'Preis auf Anfrage' ?
                              <span className={'text-highlight price-span'}>
                                 ab <b>{price}</b>
                              </span>
                              :
                              <span className={'text-highlight price-span'}>
                                 {price}
                              </span>
                        }
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default (RimCardDetails);
