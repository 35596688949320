import React from 'react';
import {connect} from 'react-redux';
import {tyreDetailAction} from '../../../../../redux-store/actions/tyre';
import Dropdown from '../../shared/dropDown/DropDown';
import Slider from 'rc-slider';
import ReactTooltip from 'react-tooltip';
// import Select, {components} from 'react-select';
import Select from 'react-dropdown-select';
import Switch from 'react-toggle-switch';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class RimFilter extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         filter: {},
         filterHider: this.props.filterHider ? this.props.filterHider : false,
         filterhidertext: 'Filter einblenden',
         filterClass: '',
         deleteFilterClass: 'd-none',
         deleteFilter: false,
         backToRims: false,
         carId: this.props.carId,
         filterSelections: {},
         rimsizes: [],
         brands: [],
         values: [],
         colors: [],
         isFavoriteView: this.props.isFavoriteView,
         enableSwitch: this.props.enableSwitch,
         sizeArray: [],
         brandArray: [],
         colorArray: [],
         defaultValue: 'Alle',
      };
      this.clear = this.clear.bind(this);
      this.optionsSelectedZoll = this.optionsSelectedZoll.bind(this);
      this.optionsSelectedBrand = this.optionsSelectedBrand.bind(this);
      this.optionsSelectedColor = this.optionsSelectedColor.bind(this);
      this.clearZollFunction = () => {
      };
      this.clearBrandFunction = () => {
      };
      this.clearColorFunction = () => {
      };
   }

   componentWillMount() {
      let _state = this.state;
      if (this.props.filter != _state.filter) {
         let newFilter = this.props.filter;
         newFilter.map((filterPos, key) => {
            _state.filter[filterPos.id] = filterPos;
            _state.filterSelections[filterPos.id] = {};
            _state.filter[filterPos.id].values.map((val, iD) => {
                  _state.filter[filterPos.id].values[iD] = val.id;
               }
            );
         });
         this.setState(_state);
      }
   }

   componentDidMount() {
      if (this.state.filterHider !== this.props.filterHider || this.state.filterHider == true) {
         if (this.props.filterHider === true) {
            this.setState({
               filterHider: true,
               filterhidertext: 'Zurück zur Felgenauswahl',
               filterClass: ' go-back',
               backToRims: true
            });
         } else {
            this.setState({
               filterHider: true,
               filterhidertext: 'Filter einblenden',
               filterClass: '',
               backToRims: false
            });
         }
      }
   }

   componentDidUpdate(nextProps, prevState) {
      if (this.state.filter.RIMSIZE.values && (this.state.sizeArray.length < 1 || this.state.filter != prevState.filter)) {
         let _state = this.state;
         _state.sizeArray = [];
         _state.brandArray = [];
         _state.colorArray = [];
         _state.filter.RIMSIZE.values.map(size => (
            _state.sizeArray.push({
               value: size,
               label: size
            })
         ));
         _state.filter.BRAND.values.map(brand => (
            _state.brandArray.push({
               value: brand,
               label: brand
            })
         ));
         _state.filter.COLOR_GROUP.values.map(color => (
            _state.colorArray.push({
               value: color,
               label: color
            })
         ));
         this.setState(_state);
      }

      if (this.props.filteredRimsFound !== nextProps.filteredRimsFound) {
         if (!this.props.filteredRimsFound) {
            setTimeout(
               function () {
                  this.deleteFilterHandler();
               }
               .bind(this),
               500
            );
         }
      }

      if (nextProps.filterHider != this.props.filterHider) {
         if (this.state.filterHider != this.props.filterHider) {
            if (this.props.filterHider === true) {
               this.setState({
                  filterHider: this.props.filterHider,
                  filterhidertext: 'Zurück zur Felgenauswahl',
                  filterClass: ' go-back',
                  backToRims: true
               });
            } else {
               this.setState({
                  filterHider: this.props.filterHider,
                  filterhidertext: 'Filter einblenden',
                  filterClass: '',
                  backToRims: false
               });
            }
         }
      }
   }

   changeHandler(e, id) {
      let newState = this.state;
      if (e.currentTarget || e == 'Nur') {
         if (newState.filterSelections[id] == 'true') {
            newState.filterSelections[id] = '';
         } else {
            newState.filterSelections[id] = 'true';
         }
      } else {
         newState.filterSelections[id] = e;
      }
      this.setState(newState);
      this.setState({deleteFilterClass: 'd-block'});
      this.props.filterSelection(this.state.filterSelections);
   }

   zollHandler(e) {
      let newState = this.state;
      let newArray = [];
      for (let size of e) {
         newArray.push(size.value);
         if (newState.rimsizes[size.value] !== 'selected') {
            newState.rimsizes[size.value] = 'selected';
         } else {
            newState.rimsizes[size.value] = '';
         }
      }
      newState.filterSelections.RIMSIZE = newArray;
      this.setState(newState);
      this.props.filterSelection(this.state.filterSelections);

      if (newArray.length > 0) {
         this.setState({deleteFilterClass: 'd-block'});
      } else {
         this.setState({deleteFilterClass: 'd-none'});
      }
   }

   brandHandler(e) {
      let newState = this.state;
      let newArray = [];
      for (let brand of e) {
         newArray.push(brand.value);
         if (newState.brands[brand.value] !== 'selected') {
            newState.brands[brand.value] = 'selected';
         } else {
            newState.brands[brand.value] = '';
         }
      }
      newState.filterSelections.BRAND = newArray;
      this.setState(newState);
      this.props.filterSelection(this.state.filterSelections);

      if (newArray.length > 0) {
         this.setState({deleteFilterClass: 'd-block'});
      } else {
         this.setState({deleteFilterClass: 'd-none'});
      }
   }

   colorHandler(e) {
      let newState = this.state;
      let newArray = [];
      for (let color of e) {
         newArray.push(color.value);
         if (newState.colors[color.value] !== 'selected') {
            newState.colors[color.value] = 'selected';
         } else {
            newState.colors[color.value] = '';
         }
      }
      newState.filterSelections.COLOR_GROUP = newArray;
      this.setState(newState);
      this.props.filterSelection(this.state.filterSelections);

      if (newArray.length > 0) {
         this.setState({deleteFilterClass: 'd-block'});
      } else {
         this.setState({deleteFilterClass: 'd-none'});
      }
   }

   clear() {
      this.clearZollFunction();
      this.clearBrandFunction();
      this.clearColorFunction();
   }

   filterHandler(e) {
      if (!this.state.backToRims) {
         if (this.state.filterhidertext === 'Filter ausblenden') {
            this.setState({
               filterHider: true,
               filterhidertext: 'Filter einblenden',
               filterClass: ''
            });
         } else if (this.state.filterhidertext === 'Filter einblenden') {
            this.setState({
               filterHider: false,
               filterhidertext: 'Filter ausblenden',
               filterClass: ' is-active'
            });
         }
      } else {
         this.props.callBack();
      }
   }

   deleteFilterHandler(e) {
      let newState = {...this.state};
      newState.deleteFilterClass = 'd-none';
      newState.filterSelections = {};
      newState.sizeArray = [];
      newState.brandArray = [];
      newState.colorArray = [];
      this.clear();
      this.setState({...newState, deleteFilterClass: 'd-none'});
      this.props.filterSelection(newState.filterSelections);
   }

   rangeChangeHandler(e) {
      let newState = this.state;
      newState.filterSelections.RETAILPRICE = e;
      this.setState(newState);
      this.props.filterSelection(this.state.filterSelections);
   }

   optionsSelectedZoll(args) {
      this.clearZollFunction = args.methods.clearAll;
      if (this.state.filterSelections && this.state.filterSelections.RIMSIZE && this.state.filterSelections.RIMSIZE.length > 0 && this.state.filterSelections.RIMSIZE.length !== undefined) {
         return (this.state.filterSelections.RIMSIZE.length + ' ' + 'gewählt');
      } else {
         return 'Alle';
      }
   }

   optionsSelectedBrand(args) {
      this.clearBrandFunction = args.methods.clearAll;
      if (this.state.filterSelections && this.state.filterSelections.BRAND && this.state.filterSelections.BRAND.length > 0 && this.state.filterSelections.BRAND.length !== undefined) {
         return (this.state.filterSelections.BRAND.length + ' ' + 'gewählt');
      } else {
         return 'Alle';
      }
   }

   optionsSelectedColor(args) {
      this.clearColorFunction = args.methods.clearAll;
      if (this.state.filterSelections && this.state.filterSelections.COLOR_GROUP && this.state.filterSelections.COLOR_GROUP.length > 0 && this.state.filterSelections.COLOR_GROUP.length !== undefined) {
         return (this.state.filterSelections.COLOR_GROUP.length + ' ' + 'gewählt');
      } else {
         return 'Alle';
      }
   }

   render() {
      const {filter} = this.state;
      let content;
      const intValues = [parseInt(filter.RETAILPRICE.values[0]), parseInt(filter.RETAILPRICE.values[1])];
      if (filter) {
         content = (
            <div className={'rim-filter-wrapper full-width-wrapper position-relative mb-4'}>
               <div className={'container'}>
                  <div className={'row animated'}>
                     {filter.RIMSIZE ?
                        <div className={'col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2'}>
                           <h3 className={'d-inline'}> Zollgröße </h3>
                           <i data-tip data-for={'Mehrfachauswahl'} className="fas fa-info-circle"/>
                           <div className="react-select-container">
                              <Select
                                 onChange={this.zollHandler.bind(this)}
                                 multi={true}
                                 isSearchable={true}
                                 placeholder={'Alle'}
                                 options={this.state.sizeArray}
                                 separator={true}
                                 clearable={true}
                                 disabled={this.state.filterHider || this.props.isFavoriteView}
                                 contentRenderer={this.optionsSelectedZoll}
                                 className={'multi-dd'}
                              />
                           </div>
                        </div>
                        :
                        <div></div>
                     }
                     {filter.BRAND ?
                        <div className={'col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2'}>
                           <h3 className={'d-inline'}> Marken </h3>
                           <i data-tip data-for={'Mehrfachauswahl'} className="fas fa-info-circle"/>
                           <div className="react-select-container">
                              <Select
                                 onChange={this.brandHandler.bind(this)}
                                 multi={true}
                                 isSearchable={true}
                                 placeholder={'Alle'}
                                 separator={true}
                                 clearable={true}
                                 disabled={this.state.filterHider || this.props.isFavoriteView}
                                 contentRenderer={this.optionsSelectedBrand}
                                 options={this.state.brandArray}
                                 className={'multi-dd'}
                              />
                           </div>
                        </div>
                        :
                        <div></div>
                     }
                     {filter.ABE ?
                        <div className={'col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2'}>
                           <h3 className={'d-inline'}> ABE-Freigabe </h3>
                           <i data-tip data-for={'ABE'} className="fas fa-info-circle"/>
                           <ReactTooltip id='ABE'>
                              <span>
                                 Bei der ABE handelt es sich um die Betriebserlaubnis für Kraftfahrzeuge und ihre Bestandteile.
                                 Sie bestätigt, dass ein Fahrzeug oder auch nur eines seiner Autoteile den nationalen Vorschriften
                                 entspricht und somit zugelassen ist.
                              </span>
                           </ReactTooltip>
                           <Dropdown
                              items={['Nur']}
                              disabled={this.state.filterHider || this.props.isFavoriteView}
                              id={filter.ABE.id}
                              defaultValue={this.state.filterSelections.ABE === 'true' ? 'Nur' : 'Alle'}
                              defaultValueOnly={true}
                              onChangeCallBack={this.changeHandler.bind(this)}
                           />
                        </div>
                        :
                        <div></div>
                     }
                     {filter.WINTERPROOF ?
                        <div className={'col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2'}>
                           <h3 className={'d-inline'}> Wintertauglich </h3>
                           <i data-tip data-for={'winter'} className="fas fa-info-circle"/>
                           <ReactTooltip id='winter'>
                              <span>
                                 Die Widerstandsfähigkeit gegen Streusalz und Steinschlag ergibt sich aus
                                 Qualität und Stärke der letzten Schicht auf der Felge.
                              </span>
                           </ReactTooltip>
                           <Dropdown
                              items={['Nur']}
                              id={filter.WINTERPROOF.id}
                              defaultValue={this.state.filterSelections.WINTERPROOF === 'true' ? 'Nur' : 'Alle'}
                              disabled={this.state.filterHider || this.props.isFavoriteView}
                              defaultValueOnly={true}
                              onChangeCallBack={this.changeHandler.bind(this)}
                           />
                        </div>
                        :
                        <div></div>
                     }
                     {filter.COLOR_GROUP ?
                        <div className={'col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2'}>
                           <h3 className={'d-inline'}> Farben </h3>
                           <i data-tip data-for={'Mehrfachauswahl'} className="fas fa-info-circle"/>
                           <div className="react-select-container">
                              <Select
                                 onChange={this.colorHandler.bind(this)}
                                 multi={true}
                                 isSearchable={true}
                                 placeholder={'Alle'}
                                 separator={true}
                                 values={[...this.state.values]}
                                 disabled={this.state.filterHider || this.props.isFavoriteView}
                                 clearable={true}
                                 contentRenderer={this.optionsSelectedColor}
                                 options={this.state.colorArray}
                                 className={'multi-dd'}
                              />
                           </div>
                        </div>
                        :
                        <div></div>
                     }
                     {filter.RETAILPRICE ?
                        <div className={'col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 text-highlight'}>
                           <h3> Preis </h3>
                           <div className={'ml-1'}>
                              <Range min={parseInt(intValues[0])}
                                     max={parseInt(intValues[1])}
                                     defaultValue={intValues}
                                     disabled={this.state.filterHider || this.props.isFavoriteView}
                                     allowCross={false}
                                     onAfterChange={this.rangeChangeHandler.bind(this)}
                              />
                              <span>
                                 von {this.state.filterSelections && this.state.filterSelections.RETAILPRICE &&
                              this.state.filterSelections.RETAILPRICE[0] ?
                                 this.state.filterSelections.RETAILPRICE[0]
                                 :
                                 intValues[0]} €
                              </span>
                              <span style={{float: 'right'}}>
                                            bis {this.state.filterSelections && this.state.filterSelections.RETAILPRICE
                              && this.state.filterSelections.RETAILPRICE[1] ?
                                 this.state.filterSelections.RETAILPRICE[1]
                                 :
                                 intValues[1]} €
                              </span>
                           </div>
                        </div>
                        :
                        <div></div>
                     }
                  </div>
                  <div className={'row info'}>
                     <div className={'col-12 text-right mt-3 ' + this.state.deleteFilterClass}>
                        <span className={'delete-filter'} onClick={this.deleteFilterHandler.bind(this)}>
                           Alle Filter löschen
                        </span>
                     </div>
                     <div className={'col-6 fav-list'}>
                        {!this.props.enableSwitch ?
                           <ReactTooltip id='switch-toggle'>
                              <span>
                                 Es wurden keine Felgen favorisiert. Klicken Sie dafür auf das Stern-Symbol.
                              </span>
                           </ReactTooltip> : ''
                        }
                        <div className={'switch-toggle-wrapper'} data-tip data-for={'switch-toggle'}>
                           <Switch onClick={() => {
                              this.deleteFilterHandler(this);
                              this.props.favRimCallBack(!this.props.isFavoriteView, this.props.enableSwitch);
                           }}
                                   enabled={this.props.enableSwitch}
                                   on={this.props.isFavoriteView && this.props.enableSwitch}/>
                           <div className={'mt-1'}>Favoritenansicht</div>
                        </div>
                     </div>
                     <div className={'col-2 fav-list-xl'}>
                        {!this.props.enableSwitch ?
                           <ReactTooltip id='switch-toggle'>
                              <span>
                                 Es wurden keine Felgen favorisiert. Klicken Sie dafür auf das Stern-Symbol.
                              </span>
                           </ReactTooltip> : ''
                        }
                        <div className={'switch-toggle-wrapper'} data-tip data-for={'switch-toggle'}>
                           <Switch onClick={() => {
                              this.deleteFilterHandler(this);
                              this.props.favRimCallBack(!this.props.isFavoriteView, this.props.enableSwitch);
                           }}
                                   enabled={this.props.enableSwitch}
                                   on={this.props.isFavoriteView && this.props.enableSwitch}/>
                           <div className={'mt-1'}>Favoritenansicht</div>
                        </div>
                     </div>
                     <div className={'col-2 fav-list-xl'}>
                        {!this.props.enableSwitch ?
                           <ReactTooltip id='switch-toggle'>
                              <span>
                                 Es wurden keine Felgen favorisiert. Klicken Sie dafür auf das Stern-Symbol.
                              </span>
                           </ReactTooltip> : ''
                        }
                        <div className={'switch-toggle-wrapper'} data-tip data-for={'switch-toggle'}>
                           <Switch onClick={() => {
                              this.deleteFilterHandler(this);
                              this.props.favRimCallBack(!this.props.isFavoriteView, this.props.enableSwitch);
                           }}
                                   enabled={this.props.enableSwitch}
                                   on={this.props.isFavoriteView && this.props.enableSwitch}/>
                           <div className={'mt-1'}>Favoritenansicht</div>
                        </div>
                     </div>
                     <div className={'col-2 total-count-xl text-right'}>
                        <div className={'mt-1'}>{this.props.totalCount} Felgen</div>
                     </div>
                     <div className={'col-6 total-count text-right'}>
                        <div className={'mt-1'}>{this.props.totalCount} Felgen</div>
                     </div>
                     <div
                        className={'col-6 ' + (this.state.filterhidertext === 'Zurück zur Felgenauswahl' ? 'filter-display ' + this.state.filterClass : 'd-none')}
                        onClick={this.filterHandler.bind(this)}>
                        {this.state.filterhidertext}
                     </div>
                  </div>
               </div>
               <ReactTooltip id='Mehrfachauswahl'>
                  <span>
                     Hier kann eine Mehrfachauswahl getroffen werden.
                  </span>
               </ReactTooltip>
            </div>
         );
      } else {
         content = (
            <div className={'rim-filter-wrapper full-width-wrapper'}>
               <div className={'container text-right'}>
                  Keine Filter vorhanden
               </div>
            </div>
         );
      }
      return content;
   }
}

RimFilter.serverFetch = '';//tyreDetailActionSSR; // static declaration of data requirements

const mapStateToProps = state => ({});

const mapDispatchToProps = {
   tyreDetailAction
};

export default connect(mapStateToProps, mapDispatchToProps)(RimFilter);

