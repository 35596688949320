import React from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';

let title = [];
let body = [];

class Imprint extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         firmData: this.props.firmData
      };
   }

   imprintHeader(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         return (
            <div>
               <h2> {i.Name} </h2>
               {i.Strasse}
               <br/>
               {i.Plz} {i.Ort} <br/>
               <br/>
            </div>
         );
      }
   }

   imprintComplementary(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         let x = i.Impressum;
         if (i.Impressum && x.Vertretungsberechtigt) {
            return (
               <div>
                  <strong>{x.Vertretungsberechtigt}</strong> <br/>
                  {x.Komplementaer} <br/>
                  <br/>
               </div>
            );
         }
      }
   }

   imprintSubstitutePerson(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         if (i.VertretungsberechtigtePerson) {
            return (
               <div>
                  <strong>{i.VertretungsberechtigtePerson}</strong> <br/>
               </div>
            );
         }
      }
   }

   imprintJurisdicision(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;

         if (i.Gerichtsstand) {
            return (
               <div>
                  <strong>Gerichtsstand</strong> <br/>
                  {i.Gerichtsstand} <br/>
                  <br/>
               </div>
            );
         }
      }
   }

   imprintContact(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         if (i) {
            return (
               <div>
                  <strong>Kontakt</strong> <br/>
                  Telefon: {i.Telefon} <br/>
                  Fax: {i.Fax} <br/>
                  Mail: {i.Email} <br/>
                  <br/>
               </div>
            );
         }
      }
   }

   imprintEnding(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         if (i) {
            return (
               <div className="imprint-ending mb-3">
                  <hr/>
                  <p>Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind
                     wir nicht verpflichtet. Wir nehmen an einem Streitbeilegungsverfahren vor einer
                     Verbraucherschlichtungsstelle auch nicht teil.</p>
                  <p>Verbraucherinformation gemäß Verordnung (EU) Nr.524/2013:<br/>An dieser Stelle stellen wir
                     Ihnen den <a
                        href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=DE"
                        target="_blank">Link</a> zur OS-Plattform (Online
                     Streitbeilegungsplattform) der EU-Kommission zur Verfügung.<br/>Unsere E-Mailadresse lautet:
                     {i.Mail}</p>
               </div>
            );
         }
      }
   }

   imprintHeadquarters(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         if (i.Firmensitz) {
            return (
               <div className="mb-3">
                  <strong>Firmensitz</strong> <br/>
                  {i.Firmensitz} <br/>
                  {i.Gerichtsstand}
               </div>
            );
         }
      }
   }

   imprintDistrictCourt(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         if (i.AmtsgerichtHRB) {
            return (
               <div className="mb-3">
                  <strong>Handelsregisternummern</strong> <br/>
                  {/*{x.AmtgerichtHRA} {x.HRA} <br/>*/}
                  {i.AmtgerichtHRB} {i.HRB}
               </div>
            );
         }
      }
   }

   imprintLegalNotice(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         if (i.RechtlicherHinweisTitel && i.RechlicherHinweis) {
            return (
               <div className="mb-3">
                  <strong>{i.RechlicherHinweisTitel}</strong> <br/>
                  {i.RechlicherHinweis}
               </div>
            );
         }
      }
   }

   imprintPrivacyPolicy(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         if (i.Datenschutzerklaerung) {
            return (
               <div className="mb-3">
                  <strong>{x.DatenschutzerklaerungTitel}</strong> <br/>
                  {x.Datenschutzerklaerung}
               </div>
            );
         }
      }
   }

   imprintPersonalData(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         let x = i.Impressum;
         if (x) {
            return (
               <div className="mb-3">
                  <strong>{x.PersoenlicheDatenTitel}</strong> <br/>
                  {x.PersoenlicheDaten}
               </div>
            );
         }
      }
   }

   imprintUse(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         let x = i.Impressum;
         if (x) {
            return (
               <div>
                  <strong>{x.NutzungTitel}</strong> <br/>
                  {x.Nutzung}
               </div>
            );
         }
      }
   }

   imprintLinks(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         let x = i.Impressum;
         if (x) {
            return (
               <div className="mb-3">
                  <strong>{x.LinksTitel}</strong> <br/>
                  {x.Links}
               </div>
            );
         }
      }
   }

   imprintSecurity(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         let x = i.Impressum;
         if (x) {
            return (
               <div className="mb-3">
                  <strong>{x.SicherheitTitel}</strong> <br/>
                  {x.Sicherheit}
               </div>
            );
         }
      }
   }

   imprintUst(num) {
      if (this.state.firmData[num]) {
         let i = this.state.firmData[num].AllgemeineDaten;
         if (i.UmsatzsteuerID) {
            return (
               <div className="mb-3">
                  <strong>Umsatzsteuer-Identifikationsnummer</strong> <br/>
                  {i.UmsatzsteuerID}
               </div>
            );
         }
      }
   }

   render() {
      return (
         <div className="container site-wrapper">
            <h1 className={"mb-3"}>
               Impressum
               <br/>
               <small>Angaben gemäß § 5 TMG</small>
            </h1>
            {this.imprintHeader(0)}
            {/*{this.imprintComplementary(0)}*/}
            {this.imprintSubstitutePerson(0)}
            {this.imprintJurisdicision(0)}
            {this.imprintContact(0)}
            {this.imprintHeadquarters(0)}
            {/*{this.imprintDistrictCourt(0)}*/}
            {/*{this.imprintLegalNotice(0)}*/}
            {this.imprintPrivacyPolicy(0)}
            {this.imprintPersonalData(0)}
            {this.imprintUse(0)}
            {this.imprintLinks(0)}
            {this.imprintSecurity(0)}
            {this.imprintUst(0)}
            <div className={"mt-5 mb-2"}>
               Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht
               verpflichtet. Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle auch
               nicht teil.
            </div>
            <div>
               Verbraucherinformation gemäß Verordnung (EU) Nr.524/2013:
               An dieser Stelle stellen wir Ihnen den
               <a href={"https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"}> Link</a> zur
               OS-Plattform (Online Streitbeilegungsplattform) der EU-Kommission zur Verfügung.
               Unsere E-Mailadresse lautet: {this.state.firmData[0].AllgemeineDaten.Email}
            </div>
            <Helmet>
               <title>Impressum</title>
            </Helmet>
         </div>
      );
   }
}

const mapStateToProps = state => ({
   firmData: state.firmData
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Imprint);
