import React from 'react';
import {Link} from 'react-router-dom';
import Slider from 'rc-slider';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CarImage from './CarImage';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Handle = Slider.Handle;

class CarView extends React.Component {
   constructor(props) {
      super(props);
      this.myRef = React.createRef();
      this.state = {
         carClass: 'active',
         bgClass: 'inactive',
         colorStatus: false,
         selectedBackground: 'city',
         backgroundStatus: 'active'
      };

      this.setColor = this.setColor.bind(this);
   }

   rangeHandler(e) {
      this.props.loweringCallback(e);
   }

   colorPlateRenderer() {
      let returnarray = [];
      let counter = 0;
      if (this.props.carColors) {
         for (let color of this.props.carColors) {
            returnarray.push(
               <img key={color.name}
                    className={'img img-fluid color-plate-img' + (this.props.color == color.name ? ' current' : '')}
                    id={color.name} onClick={this.setColor} src={`/ggColors/${color.name}`}/>
            );
            counter += 1;
         }
      }
      return returnarray;
   }

   classSwitcher(e) {
      if (e.target.id == 'bg') {
         this.setState({
            bgClass: 'active',
            carClass: 'inactive'
         });
      } else {
         this.setState({
            bgClass: 'inactive',
            carClass: 'active'
         });
      }
   }

   setColor(e) {
      this.props.carImageCallback(true, e.target.id);
   }

   backgroundClickHandler() {
      let newState = this.state;
      newState.backgroundStatus = !this.state.backgroundStatus;
      this.setState(newState);
   }

   selectBackground(e) {
      this.setState({selectedBackground: e});
   }

   render() {
      return (
         <div className={'car-view-wrapper'}>
            <div className={'background-view ' + this.state.selectedBackground}>
               <div className={'container-fluid'}>
                  <div className={'max-content car-canvas'}>
                     <div className={' row'}>
                        <div className={'car-data col-12 col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2'}>
                           <div className={'bg-milky'}>
                              <h3>{this.props.car.manufacturer} </h3>
                              <div>{this.props.car.tradeName}</div>
                              <div>{this.props.car.ps} PS / {this.props.car.kw} kW</div>
                              <div>{this.props.car.fuel} </div>
                              <div>
                                 <Link to={this.props.rimPath}
                                       onClick={() => this.props.history.push(this.props.rimPath)}
                                       className={'btn btn-primary'}>
                                    <span className={' d-none d-md-block'}>Fahrzeug ändern</span>
                                    <span className={' d-block d-md-none'}>Ändern</span>
                                 </Link>
                              </div>
                           </div>
                        </div>
                        <div
                           className={'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 text-center image-height mt-4 position-relative'}>
                           <CarImage lowering={this.props.lowering}
                                     base64img={this.props.base64img}
                                     rimId={this.props.rimId}
                                     carId={this.props.carId}
                                     color={this.props.color}
                                     images={this.props.images}
                           />
                        </div>
                        <div
                           className={'car-configuration-wrapper col-12 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-2'}>
                           <div className={'bg-milky box  d-none d-lg-block'}>
                              <Tabs defaultActiveKey="car">
                                 <Tab tabClassName={'bg-tab'} eventKey="background" title="Hintergrund">
                                    <div
                                       className={'backgrounds' + (this.state.backgroundStatus ? ' active' : '')}>
                                       <div onClick={this.selectBackground.bind(this, 'city')}
                                            className={'background-view city' + (this.state.selectedBackground == 'city' ? ' current' : '')}/>
                                       <div onClick={this.selectBackground.bind(this, 'sunstreet')}
                                            className={'background-view sunstreet' + (this.state.selectedBackground == 'sunstreet' ? ' current' : '')}/>
                                       <div onClick={this.selectBackground.bind(this, 'snow')}
                                            className={'background-view snow' + (this.state.selectedBackground == 'snow' ? ' current' : '')}/>
                                       <div onClick={this.selectBackground.bind(this, 'background-grey')}
                                            className={'background-view background-grey' + (this.state.selectedBackground == 'background-grey' ? ' current' : '')}/>
                                       <div onClick={this.selectBackground.bind(this, 'background-none')}
                                            className={'background-none' + (this.state.selectedBackground == 'background-none' ? ' current' : '')}/>
                                    </div>
                                 </Tab>
                                 <Tab tabClassName={'car-tab'} eventKey="car" title="Auto">
                                    <h4> Außen </h4>
                                    <div className={'color-plate mb-2'}>
                                       {this.colorPlateRenderer()}
                                    </div>
                                    <div className="lowering-pos">
                                       <h4> Tieferlegung </h4>
                                       <Slider defaultValue={1} min={1} max={10}
                                               onChange={this.rangeHandler.bind(this)}/>
                                    </div>
                                 </Tab>
                              </Tabs>
                           </div>
                           <div className={'bg-milky box d-block d-lg-none'}>
                              <div
                                 className={'half-width' + ((this.colorPlateRenderer().length <= 5) ? ' center-colors ' : '')}>
                                 <div className={'color-plate d-inline-block'}>
                                    <h4>Farbe</h4>
                                    {this.colorPlateRenderer()}
                                 </div>
                              </div>
                              <div className={'half-width'}>
                                 <h4>Tieferlegung</h4>
                                 <Slider defaultValue={1} min={1} max={10}
                                         onChange={this.rangeHandler.bind(this)}/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default CarView;
