import fetch from "isomorphic-fetch";

let requestLoad;

/*
    selectedTyre Search
    requestBody contains request params
 */
export function getTyres(reqBody, env) {
   let environment = env;
   let requestBody = JSON.parse(reqBody);
   requestBody.env = environment;
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/tyre/search`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(requestBody)
   }).then(res => res.json()).then(res => {
      let newres = [];
      let resItem = res;
      if (resItem.filters) {
         res = resItem.tires;
      }
      for (let items of res) {
         if (items && items.productId) {
            let TireDTO = {
               "productId": items.productId,
               "description": items.description,
               "speedIndex": items.speedIndex,
               "loadIndex": items.loadIndex,
               "tread": items.tread,
               "rft": items.rft,
               "brand": items.brand,
               "ean": items.ean,
               "ipc": items.ipc,
               "totalAmount": items.totalAmount,
               "productImageUrl": items.productImageUrl,
               "vehicleTypes": items.vehicleTypes,
               "season": items.season,
               "quality": items.quality,
               "B2CPrice": items.B2CPrice,
               "rollingResistance": items.rollingResistance,
               "noiseDb": items.noiseDb,
               "wetGrip": items.wetGrip,
               "productCategoryName": items.productCategoryName
            }
            newres.push(TireDTO);
         } else {
            newres.push(items);
         }
      }
      let returnDTO = {};
      if (resItem.filters) {
         returnDTO.tires = newres;
         returnDTO.filters = resItem.filters;
      } else returnDTO = newres;
      return returnDTO;
   }).catch(error => {
      console.log(error)
      return error
   });

}

/*
    Get one selectedTyre by ID
 */
export function getTyreDetails(params) {
   const url = `${process.env.HOST ? process.env.HOST : ""}/api/tyre/detail/${params.id}/${params.mandatorStoreNumber}`;
   return fetch(url).then(res => res.json().then(res => {
      let newres = {};
      let tire = res.product;
      if (tire) {
         let TireDTO = {
            "productId": tire.id,
            "description": tire.description1,
            "speedIndex": tire.speedIndex,
            "loadIndex": tire.loadIndex,
            "tread": tire.tread,
            "rft": tire.rft,
            "brand": tire.brandName,
            "ean": tire.ean,
            "ipc": tire.ipc,
            "totalAmount": tire.amount,
            "productImageUrl": tire.productImages.standardImage,
            "vehicleTypes": tire.vehicleTypes,
            "season": tire.season,
            "quality": tire.quality,
            "B2CPrice": tire.B2CPrice,
            "rollingResistance": tire.rollingResistance,
            "noiseDb": tire.noiseDb,
            "wetGrip": tire.wetGrip,
            "productCategoryName": tire.productCategoryName,
            labelImageUrl: tire.labelImageUrl
         }
         newres = TireDTO;
         if (res.items[0].B2CPrice) {
            newres.B2CPrice = res.items[0].B2CPrice
         }
      } else {
         newres = res.product;

      }
      return newres;
   }));
}

/*
    Get TyreSizes Async
    Reload if season is changes by CS Rendering
 */
export function getTyreSizes() {
   try {
      return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/tyre/sizes`)
      .then(res => res.json())
      .then(res => res);
   } catch (err) {
   }
}

export function sendOrder(order) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/postOrder`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: order
   })
   .then(function (response) {
      return response.json();
   })
   .catch(function (err) {
      return String(err);
   });
}

export function getCacheFolder() {
   const url = `${process.env.HOST ? process.env.HOST : ""}/api/getCacheFolder`;
   return fetch(url).then(res => res.json()).then(res => res);
}

/*
    Get one selectedTyre by ID
 */
// export function getCarModels(params) {
//   const url = `${process.env.HOST ? process.env.HOST : ""}/api/rimconfigurator/car/`;
// return fetch(url + params.id).then(res => res.json());
// }

/*
   Rim Search
   RequestBody contains request params
*/

export function getCarModels(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getCarModels`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(reqBody)
   }).then(res => res.json());
}

export function getManufacturers(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getManufacturers`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(reqBody)
   }).then(res => res.json());
}

export function getCarTypes(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getCarTypes`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(reqBody)
   }).then(res => res.json());
}

export function getClasses(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getClasses`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

export function getTypes(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getTypes`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

export function getCarIdByHsnTsn(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getCarIdByHsnTsn`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

export function getRimDetails(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getRimDetails`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

export function getCarDetails(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getCarDetails`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());

}

export function getCarColors(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getCarColors`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

/*
   Rim Search
   RequestBody contains request params
*/
export function getRims(reqBody) {
   if (requestLoad != reqBody) {
      let newRequestBody = {
         'limit': reqBody.limit,
         'offset': reqBody.offset,
         'filter': reqBody.filterSelection,
         'carId': reqBody.carId
      }
      requestLoad = reqBody;
      return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/searchRims`, {
         method: "post",
         headers: {Accept: "application/json", "Content-Type": "application/json"},
         body: JSON.stringify(newRequestBody)
      }).then(res => res.json());
   } else return false;
   1
}

export function getRatio(width, vehicleType) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/ratios/${width}/${vehicleType}`, {
      method: "get",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
   }).then(res => res.json());
}

export function getDiameter(width, ratio, vehicleType) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/diameter/${width}/${ratio}/${vehicleType}`, {
      method: "get",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
   }).then(res => res.json());
}

/*
   Rim Search
   RequestBody contains request params
*/
export function pointsCodeGenerator(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/rim/wishlist`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(reqBody)
   })
   .then(function (response) {
      return response.json();
   })
   .catch(function (err) {
      return String(err);
   });
}


export function getAssemblyCosts(firmData) {
   let costs = firmData[0].AllgemeineDaten.Montage_RLS.split(',');
   let assemblyCosts = {
      "assemblyCostAlloy": parseFloat(costs[1]),
      "assemblyCostRft": parseFloat(costs[0]),
      "assemblyCostSteel": parseFloat(costs[2])
   }
   return assemblyCosts;
}
