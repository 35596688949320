import {getTyreDetails} from '../api';
import * as actionTypes from './actionTypes';


export const tyreDetailAction = (params) => (dispatch) => {
   dispatch(loadselectedTyre());
   setTimeout(
      function () {
         getTyreDetails(params).then(
            function (res) {
               if (res && res.Error != '' && res.Error != null && res.Error != undefined) {
                  dispatch(loadTyreError(res.Error));
               } else {
                  dispatch(storeselectedTyre(res));
               }
            }
         );
      }
      .bind(this),
      500
   );
}


export const tyreDetailActionSSR = (params) => (dispatch) =>
   getTyreDetails(params).then(res => dispatch(storeselectedTyre(res)));

const storeselectedTyre = (selectedTyre) => {
   return {
      type: actionTypes.STORE_selectedTyre,
      selectedTyre
   }
}


const loadselectedTyre = () => ({
   type: actionTypes.LOAD_selectedTyre
});


const loadTyreError = (error) => ({
   type: actionTypes.LOAD_selectedTyre_ERROR,
   error: error
});
