import React from 'react';
import moment from 'moment';
import 'moment/min/moment-with-locales';
import {connect} from 'react-redux';

let globalTime = [];

class Calendar extends React.Component {

    constructor(props) {
        super(props);
        moment.locale('de');
        this.state = {
            open: 0,
            skipDays: 3,
            chosenTime: 0,
            chosenDate: 0,
            chosenDay: 0,
            chosenDateTime: [],
            checkedTime: 0,
            deleteDateTime: null
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate() {
        if (this.props.deleteDateTime != null) {
            let newstate = this.state;
            let remove = this.props.deleteDateTime;
            newstate.chosenDateTime.splice(remove, 1);
            this.props.deleteCallback(null);
            this.setState(newstate);
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (event.target.id != this.state.open) {
                this.setState({
                    open: 0
                });
            }
        }
    }

    handleClick(e) {
        let id = '';
        if (e.target.id) {
            id = e.target.id;
        } else if (e.target.parentNode.id) {
            id = e.target.parentNode.id;
        } else {
            id = e.target.parentNode.parentNode.id;
        }
        if (this.state.open != id && id != '') {
            this.setState({
                open: id
            });
        }

    }

    handleTime(e) {
        let newstate = this.state;
        let dayInFuture = this.state.open;
        let selectedDate;
        let remove = -1;
        newstate.chosenDate = globalTime[dayInFuture - 1];
        newstate.chosenDateTime = this.state.chosenDateTime;

        selectedDate = {
            'chosenDate': newstate.chosenDate,
            'chosenTime': e.target.id,
            'chosenDay': e.target.parentNode.parentNode.id,
            'checkedTime': true
        };

        if (this.state.chosenDateTime.length === 0) {
            newstate.chosenDateTime.push(selectedDate);

        } else {
            this.state.chosenDateTime.forEach((data, index) => {

                if (data.chosenTime == selectedDate.chosenTime && data.chosenDate == selectedDate.chosenDate) {
                    remove = index;
                }
            });
            if (remove > -1) {
                newstate.chosenDateTime.splice(remove, 1);
            } else {
                newstate.chosenDateTime.push(selectedDate);
            }
        }
        this.props.callBackFromParent(newstate.chosenDateTime);
        this.setState(newstate);
    }

    times(time, day) {
        return (
            time.map((_time) => (
                <button id={_time} key={_time + day}
                        className={'btn btn-outline-primary time-button ' + (this.checkDateTime(day, _time))}
                        onClick={(this.checkDateTime(day, _time) != 'disabled') ? this.handleTime.bind(this) : this.handleClick.bind(this)}>  {_time}  </button>
            ))

        );
    }

    checkDateTime(day, _time) {
        let newstate = this.state;
        let checkedTime = '';
        newstate.chosenDateTime.forEach((time, index) => {
            if (time.chosenDay === day.toString() && time.chosenTime === _time.toString()) {
                checkedTime = 'checked-time';
            } else if (this.state.chosenDateTime.length === 3 && checkedTime !== 'checked-time') {
                checkedTime = 'disabled';
            }
        });
        return checkedTime;
    }

    checkState(day) {
        let temp = 'unchecked';
        this.state.chosenDateTime.forEach((time) => {
            if (time.chosenDay === day.toString()) {
                temp = 'checked';
            }
        });
        return temp;
    }

    showWeekdays() {
        let weekDayArray = [];
        for (let i = 1; i <= 7; i++) {
            const currentDayName = moment()
                .add(i, 'day');
            weekDayArray.push(
                <span className="text-center calendar-day-chart" key={i + 'wd'}>{moment(currentDayName)
                    .format('dd')}</span>
            );
        }
        return weekDayArray;
    }

    mapDate() {
        let dateArray = [];
        for (let day = 1; day < 29; day++) {
            let timeArea = '';

            const currentDay = moment()
                .add(day, 'day');

            let time = [];
            let counter = this.props.userdata.passedvalues.passedvalues.counter;
            if (counter === null) {
                counter = '0';
            }
            const debitor = this.props.firmData;
            if (this.props && this.props.userdata && debitor &&
                debitor[counter] &&
                debitor[counter].openingTimes) {

                const times = debitor[counter].openingTimes;
                const openingTimes = times.split('\n');

                const weekdayNo = moment()
                    .add(day, 'day')
                    .day();
                globalTime.push(currentDay.format('l'));
                if (weekdayNo > 0) { // nicht sonntag
                    timeArea = openingTimes[weekdayNo];
                    if (timeArea != undefined) {
                        timeArea = timeArea.replace(/-/g, ' ');
                        timeArea = timeArea.split(' ');
                        /* if the first position in the array is empty, it must be removed so that the times are rendered correctly */
                        if (timeArea[0] == "") {
                            timeArea.shift();
                        }
                        for (let u = 1; u < timeArea.length; u = u + 2) {
                            let now = moment(currentDay).format('YYYY-MM-DD ') + timeArea[u] + '';
                            let then = moment(currentDay).format('YYYY-MM-DD ') + timeArea[u + 1] + '';
                            for (; moment(then)
                                       .isAfter(now); now = moment(now)
                                .add(1, 'hours')) {
                                time.push(moment(now)
                                    .format('HH:mm'));
                            }
                        }
                    }
                }
            }

            dateArray.push(
                <div key={day} onClick={this.handleClick.bind(this)}
                     className={'text-center calendar-day-chart'}>

                    {(moment(currentDay)
                        .day() != '0' && day > this.state.skipDays && (this.state.chosenDateTime.length <= 2 || this.checkState(day) === 'checked')) ?
                        <div id={day} className={' ' + (this.state.open == day ? 'open' : 'closed') + ' '
                        + (this.checkState(day))}>
                            <span>
                                <span className="calendar-weekday"> {moment(currentDay)
                                    .format('dddd')}</span>
                                <span className="calendar-day">{moment(currentDay)
                                    .format('D')}</span>
                               <span className="calendar-month"> {moment(currentDay)
                                   .format('MMMM')}</span>
                               <span className="calendar-month-short"> {moment(currentDay)
                                   .format('MMM')}</span>
                            </span>
                            {this.state.open == day ?
                                <div ref={this.setWrapperRef} className='calendar-day-times'>
                                    {this.times(time, day)}
                                </div>
                                : ''
                            }

                        </div>
                        :
                        <div id={day} className="not-available" title="nicht verfügbar">
                            <span>
                                <span className="calendar-weekday"> {moment(currentDay)
                                    .format('dddd')} </span>
                                <span className="calendar-day">{moment(currentDay)
                                    .format('D')}</span>
                                <span className="calendar-month"> {moment(currentDay)
                                    .format('MMMM')}</span>
                               <span className="calendar-month-short"> {moment(currentDay)
                                   .format('MMM')}</span>
                            </span>
                        </div>
                    }
                </div>
            );
        }
        return dateArray;
    }

    render() {
        return (
            <div className="calendar-border">
                <div className="calendar-weekday-bar"> {this.showWeekdays()} </div>
                {this.mapDate()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    firmData: state.firmData
});
export default connect(mapStateToProps)(Calendar);
