import * as actionTypes from "./actionTypes";

export const setNavigation = loc => {
   return {
      type: actionTypes.SET_NAVIGATION,
      location: loc
   };
};

export const setPage = page => {
   return {
      type: actionTypes.SET_PAGE,
      page: page
   };
};


export const homeSearch = () => {
   return {
      type: actionTypes.SET_HOME_SEARCH,
      homeSearch: true
   };
};

export const resetHomeSearch = () => {
   return {
      type: actionTypes.RESET_HOME_SEARCH,
      homeSearch: false
   };
};
