import React from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../../../../redux-store/actions/index';
import {Link} from 'react-router-dom';
import DropDown from '../../../shared/dropDown/DropDown';
import SeasonIcon from '../../../shared/seasonIcon/SeasonIcon';
import TyreLabel from '../../../shared/tyreLabel/TyreLabel';
import TotalPrice from '../../../shared/priceAmountCaption/TotalPrice';
import {getAssemblyCosts} from '../../../../../redux-store/api';

let items = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

class TyreResult extends React.Component {
   constructor(props) {
      super(props);
      let isInCart = 0;
      this.state = {
         computedAmount: 4,
         isInCart: isInCart,
         loading: false,
         init: true
      };
      this.props.shoppingCartTyres.forEach(selectedTyre => {
         if (selectedTyre.productId === this.props.selectedTyre.productId) {
            this.state.isInCart = parseInt(selectedTyre.amount);
            this.state.computedAmount = parseInt(selectedTyre.amount);
         }
      });
      this.state.init = false;
      this.amountSelectionHandler = dataFromChild => {
         this.setState({computedAmount: dataFromChild});
         this.props.onAmountSelected(dataFromChild, this.props.selectedTyre.productId);
      };
   }

   handleShoppingCartButton() {
      this.setState({loading: true});
      let tire = this.props.selectedTyre;
      let assemblyCosts = getAssemblyCosts(this.props.firmData)
      if (assemblyCosts) {
         tire = {
            ...this.props.selectedTyre,
            response: assemblyCosts
         };
      }
      this.addToCart(tire);
   }

   addToCart(selectedTyre) {
      this.props.onAddTyreToSC(selectedTyre, this.state.computedAmount);
      setTimeout(
         function () {
            this.setState({loading: false});
            this.setState({isInCart: this.state.computedAmount});
         }.bind(this),
         1200
      );
      this.props.onUpdateTotalPrice();
   }

   buttonContent() {
      if (this.state.init) {
         return <span>...</span>;
      }
      if (this.state.loading === true) {
         return (
            <span>
               <i className="fas fa-circle-notch fa-spin"/>
            </span>
         );
      }
      if (this.state.isInCart > 0 && this.state.computedAmount !== this.state.isInCart) {
         return (
            <span>
               <span className="from-size-714"> Menge ändern <i className="fas fa-check"/></span>
               <span className="until-size-714"> <i className="fas fa-sync-alt"/> </span>
            </span>
         );
      }
      if (this.state.isInCart > 0 && this.state.computedAmount === this.state.isInCart) {
         return (
            <span>
               <div className="from-size-714"> {this.state.isInCart} Stück </div>
               <i className="fas fa-check"/>
            </span>
         );
      }
      return (
         <span>
            <i className="fas fa-shopping-cart"/>
            <span className="from-size-714">In den Warenkorb</span>
         </span>
      );
   }

   render() {
      const detailPath = '/tyre/';
      let imageUrl = this.props.selectedTyre.productImageUrl ? this.props.selectedTyre.productImageUrl.split("content.gettygo.com")[1] : "";
      // let imageUrl = "";
      return (
         <div className="row">
            <div className="col-6 col-sm-4 col-md-3 col-xl-3">
               <Link to={detailPath + this.props.selectedTyre.productId + '/' + this.props.firmData[0].Id}>
                  <SeasonIcon season={this.props.selectedTyre.season}/>
                  <div className="result-img-container">
                     <img className="result-img-size"
                          src={this.props.selectedTyre.productImageUrl
                             ? imageUrl
                             : '/images/noimage.png'
                          }/>
                  </div>
               </Link>
            </div>
            <div className="col-6 col-sm-4 col-md-4 col-xl-4">
               <Link to={detailPath + this.props.selectedTyre.productId + '/' + this.props.firmData[0].Id}>
                  <div className="tyre-results-description">
                     <h2 className={this.props.selectedTyre.season}>
                        {this.props.selectedTyre.brand}
                     </h2>
                     <div className="dark-text">
                        <div>
                           {this.props.selectedTyre.tread}
                        </div>
                        {this.props.selectedTyre && this.props.selectedTyre.description && this.props.selectedTyre.description.includes(this.props.selectedTyre.tread) ?
                           <div>
                              {this.props.selectedTyre.description.split(this.props.selectedTyre.tread)}
                           </div>
                           :
                           <div>
                              {this.props.selectedTyre.description}
                           </div>
                        }
                        <div>
                           {this.props.selectedTyre.productCategoryName && (this.props.selectedTyre.productCategoryName).startsWith("category_pc")
                              ? '  PKW  '
                              : (this.props.selectedTyre.productCategoryName && this.props.selectedTyre.productCategoryName).startsWith("category_lt")
                                 ? '  LKW  '
                                 : '   SUV  '}
                           {this.props.selectedTyre.season === 'winter'
                              ? 'WINTER'
                              : this.props.selectedTyre.season === 'summer'
                                 ? 'SOMMER'
                                 : 'GANZJAHRES'}
                        </div>
                     </div>
                     <div className="spacing-top">
                        <span className="dark-text">EU-REIFENLABEL</span>
                     </div>
                     {this.props.selectedTyre ?
                        <TyreLabel rollingResistance={this.props.selectedTyre.rollingResistance}
                                   wetGrip={this.props.selectedTyre.wetGrip}
                                   noiseEmission={this.props.selectedTyre.noiseDb}
                        />
                        : ""}

                  </div>
               </Link>
            </div>
            <div className="col-12 col-sm-4 col-md-5 col-xl-5 text-right">
               <div className="row">
                  <div className="col-12">
                     <TotalPrice price={(this.props.selectedTyre.B2CPrice ? this.props.selectedTyre.B2CPrice : 0)}
                                 ownClass={'result'}
                                 handleShoppingCartButton={this.handleShoppingCartButton}
                                 loading={this.state.loading}
                                 buttonContent={this.buttonContent}
                     />
                  </div>
                  <div className={this.props.selectedTyre.B2CPrice ? "col-12 mt-3" : "col d-none"}>
                     <div className="position-button float-right">
                        <button className={'btn btn-primary' + (this.state.loading ? ' loading' : '')}
                                disabled={this.state.loading}
                                onClick={() => {
                                   this.handleShoppingCartButton();
                                }}>
                           {this.buttonContent()}
                        </button>
                     </div>
                     <div className="custom-dd-tire float-right">
                        <DropDown
                           items={items}
                           onChangeCallBack={this.amountSelectionHandler}
                           defaultValue={this.state.isInCart === 0 ? '4' : this.state.isInCart}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => ({
   shoppingCartTyres: state.shoppingcart.shoppingCartTyres,
   firmData: state.firmData
});

const mapDispatchToProps = dispatch => ({
   onAddTyreToSC: (selectedTyre, amount) => dispatch(actionCreators.setSelectedCartTyre(selectedTyre, amount)),
   onScTireAmountSelected: (amount, tyreId) => dispatch(actionCreators.setShoppingCartTireSelectedAmount(amount, tyreId)),
   onUpdateTotalPrice: () => dispatch(actionCreators.updateTotalPrice()),
   onAmountSelected: (amount, id) => dispatch(actionCreators.setSelectedAmount(amount, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(TyreResult);
