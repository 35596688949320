// react
import React from 'react';
import {Link} from 'react-router-dom';

// redux
import {connect} from 'react-redux';
import SeasonIcon from '../seasonIcon/SeasonIcon';
import TyreLabel from '../tyreLabel/TyreLabel';
import Dropdown from '../dropDown/DropDown';
import * as actionCreators from '../../../../redux-store/actions';
import TotalPrice from '../priceAmountCaption/TotalPrice';

class RimCartPosition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deletable: true,
            amountSelectionChanged: this.props.amountSelectionChanged
            // assemblyCost: ( this.props.selectedTyre.assemblyCosts ? this.props.selectedTyre.assemblyCosts : 0.00 ),
            // assemblyType: ( this.props.selectedTyre.assemblyType ? this.props.selectedTyre.assemblyType : 'None' )
        };
        this.shoppingCartRimAmountSelectionHandler = (amount, currentRimId) => {
            this.props.selectedRim.amount = amount;
            this.props.onShoppingCartRimChanged(this.props.selectedRim);
            this.props.onAmountCount();
            this.props.onUpdateTotalPrice();
        };
    }

    // handleAssemblyCosts( assemblyType ) {
    //     this.props.selectedTyre.assemblyType = assemblyType;
    //     this.props.onShoppingCartTireChanged( this.props.selectedTyre );
    //     this.props.onUpdateTotalPrice();
    //     this.setState( {
    //         assemblyType
    //     } );
    // }

    removeButtonHandlers(idx) {
        this.props.onRemoveButtonHandlers(idx);
        this.props.onUpdateTotalPrice();
    }

    render() {
        // let selectedAssemblyCostsValue = null;
        // let assemblyCostsValue = null;
        // this.props.scTires.forEach( selectedTyre => {
        //     if ( selectedTyre.productId === this.props.selectedTyre.productId ) {
        //         selectedAssemblyCostsValue = selectedTyre.selectedAssemblyCosts;
        //         assemblyCostsValue = selectedTyre.assemblyCosts;
        //     }
        // } );
        const selectedRim = this.props.selectedRim;
        let imageUrl = '';
        selectedRim !== undefined && selectedRim !== {} && selectedRim.id ? (imageUrl = 'api/rimDetailRotateImage/' + selectedRim.id) :  ( imageUrl = '/images/noimage.png' );

        // const season = ( selectedTyre && selectedTyre.productCategoryName ) ?
        //     ( selectedTyre.productCategoryName.includes( 'summer' ) ? 'summer' :
        //         selectedTyre.productCategoryName.includes( 'winter' ) ? 'winter'
        //             : 'all' )
        //     : '';
        // const detailpage = '/tyre/';
        const items = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        // const assemblycosts = [ `Runflat ${ selectedTyre.assemblyCostRft.toFixed( 2 )
        //     .replace( '.', ',' ) } €`,
        //     `Stahlfelge ${ selectedTyre.assemblyCostSteel.toFixed( 2 )
        //         .replace( '.', ',' ) } €`,
        //     `Leichtmetallfelge  ${ selectedTyre.assemblyCostAlloy.toFixed( 2 )
        //         .replace( '.', ',' ) } €` ];
        return (
            <div className="tyre-cart-pos-wrapper">
                <div className="tyre-cart-pos-details">
                    <div className="row">
                        <div className="col-5 d-none d-sm-block col-sm-4 col-md-3 col-xl-3">
                            {/* {this.props.deletable === true ? */}
                            {/*     // <Link to={ `${ detailpage + this.props.firmData[ 0 ].Id }/${ selectedTyre.productId }` }> */}
                            {/*     //     <SeasonIcon season={ season } /> */}
                            {/*     //     <img */}
                            {/*     //         className="result-img-size" */}
                            {/*     //         src={ imageUrl } */}
                            {/*     //     /> */}
                            {/*     // </Link> */}
                            <div>
                                {/* <SeasonIcon season={ season } /> */}
                                <img
                                    className="result-img-size"
                                    src={imageUrl}
                                />
                            </div>
                            {/* } */}
                        </div>

                        <div className="col-6 col-sm-4 col-md-3 col-xl-3">
                            {/* {this.props.deletable === true ? */}
                            {/*     <Link to={ detailpage + selectedTyre.productId }> */}
                            {/*         <h3>{selectedTyre.brand}</h3> */}
                            {/*         <div> */}
                            {/*             {selectedTyre.tread} */}
                            {/*         </div> */}
                            {/*         {selectedTyre.description && selectedTyre.description.includes( selectedTyre.tread ) ? */}
                            {/*             <div> */}
                            {/*                 {selectedTyre.description.split( selectedTyre.tread )} */}
                            {/*             </div> */}
                            {/*             : */}
                            {/*             <div> */}
                            {/*                 {selectedTyre.description} */}
                            {/*             </div> */}
                            {/*         } */}
                            {/*         <div> */}
                            {/*             {( selectedTyre.productCategoryName && ( selectedTyre.productCategoryName ).startsWith( 'category_pc' ) ) */}
                            {/*                 ? '  PKW  ' */}
                            {/*                 : ( selectedTyre.productCategoryName && ( selectedTyre.productCategoryName ).startsWith( 'category_lt' ) ) */}
                            {/*                     ? '  LKW  ' */}
                            {/*                     : '   SUV  '} */}
                            {/*             {season === 'winter' */}
                            {/*                 ? 'Winter' */}
                            {/*                 : season === 'summer' */}
                            {/*                     ? 'Sommer' */}
                            {/*                     : 'Allwetter'} */}

                            {/*         </div> */}
                            {/*         <div className="mt-2"> */}
                            {/*             EU-REIFENLABEL */}
                            {/*             {selectedTyre ? */}
                            {/*                 <TyreLabel */}
                            {/*                     rollingResistance={ selectedTyre.rollingResistance } */}
                            {/*                     wetGrip={ selectedTyre.wetGrip } */}
                            {/*                     noiseEmission={ selectedTyre.noiseDb } */}
                            {/*                 /> */}
                            {/*                 : '' */}
                            {/*             } */}
                            {/*         </div> */}
                            {/*     </Link> */}
                            {/*     : */}
                            <div>
                                <h3>{selectedRim.brandName}</h3>
                                <div>
                                    Design: {selectedRim.design}
                                </div>
                                {/* {selectedTyre.description && selectedTyre.description.includes( selectedTyre.tread ) ? */}
                                {/*     <div> */}
                                {/*         {selectedTyre.description.split( selectedTyre.tread )} */}
                                {/*     </div> */}
                                {/*     : */}
                                <div>
                                    Farbe: {selectedRim.color}
                                </div>
                                <div>
                                    Größe: {selectedRim.width}x{selectedRim.diameter}"
                                </div>
                                <div>
                                    ET: {selectedRim.et}
                                </div>
                                {/* } */}
                                {/* <div> */}
                                {/*     {( selectedTyre.productCategoryName && ( selectedTyre.productCategoryName ).startsWith( 'category_pc' ) ) */}
                                {/*         ? '  PKW  ' */}
                                {/*         : ( selectedTyre.productCategoryName && ( selectedTyre.productCategoryName ).startsWith( 'category_lt' ) ) */}
                                {/*             ? '  LKW  ' */}
                                {/*             : '   SUV  '} */}
                                {/*     {season === 'winter' */}
                                {/*         ? 'Winter' */}
                                {/*         : season === 'summer' */}
                                {/*             ? 'Sommer' */}
                                {/*             : 'Allwetter'} */}

                                {/* </div> */}
                                {/* <div className="mt-2"> */}
                                {/*     EU-REIFENLABEL */}
                                {/*     {selectedTyre ? */}
                                {/*         <TyreLabel */}
                                {/*             rollingResistance={ selectedTyre.rollingResistance } */}
                                {/*             wetGrip={ selectedTyre.wetGrip } */}
                                {/*             noiseEmission={ selectedTyre.noiseDb } */}
                                {/*         /> */}
                                {/*         : '' */}
                                {/*     } */}
                                {/* </div> */}
                            </div>
                            {/* } */}
                        </div>

                        <div className="col-2 d-none d-md-block col-sm-1 col-md-2 col-xl-2 my-auto text-right">
                            {/* {this.props.deletable === false ? */}
                            {/*     <div className="spacing"> */}
                            {/*         <img */}
                            {/*             src="/reifen-icon.svg" */}
                            {/*             className="tyre-icon" */}
                            {/*         /> {selectedRim.minPrice.toFixed( 2 ) */}
                            {/*         .replace( '.', ',' )} {' '} &euro; */}

                            {/*         <br /> */}
                            {/*         <img */}
                            {/*             src="/montage-icon.svg" */}
                            {/*             className="montage-icon" */}
                            {/*         />{selectedTyre.assemblyType === 'None' || selectedTyre.assemblyType == undefined ? */}
                            {/*         'Keine' */}
                            {/*         : selectedTyre.assemblyType === 'Leichtmetall' ? */}
                            {/*             'Montage auf Leichtmetallfelge' */}
                            {/*             : selectedTyre.assemblyType == 'Stahlfelge' ? */}
                            {/*                 'Montage auf Stahlfelge' : */}
                            {/*                 selectedTyre.assemblyType */}
                            {/*     } */}
                            {/*         {' '}{selectedTyre.assemblyType === 'None' || selectedTyre.assemblyCosts == undefined ? */}
                            {/*         '0,00' */}
                            {/*         : */}
                            {/*         selectedTyre.assemblyCosts.toFixed( 2 ) */}
                            {/*             .replace( '.', ',' )} {' '} &euro; */}
                            {/*     </div> */}
                            {/*     : */}
                            <div>
                                {selectedRim.minPrice.toFixed(2)
                                    .replace('.', ',')} {' '} &euro;
                            </div>
                            {/* } */}
                        </div>

                        {this.props.deletable === true ?
                            <div className="col-2 d-none d-md-block col-sm-2 col-md-2 col-xl-2 my-auto">
                                <div className="custom-dd">
                                    <Dropdown
                                        items={items}
                                        currentselectedRim={selectedRim.id}
                                        defaultValue={selectedRim.amount}
                                        onChangeCallBack={this.shoppingCartRimAmountSelectionHandler}
                                    />
                                </div>
                            </div>
                            :
                            <div className="col-2 d-none d-md-block col-sm-2 col-md-2 col-xl-2 my-auto text-center">
                                {selectedRim.amount}
                            </div>
                        }

                        <div className="col-6 col-sm-4 col-md-2 col-xl-2 text-right">
                            {this.props.deletable === true ?
                                <div>
                                    <i
                                        className="fas fa-times icon-style"
                                        onClick={() => {
                                            this.removeButtonHandlers(this.props.idx);
                                        }}
                                    />
                                    <div className="custom-dd mobile-view">
                                        <Dropdown
                                            items={items}
                                            currentselectedTyre={selectedRim.id}
                                            defaultValue={selectedRim.amount}
                                            onChangeCallBack={this.shoppingCartRimAmountSelectionHandler}
                                        />

                                    </div>
                                    <span className="mobile-view-price">
                                        à {selectedRim.minPrice
                                        .toFixed(2)
                                        .replace('.', ',')}
                                        &euro;
                                    </span>
                                </div>
                                : <span className="mobile-view-price overview-pos"><b
                                    className="mobile-view-fontsize"
                                >{selectedRim.amount} Stück
                                                                                   </b><br/>
                                à {selectedRim.minPrice
                                        .toFixed(2)
                                        .replace('.', ',')}
                                    &euro;
                                    <br/>
                           <img
                               src="/montage-icon.svg"
                               className="montage-icon"
                           />{selectedRim.assemblyType === 'None' || selectedRim.assemblyType == undefined ?
                                        'Keine'
                                        : selectedRim.assemblyType === 'Leichtmetall' ?
                                            'Leichtmetallfelge'
                                            : selectedRim.assemblyType
                                    }
                                    {' '}{selectedRim.assemblyType === 'None' || selectedRim.assemblyCosts == undefined ?
                                        '0,00'
                                        :
                                        selectedRim.assemblyCosts.toFixed(2)
                                            .replace('.', ',')} {' '} &euro;
                                  </span>
                            }
                            <div>
                                <TotalPrice
                                    price={(selectedRim.minPrice * selectedRim.amount)}
                                    ownClass={this.props.deletable === true ? 'total-price-per-item' : 'overview-pos'}
                                />
                                <span>inkl. Mwst</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="separator-sc-tyres"> */}
                {/*     {this.props.deletable === true ? */}
                {/*         <div> */}
                {/*             <h5 className="mt-2 mb-2">Bitte wählen Sie Ihre optionale Montage:</h5> */}
                {/*             <div */}
                {/*                 className={ `assembly-cost-per-piece${ selectedTyre.assemblyType === 'None' || selectedTyre.assemblyType == undefined ? ' active' : '' }` } */}
                {/*                 onClick={ () => { */}
                {/*                     this.handleAssemblyCosts( 'None' ); */}
                {/*                 } } */}
                {/*             > */}
                {/*                 <i className="fa fa-check" /> */}
                {/*                 Ohne Montage 0,00 € / pro Reifen */}
                {/*             </div> */}
                {/*             <div */}
                {/*                 className={ `assembly-cost-per-piece${ selectedTyre.assemblyType === 'Runflat' ? ' active' : '' }` } */}
                {/*                 onClick={ () => { */}
                {/*                     this.handleAssemblyCosts( 'Runflat' ); */}
                {/*                 } } */}
                {/*             > */}
                {/*                 <i className="fa fa-check" /> */}
                {/*                 Runflat {selectedTyre.assemblyCostRft.toFixed( 2 ) */}
                {/*                 .replace( '.', ',' )} &euro; / pro Reifen */}
                {/*             </div> */}
                {/*             <div */}
                {/*                 className={ `assembly-cost-per-piece${ selectedTyre.assemblyType === 'Stahlfelge' ? ' active' : '' }` } */}
                {/*                 onClick={ () => { */}
                {/*                     this.handleAssemblyCosts( 'Stahlfelge' ); */}
                {/*                 } } */}
                {/*             > */}
                {/*                 <i className="fa fa-check" /> */}
                {/*                 Montage auf Stahlfelge {selectedTyre.assemblyCostSteel.toFixed( 2 ) */}
                {/*                 .replace( '.', ',' )} &euro; / pro Reifen */}
                {/*             </div> */}
                {/*             <div */}
                {/*                 className={ `assembly-cost-per-piece${ selectedTyre.assemblyType === 'Leichtmetall' ? ' active' : '' }` } */}
                {/*                 onClick={ () => { */}
                {/*                     this.handleAssemblyCosts( 'Leichtmetall' ); */}
                {/*                 } } */}
                {/*             > */}
                {/*                 <i className="fa fa-check" /> */}
                {/*                 Montage auf Leichtmetallfelge {selectedTyre.assemblyCostAlloy.toFixed( 2 ) */}
                {/*                 .replace( '.', ',' )} &euro; / pro Reifen */}
                {/*             </div> */}
                {/*         </div> */}
                {/*         : */}
                {/*         <div /> */}
                {/*     } */}
                {/* </div> */}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    scRims: state.shoppingcart.shoppingCartRims,
    amountSelectionChanged: state.shoppingcart.amountSelectionChanged,
    // assemblyCosts: state.shoppingcart.assemblyCosts,
    firmData: state.firmData
});

const mapDispatchToProps = dispatch => ({
    onShoppingCartRimChanged: (selectedRim) => dispatch(actionCreators.setShoppingCartRimChanged(selectedRim)),
    onRemoveButtonHandlers: idx => dispatch(actionCreators.removeCartRim(idx)),
    onAmountCount: () => dispatch(actionCreators.countAmount()),
    onUpdateTotalPrice: () => dispatch(actionCreators.updateTotalPrice())
});

export default connect(mapStateToProps, mapDispatchToProps)(RimCartPosition);
