// react
import React from 'react';
import moment from 'moment';
// redux
import { connect } from 'react-redux';
import * as actionCreators from '../../../../../../redux-store/actions';

import ShoppingCart from '../../ShoppingCart';
import AssemblyDate from './AssemblyDate';
import BillingAddress from './BillingAddress';
import CheckoutConfirmation from './CheckoutConfirmation';
import BranchChecker from './BranchChecker';
import CheckoutConfirmed from './CheckoutConfirmed';
import CheckoutFailed from './CheckoutFailed';
import axios from 'axios';

import { postOrder, postOrderSSR } from ' ../../../redux-store/actions/order';
import Breadcrumb from '../../../../shared/breadcrumb/Breadcrumb';
import { Helmet } from 'react-helmet';
import ScriptTag from 'react-script-tag';

const passedvalues = '';

class CheckoutProcess extends React.Component {
    constructor( props ) {
        super( props );
        // initial state
        this.state = {
            tyres: this.props.tyres,
            rims: this.props.rims,
            userdata: {
                dates: [],
                fields: []
            },
            steps: '',
            step: 1,
            passedvalues: ''
        };
        this.myCallback = dataFromChild => {
            this.setState( dataFromChild );
        };

        this.changeStateByBreadcrumb = dataFromChild => {
            this.setState( {
                step: dataFromChild.step,
                steps: dataFromChild.steps
            } );
        };

        this.myLastCallback = dataFromChild => {
            this.setState( dataFromChild );
            this.postOrder( dataFromChild );
        };

        this.siteChecker = props => {
            let buffer;
            if ( this.state.passedvalues !== '' ) {
                switch ( this.state.steps ) {
                    case 'BranchChecker':
                        buffer = (
                            <BranchChecker
                                props={ props }
                                userdata={ this.state }
                                firmData={ this.props.firmData }
                                callBackFromParent={ this.myCallback }
                            />
                        );
                        break;
                    case 'AssemblyDate':
                        buffer = (
                            <AssemblyDate
                                props={ props }
                                userdata={ this.state }
                                callBackFromParent={ this.myCallback }
                            />
                        );
                        break;
                    case 'BillingAddress':
                        buffer = (
                            <BillingAddress
                                props={ props }
                                userdata={ this.state }
                                callBackFromParent={ this.myCallback }
                            />
                        );
                        break;
                    case 'CheckoutConfirmation':
                        buffer = (
                            <CheckoutConfirmation
                                props={ props }
                                userdata={ this.state }
                                callBackFromParent={ this.myLastCallback }
                            />
                        );
                        break;
                    case 'CheckoutConfirmed':
                        buffer = <CheckoutConfirmed props={ props } userdata={ this.state } />;
                        break;

                    case 'CheckoutFailed':
                        buffer = (
                            <CheckoutFailed
                                props={ props }
                                userdata={ this.state }
                                callBackFromParent={ this.myCallback }
                            />
                        );
                        break;
                    default:
                        buffer = false;
                }
            } else {
                buffer = (
                    <BranchChecker
                        props={ props }
                        userdata={ this.state }
                        firmData={ this.props.firmData }
                        callBackFromParent={ this.myCallback }
                    />
                );
            }
            return buffer;
        };
    }

    componentDidMount() {
        if ( this.props.tyres.length === 0 && this.props.rims.length === 0 ) {
            this.props.history.push( '/shoppingcart' );
        }
    }

    // post order
    postOrder( data ) {
        const requestUrl = '/api/postOrder';
        let requestBody = {};
        let order = {};
        const proposedDates = [];
        const chosenDates = [];
        const termine = [];
        const chosenTimes = [];
        let comment = '';
        let name = '';
        let surname = '';
        let email = '';
        let phone = '';
        const mobile = '';
        const firm = '';
        // address Object
        let street = '';
        let housenumber = '';
        let zip = '';
        let city = '';
        let address = {};
        const mandatorStoreNumber = this.props.firmData[ this.state.passedvalues.passedvalues.counter ? this.state.passedvalues.passedvalues.counter : 0 ].Id;
        // end address object
        if ( data ) {
            // start chosen dates
            for ( let i = 0; i <= 2; i++ ) {
                if ( data.userdata.dates.length !== 0 && data.userdata.dates[ i ] ) {
                    chosenDates[ i ] = data.userdata.dates[ i ].chosenDate;
                    const day = chosenDates[ i ].split( '.' )[ 0 ];
                    const month = chosenDates[ i ].split( '.' )[ 1 ];
                    const year = chosenDates[ i ].split( '.' )[ 2 ];
                    proposedDates[ i ] = {
                        date: `${ year }-${ month }-${ day }`,
                        time: data.userdata.dates[ i ].chosenTime
                    };
                }
            }
            order = {
                ...order,
                proposedDates,
                hsntsn: data.userdata.fields.hsntsn ? data.userdata.fields.hsntsn : '',
                licensePlate: data.userdata.fields.licensePlate ? data.userdata.fields.licensePlate : '',
                financing: data.financing ? data.financing : '',
                mandatorStoreNumber
            };
            if ( data.userdata.fields.vendormessage ) {
                const comment = data.userdata.fields.vendormessage;
                order = {
                    ...order,
                    comment
                };
            }
            // end chosen dates
            if ( data.userdata.fields ) {
                // comment
                if ( data.userdata.fields.vendormessage ) {
                    comment = data.userdata.fields.vendormessage;
                }
                // name
                if ( data.userdata.fields.firstname ) {
                    name = data.userdata.fields.firstname;
                    order = {
                        ...order,
                        name
                    };
                }
                // surname
                if ( data.userdata.fields.name ) {
                    surname = data.userdata.fields.name;
                    order = {
                        ...order,
                        surname
                    };
                }
                // email
                if ( data.userdata.fields.email ) {
                    email = data.userdata.fields.email;
                    order = {
                        ...order,
                        email
                    };
                }
                // phone
                if ( data.userdata.fields.phone ) {
                    phone = data.userdata.fields.phone;
                    order = {
                        ...order,
                        phone
                    };
                }

                // address
                if ( data.userdata.fields.adress ) {
                    street = data.userdata.fields.adress;
                    address = {
                        ...address,
                        street
                    };
                    housenumber = street.replace( /\D/g, '' );
                    address = {
                        ...address,
                        housenumber
                    };
                }
                if ( data.userdata.fields.zipcode ) {
                    zip = data.userdata.fields.zipcode;
                    address = {
                        ...address,
                        zip
                    };
                }
                if ( data.userdata.fields.city ) {
                    city = data.userdata.fields.city;
                    address = {
                        ...address,
                        city
                    };
                }
                order = {
                    ...order,
                    address
                };
            }

            // start positions
            const positions = [];

            /* pos.price = parseFloat(price / 1.19).toFixed(2); because backend needs netPrice*/
            if ( data.tyres ) {
                const type = 'PRODUCTID';
                const vat = 0.19;
                data.tyres.forEach( selectedTyre => {
                    const pos = {};
                    const servicepos = {};
                    const position = String( selectedTyre.productId );
                    const quantity = String( selectedTyre.amount );
                    const price = selectedTyre.price;
                    const description = selectedTyre.description;
                    pos.type = 'PRODUCTID';
                    pos.vat = vat;
                    pos.position = position;
                    pos.quantity = quantity;
                    pos.price = parseFloat( price / 1.19 ).toFixed( 2 );
                    pos.description = description;
                    pos.brand = selectedTyre.brand;
                    pos.productCategoryName = selectedTyre.productCategoryName;
                    pos.tread = selectedTyre.tread;
                    servicepos.type = 'SERVICE';
                    servicepos.position = 'MONTAGE';
                    servicepos.quantity = quantity;
                    servicepos.price = selectedTyre.assemblyCosts ? selectedTyre.assemblyCosts : 0;
                    servicepos.assemblyType = selectedTyre.assemblyType;
                    servicepos.vat = vat;
                    positions.push( pos );
                    positions.push( servicepos );
                } );
                order = {
                    ...order,
                    positions,
                    counter: this.state.passedvalues.passedvalues.counter ? this.state.passedvalues.passedvalues.counter : 0,
                    firmdata: this.props.firmData
                };
            }
            if ( data.rims ) {
                const type = 'PRODUCTIDRIM';
                const vat = 0.19;
                data.rims.forEach( selectedRim => {
                    const pos = {};
                    const position = String( selectedRim.id );
                    const quantity = String( selectedRim.amount );
                    const price = selectedRim.minPrice;
                    const description = selectedRim.brandName + selectedRim.design;
                    pos.type = 'PRODUCTIDRIM';
                    pos.vat = vat;
                    pos.position = position;
                    pos.quantity = quantity;
                    pos.price = parseFloat( price / 1.19 ).toFixed( 2 );
                    pos.description = description;
                    pos.brand = selectedRim.brandName;
                    positions.push( pos );
                } );
                order = {
                    ...order,
                    positions,
                    counter: this.state.passedvalues.passedvalues.counter ? this.state.passedvalues.passedvalues.counter : 0,
                    firmdata: this.props.firmData
                };
            }
            // end positions
            order = JSON.stringify( order );
            requestBody = order;
            axios
                .post( requestUrl, requestBody, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                } )
                .then( response => {
                    if ( response.status === 200 ) {
                        console.log( 'order sent successfully' );
                        this.props.onResetSc();
                    } else {
                        const err = new Error( response.statusText );
                        err.code = response.status;
                        this.props.history.push( '/CheckoutFailed' );
                        throw err;
                    }
                } )
                .catch( err => {
                    console.log( 'error', err );
                    this.props.history.push( '/CheckoutFailed' );
                } );
        }
    }

    head() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Shoppingcart</title>
                    <meta
                        name="description"
                        content="This is a proof of concept for TyreDetail SSR"
                    />
                </Helmet>
            </React.Fragment>

        );
    }

    render() {
        return ( <div>
            {this.head()}
            <Breadcrumb step={ this.state.step } callBackFromParent={ this.changeStateByBreadcrumb } />
            {this.siteChecker( this.props )}
                 </div> );
    }
}

const mapStateToProps = state => ( {
        tyres: state.shoppingcart.shoppingCartTyres,
        rims: state.shoppingcart.shoppingCartRims,
        firmData: state.firmData,
        sent: state.order.sent,
        timestamp: state.shoppingcart.timestamp
    });

const mapDispatchToProps = {
    onRemoveButtonHandler: idxx => actionCreators.removeCartTire( idxx ),
    onResetSc: () => actionCreators.resetSc(),
    onResetSent: () => actionCreators.orderSent()
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( CheckoutProcess );
