import React from 'react';
import { connect } from 'react-redux';
import ShoppingCartTyres from '../../components/ShoppingCartTyres';
import TotalPrice from '../../../../shared/priceAmountCaption/TotalPrice';
import { Helmet } from 'react-helmet';
import ShoppingCartRims from '../ShoppingCartRims';

let i;
let arr;
let fields = [];
const finalpricearray = [];

class CheckoutConfirmation extends React.Component {
   constructor( props ) {
      super( props );
      // initial state
      this.state = {
         tyres: this.props.userdata.tyres,
         rims: this.props.userdata.rims,
         userdata: this.props.userdata.userdata,
         passedvalues: this.props.userdata.passedvalues,
         steps: 'CheckoutConfirmed',
         step: 4,
         financing: false
      };
      this.callBack = datafromchild => {
         if ( datafromchild !== this.state.computedTotalPrice ) {
            this.setState( { computedTotalPrice: datafromchild } );
         }
      };
   }

   vendorMessageHandler( field, e ) {
      fields = this.state.userdata.fields;
      fields[ field ] = e.target.value;
      const userdata = this.state.userdata;
      userdata.fields = fields;
      this.setState( { userdata } );
   }

   contactBuilder() {
      if (
         this.props.props.firmData &&
         this.props.props.firmData[ 0 ] &&
         this.props.props.firmData[ 0 ].AllgemeineDaten
      ) {
         i = this.props.props.firmData[ this.props.userdata.passedvalues.passedvalues.counter ].AllgemeineDaten;
         arr = (
            <div className="border-card">
               <h4 className="text-highlight"> Ihr Autohaus und Reifenexperte</h4>
               <div>{i.Name}</div>
               <div>{i.Strasse}</div>
               <div>
                  <span> {i.Plz} </span>
                  <span> {i.Ort}</span>
               </div>
               <div>
                  <b> Telefon: </b>
                  <span> {i.Telefon}</span>
               </div>
               <div>
                  <b> E-Mail: </b>
                  <span>{i.Email}</span>
               </div>
            </div>
         );
         return arr;
      } 
         return <span>Konnte keine Händlerdaten finden</span>;
   }

   dateHandler() {
      const buffer = [];
      for ( let i = 0; i < this.state.userdata.dates.length; i++ ) {
         buffer[ i ] = (
            <div key={ i }>
               Terminvorschlag {`${ i + 1 }:  `}
               <span className="text-highlight"> {this.state.userdata.dates[ i ].chosenDate} </span>
               <span className="text-highlight"> {this.state.userdata.dates[ i ].chosenTime} </span>
            </div>
         );
      }
      return buffer;
   }

   mapUserData() {
      return (
         <div className="border-card">
            <h4 className="text-highlight"> Ihre Kontaktdaten </h4>
            <div>
               <span> {this.state.userdata.fields.firstname} </span>
               <span>{this.state.userdata.fields.name}</span>
            </div>
            <div>
               {this.state.userdata.fields.adress}
            </div>
            <div>
               {this.state.userdata.fields.zipcode} {this.state.userdata.fields.city}
            </div>
            <div>
               <b>Telefon: </b>
               <span>
                    {this.state.userdata.fields.phone}
               </span>
            </div>
            <div>
               <b>E-Mail: </b>
               <span>
                    {this.state.userdata.fields.email}
               </span>
            </div>
            <br />
            {this.state.userdata.fields.hsntsn ?
               <div>
                  <b>HSN TSN: </b>
                  <span>
                    {this.state.userdata.fields.hsntsn}
                  </span>
               </div>
               :
               ''
            }
            {this.state.userdata.fields.licensePlate ?
               <div>
                  <b>Kennzeichen: </b>
                  <span>
                    {this.state.userdata.fields.licensePlate}
                  </span>
               </div>
               :
               ''
            }
            {this.state.userdata.fields.chassisNumber ?
               <div>
                  <b>Fahrgestellnummer: </b>
                  <span>
                    {this.state.userdata.fields.chassisNumber}
                  </span>
               </div>
               :
               ''
            }
         </div>
      );
   }

   render() {
      const computedTotalPrice = this.props.totalPrice.toFixed( 2 )
      .replace( '.', ',' );
      const firmData = this.props.firmData[ 0 ].AllgemeineDaten;
      return (
         <div className="container">
            <Helmet>
               <script>
                  {`var santander_ratenrechner_haendlerangabe = '${ firmData.Name }, ${ firmData.Strasse }, ${ firmData.Plz } ${ firmData.Ort }';`}
               </script>
               <script src="https://www.scb-ratenrechner.de/app/ratenrechner-widget.js" />
            </Helmet>
            <div className="checkout-confirmation-wrapper">
               <div className="row">
                  <div className="col-12">
                     <h1 className="mb-3">Anfrageübersicht</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 col-sm-4 col-md-4 col-xl-4">
                     {this.mapUserData()}
                  </div>
                  <div className="col-12 col-sm-4 col-md-4 col-xl-4 space-between-cards">
                     {this.contactBuilder()}
                  </div>
                  <div className="col-12 col-sm-4 col-md-4 col-xl-4 space-between-cards">
                     <div className="border-card assembly-date">
                        <h4 className="text-highlight"> Ihr Wunschtermin </h4>
                        {this.dateHandler()}
                     </div>
                  </div>
               </div>
               <h4 className="text-highlight title-article-overview"> Ihre Artikel </h4>
               <div className="row separator">
                  <div className="d-none d-sm-block col-md-3 col-xl-3" />
                  <div className="d-none d-md-block col-8 col-sm-4 col-md-3 col-xl-3">
                     <h4>Artikel</h4>
                  </div>
                  <div className="col-2 d-none d-md-block col-sm-1 col-md-2 col-xl-2">
                     <h4>Stückpreis</h4>
                  </div>
                  <div className="col-2 d-none d-md-block col-sm-2 col-md-2 col-xl-2 text-center">
                     <h4>Anzahl</h4>
                  </div>
                  <div className="col-4 d-none d-md-block col-sm-4 col-md-2 col-xl-2 text-right">
                     <h4>Summe</h4>
                  </div>
               </div>
               <ShoppingCartTyres
                   shoppingCartTyres={ this.props.sctyres }
                   deletable={ false }
                   key={ new Date() }
                   visualizationType="orderOverview"
               />
               <ShoppingCartRims
                   shoppingCartRims={ this.props.scRims }
                   deletable={ false }
                   key={ Math.random() }
                   visualizationType="orderOverview"
               />
               <div className="row spacing-top">
                  <div className="col-8 col-sm-7 col-md-7 col-xl-7">
                     <h4 className="total-price text-highlight"> Ihr Gesamtpreis (inkl. Mwst.)</h4>
                  </div>
                  <div className="col-4 col-sm-5 col-md-5 col-xl-5">
                     <TotalPrice />
                  </div>
               </div>
               <div className="text-right mt-2">
                  <input
                      type="checkbox"
                      className="mr-2"
                      onClick={ () => {
                            this.setState( { financing: !this.state.financing } );
                         } }
                  />
                  Mit Finanzierung anfragen
               </div>
               <div className="order-button">
                  <div
                      data-init="santander-ratenrechner"
                      data-maxamount="2500"
                      data-months="6,12,18,24,36"
                      data-amount={ this.props.totalPrice <= 2500 ? this.props.totalPrice : 2500 }
                      data-interest="7.9"
                  />
                  <button
                      className="btn btn-primary"
                      onClick={ () => this.props.callBackFromParent( this.state ) }
                  >
                     Jetzt verbindlich Anfragen
                  </button>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => ( {
      totalPrice: state.shoppingcart.totalPrice,
      firmData: state.firmData,
      sctyres: state.shoppingcart.shoppingCartTyres,
      scRims: state.shoppingcart.shoppingCartRims
   } );

const mapDispatchToProps = dispatch => ( {} );

export default connect(
   mapStateToProps,
   mapDispatchToProps
)( CheckoutConfirmation );
