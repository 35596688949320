import React from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../../redux-store/actions/index';
import ShoppingCartFilled from '../components/ShoppingCartFilled';
import ShoppingCartEmpty from '../components/ShoppingCartEmpty';
import { Link } from 'react-router-dom';

class ShoppingCartWrapper extends React.Component {
   constructor( props ) {
      super( props );
      this.state = {
         tyres: this.props.tyres,
         rims: this.props.rims
      };
   }

   shoppingCartChecker( props ) {
      if ( props.tyres.length > 0 || props.rims.length > 0 ) {
         return <ShoppingCartFilled />;
      }
      return ShoppingCartEmpty();
   }

   render() {
      return (
         <div>
            {this.shoppingCartChecker( this.props )}
            <div className="text-right">
               {this.props.tyres.length > 0 || this.props.rims.length > 0 ? (
                  <Link to="/checkoutprocess">
                     <button className="btn btn-primary my-3">
                        Zur Anfrage
                     </button>
                  </Link>
               ) : (
                  ''
               )}
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => ( {
   tyres: state.shoppingcart.shoppingCartTyres,
   rims: state.shoppingcart.shoppingCartRims
} );

const mapDispatchToProps = dispatch => ( {
   onRemoveButtonHandler: idxx => dispatch( actionCreators.removeCartTire( idxx ) ),
   onRemoveButtonHandlers: idxx => dispatch( actionCreators.removeCartRim( idxx ) )

} );

export default connect( mapStateToProps, mapDispatchToProps )( ShoppingCartWrapper );
