import React from 'react';
import {Link} from "react-router-dom";

class CheckoutFailed extends React.Component {
   constructor(props) {
      super(props);
      // initial state
      this.state = {
         steps: 'CheckoutFailed',
         step: 4
      };
   }

   render() {
      return (
         <div className={"text-center mt-4"}>
            <h2>Etwas ist schief gelaufen. Bitte wiederholen Sie den Vorgang.</h2>
            <br/>
            <Link className="btn btn-primary" to={'/shoppingcart'}>
               Erneut zum Warenkorb
            </Link>
         </div>
      );
   }
}

export default CheckoutFailed;
