import * as actionTypes from '../actions/actionTypes';

const initialState = [];

const reducer = ( state, action ) => {
    let _favRims = state || initialState;
    switch ( action.type ) {
        case actionTypes.SAVE_FAVRIMS:
            _favRims = action.favRims;
        return _favRims;
    }
    return _favRims;
};

export default reducer;
