import React from 'react';
import {GoogleApiWrapper, InfoWindow, Map, Marker} from 'google-maps-react';

let buffer;
const points = [];
let infoWindows;
let markers;

class MapContainer extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: null,
         points: null,
         counter: null,
         showInfoWindows: false,
         isOpen: true,
         markerPriority: true
      };
      this.handleToggleOpen = (e) => {
         this.props.callBackFromParent(e);
         this.setState({
            isOpen: true,
            counter: e,
            markerPriority: true
         });
      };

      this.handleToggleClose = (e) => {
         this.setState({
            isOpen: false
         });
      };
   }

   onTilesLoadedHandler() {
      this.setState({
         showInfoWindows: true,
         markerPriority: false
      });
   }

   initializeMap() {
      if (this.props) {

         if (this.state.counter != this.props.data.counter && this.state.markerPriority === false) {
            this.setState({counter: this.props.data.counter});
         } else if (!(this.state.showInfoWindows)) {
            this.setState({markerPriority: false});
         } else {
            this.state.markerPriority = false;
         }
      }

      if (this.state.points == null) {
         for (let i = 0; i < Object.keys(this.props.firmData).length; i++) {
            points.push(this.props.firmData[i].AllgemeineDaten);
            if (i === Object.keys(this.props.firmData).length - 1) {
               this.setState({
                  points,
                  data: this.props.data
               });
            }
         }
      }
   }

   createMap() {
      this.initializeMap();
      const style = {
         filter: 'grayscale(80%)'
      };
      if (this.state.points) {
         markers = this.state.points.map((AllgemeineDaten, i) => {
            const index = i + 1;
            return (
               <Marker
                  key={i}
                  position={{
                     lat: parseFloat(AllgemeineDaten.GoogleLat),
                     lng: parseFloat(AllgemeineDaten.GoogleLng)
                  }}
                  name={`Händler${index}`}
                  onClick={() => this.handleToggleOpen(i)}
               />
            );
         });
         if (this.state.showInfoWindows) {
            let i;
            let data = {
               address: [],
               counter: 0
            };
            infoWindows = this.state.points.map((AllgemeineDaten, ii) => (
               <InfoWindow key={ii}
                           style={{top: '-30px'}}
                           position={{
                              lat: parseFloat(AllgemeineDaten.GoogleLat),
                              lng: parseFloat(AllgemeineDaten.GoogleLng)
                           }}
                           visible={this.state.isOpen && this.state.counter == ii}>
                  <h2 align="center">
                     {AllgemeineDaten.Name}
                     <br/>
                     {/*{this.props !== undefined &&*/}
                     {/*this.props.firmData[ ii ]  &&*/}
                     {/*this.props.firmData[ ii ].Betriebstyp.Bezeichnung}*/}
                  </h2>

                  <span className="contact-info-text-bold"> Adresse</span><br/>
                  <span className="contact-info-text"> {AllgemeineDaten.Strasse} </span> <br/>
                  <span className="contact-info-text"> {AllgemeineDaten.Plz} {AllgemeineDaten.Ort} </span> <br/>
                  {(() => {
                     if (AllgemeineDaten.Land === 'DE') {
                        return <span className="contact-info-text"> Deutschland </span>;
                     }
                  })()}
                  <br/>
                  <span className="contact-info-text-bold"> Telefon: </span>
                  <span className="contact-info-text"> {AllgemeineDaten.Telefon} </span>
                  <br/>
                  <span className="contact-info-text-bold"> E-Mail: </span>
                  <span className="contact-info-text"> {AllgemeineDaten.Email} </span>
               </InfoWindow>
            ));
         }
      }
      return (
         <Map google={this.props.google}
              initialCenter={{
                 lat: this.props.firmData[0].AllgemeineDaten.GoogleLat,
                 lng: this.props.firmData[0].AllgemeineDaten.GoogleLng
              }}
              style={style}
              zoom={10}
              onTilesLoaded={!(this.state.showInfoWindows) && this.onTilesLoadedHandler()}>
            {markers}
            {infoWindows}
         </Map>
      );
   }

   render() {
      return this.createMap();
   }
}

export default GoogleApiWrapper({
   apiKey: 'AIzaSyChzbUX5p0dw1nceK0qFg5XJgMw4BIJwSA'
})(MapContainer);
