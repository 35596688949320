import React from 'react';
import Contact from '../../../Contact/Contact';

import { connect } from 'react-redux';
import * as actionCreators from '../../../../../../redux-store/actions/index';

class BranchChecker extends React.Component {
   constructor( props ) {
      super( props );
      // initial state
      this.state = {
         tyres: this.props.userdata.tyres,
         rims: this.props.userdata.rims,
         userdata: this.props.userdata.userdata,
         passedvalues: {
            address: [],
            counter: 0
         },
         steps: 'AssemblyDate',
         step: 2
      };
      this.newCallback = ( dataFromChild ) => {
         this.setState( { passedvalues: dataFromChild } );
      };
      this.navigationCallBack = ( data ) => {
         this.props.callBackFromParent( this.state );
      };
   }

   componentDidMount() {
      this.props.onResetTermsOfUse();
      this.props.onResetDataProtection();
   }

   render() {
      return (
         <Contact
             callBackFromParent={ this.newCallback }
             userdata={ this.props.userdata }
             navigationCallBack={ this.navigationCallBack }
         />
      );
   }
}

const mapStateToProps = state => ( {} );

const mapDispatchToProps = dispatch => ( {
      onResetTermsOfUse: () => dispatch( actionCreators.resetTermsOfUse() ),
      onResetDataProtection: () => dispatch( actionCreators.resetDataProtection() )
   } );

export default connect( mapStateToProps, mapDispatchToProps )( BranchChecker );
