import React from 'react';

class LoadingTyreResult extends React.Component {
   render() {
      return (
         <div className={"loading-tyre-result"}>
            <div className="row">
               <div className="col-6 col-sm-4 col-md-3 col-xl-3">
                  <div className={"tyre-image"}/>
               </div>
               <div className="col-6 col-sm-4 col-md-4 col-xl-4">
                  <div className={"title"}/>
                  <div className={"description"}/>
                  <div className={"description"}/>
                  <div className={"description"}/>
                  <div className={"description"}/>
                  <div className={"description"}/>
               </div>
               <div className="col-12 col-sm-4 col-md-5 col-xl-5 text-right">
                  <div className="row">
                     <div className="col-6 col-sm-12 col-md-12 col-xl-12">
                        <div className={"price"}/>
                     </div>
                     <div className="col-6 col-sm-12 col-md-12 col-xl-12s">
                        <div className={"button"}/>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default LoadingTyreResult;
