import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import routes from '../../routes';
import Header from './Header';
import Footer from './Footer';
import Cookies from './components/cookies';
import {connect} from 'react-redux';

class Layout extends React.Component {
   constructor() {
      super();
      this.state = {
         loc: ''
      };
   }

   componentDidMount() {
      if (process.env.NODE_ENV === 'production') {
         console.clear();
      }
   }

   render() {
      return (
         <div>
            {this.props.firmData ?
               <Cookies/>
               :
               <div/>
            }
            <Route render={({location}) => {
               this.state.loc = location.pathname;
               const {pathname} = location;
               return (
                  <TransitionGroup>
                     <CSSTransition
                        key={pathname}
                        classNames="page"
                        timeout={{
                           enter: 1000,
                           exit: 0
                        }}>
                        <div>
                           <Header loc={pathname}/>
                           {this.props.firmData ?
                              <div className="body-wrapper">
                                 <Switch>
                                    {routes.map(route => (
                                       <Route key={route.path} {...route} />
                                    ))}
                                 </Switch>
                              </div>
                              :
                              <div className={'container text-center errorimg'}>
                                 <img src="/images/errorpage.png"
                                      className="img-fluid"
                                 />
                                 <h1> Leider ist der ausgewählte Händler zurzeit nicht verfügbar...</h1>
                              </div>
                           }
                           <Footer/>
                        </div>
                     </CSSTransition>
                  </TransitionGroup>
               );
            }}
            />
         </div>
      );
   }
}

const mapStateToProps = state => ({
   firmData: state.firmData
});
export default connect(mapStateToProps)(Layout);
