import React from 'react';

class SeasonIcon extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         season: ''
      };
   }

   render() {
      return (
         <div className={'season-icon-wrapper'}>
            {this.props.season === 'winter'
               ?
               <span className={'icon icon-Schnee'}/>
               : this.props.season === 'summer'
                  ? <span className={'icon icon-sonne'}/>
                  : <span className={'icon icon-allwetter'}/>
            }
         </div>
      );
   }
}

export default SeasonIcon;
