import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import * as actionCreators from './../../../../redux-store/actions';
import Filter from '../../pages/SearchResult/components/Filter';
import Dropdown from '../dropDown/DropDown';
import ReactTooltip from 'react-tooltip';
import {getRatio, getDiameter} from '../../../../redux-store/api';

let path = '/searchresult/';
let url = '';

class Tyresearch extends React.Component {
   constructor(props) {
      super(props);
      let initialValues = {
         length: this.props.length,
         height: this.props.height,
         diameter: this.props.diameter,
         season: this.props.season,
         speed: this.props.speed,
         load: this.props.load,
         vehicleType: this.props.vehicleType,
         loading: true
      };
      this.state = {
         radioClass: 'radiobutton',
         values: initialValues,
         searchString: '',
         filterSelections: this.props.filterSelections,
         computedValues: null,
         searchHider: 'search-hider hide',
         filterHider: 'filter-hider hide',
         tyreSizes: this.props.tyreSizes,
         tyreSizesReady: false
      };
      this.vehicleTypeHandler = this.vehicleTypeHandler.bind(this);
      this.seasonHandler = this.seasonHandler.bind(this);
      this.filterHandler = () => {
         if (this.state.filterHider == 'filter-hider show') {
            this.setState({filterHider: 'filter-hider hide'});
         } else {
            this.setState({filterHider: 'filter-hider show'});
         }
      };
      this.mobileHandler = () => {
         if (this.state.searchHider == 'search-hider show') {
            this.setState({searchHider: 'search-hider hide'});
         } else {
            this.setState({searchHider: 'search-hider show'});
         }
      };
      this.setSearchFlag = () => {
         this.props.onSetSearchFlag();
      };
      this.changeHandler = (event, id) => {
         this.props.onResetFilterSelections();
         if (
            event !== 'Bitte wählen' &&
            event !== 'Breite' &&
            event !== 'Höhe' &&
            event !== 'Zoll'
         ) {
            let newvalues = this.state.values;
            newvalues[id] = event.toString();
            if (id == 'length') {
               this.props.onLengthChanged(event.toString());
               this.props.onHeightChanged(newvalues.height);
               this.props.onDiameterChanged(newvalues.diameter);
               this.fetchRatio(event.toString(), newvalues.vehicleType);
            } else if (id == 'height') {
               this.props.onHeightChanged(event.toString());
               this.props.onLengthChanged(newvalues.length);
               this.props.onDiameterChanged(newvalues.diameter);
               this.fetchDiameter(this.state.values.length, event.toString(), newvalues.vehicleType);
            } else if (id == 'diameter') {
               this.props.onLengthChanged(newvalues.length);
               this.props.onHeightChanged(newvalues.height);
               this.props.onDiameterChanged(event.toString());
            }
            if (id === 'speed') {
               let _speed = event;
               if (_speed === 'Alles') {
                  _speed = '';
               }
               this.props.onSpeedChanged(_speed);
            } else if (id === 'load') {
               let _load = event;
               if (_load === 'Alles') {
                  _load = '';
               }
               this.props.onLoadChanged(_load);
            }
            this.setState({values: newvalues})
         } else {
            return false;
         }
      };
   }

   componentDidMount() {
      this.createSearchString();
      if (this.props.length && this.props.height && this.props.diameter) {
         this.fetchRatio(this.props.length);
         this.fetchDiameter(this.props.length, this.props.height);
      }

   }

   seasonHandler(event) {
      this.props.onResetSetSearchFlag();
      this.props.onResetFilterSelections();
      let newvalues = this.state.values;
      newvalues.season = event.currentTarget.name;
      this.setState({values: newvalues});
      this.props.onSeasonChanged(event.currentTarget.name);
   };

   vehicleTypeHandler(event) {
      this.props.onResetSetSearchFlag();
      this.props.onResetFilterSelections();
      let newvalues = this.state.values;
      newvalues.vehicleType = event.currentTarget.name;
      this.setState({values: newvalues});
      this.props.onVehicleTypeChanged(event.currentTarget.name);
   };


   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.length !== this.props.length) {
         this.setState({tyreSizesReady: false, loading: true})
      }
      if (this.state.tyreSizesReady && this.state.tyreSizes.heights && this.state.tyreSizes.heights[0] && !(this.state.tyreSizes.heights.includes(this.props.height))) {
         this.changeHandler(this.state.tyreSizes.heights[0], "height")
      } else if (this.state.tyreSizesReady && this.state.tyreSizes.diameters && this.state.tyreSizes.diameters[0]
         && !(this.state.tyreSizes.diameters.includes(this.props.diameter))) {
         this.changeHandler(this.state.tyreSizes.diameters[0], "diameter")
      }
      if (this.state.tyreSizesReady && this.state.tyreSizes.diameters && this.state.tyreSizes.diameters[0]
         && this.state.tyreSizes.heights && this.state.tyreSizes.heights[0] && this.state.loading
         && (this.state.tyreSizes.diameters.includes(this.props.diameter)) && (this.state.tyreSizes.heights.includes(this.props.height))) {
         setTimeout(() => {
            this.setState({loading: false})
         }, 1000);

      }
   }

   scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
   }

   iterateOverArray(value) {
      if (this.state.tyreSizes && this.props.filters && this.props.filters.TYRE_SPEED_INDEX && this.props.filters.TYRE_LOAD_INDEX) {
         if (this.state.tyreSizes !== '{}') {
            switch (value) {
               case 'speed':
                  return (
                     <Dropdown
                        items={this.props.filters.TYRE_SPEED_INDEX ? this.props.filters.TYRE_SPEED_INDEX : ""}
                        optionalValue="Alles"
                        id="speed"
                        defaultValue={this.props.speed}
                        onChangeCallBack={this.changeHandler}
                        isLoading={this.state.isLoading && this.state.loading}
                     />
                  );
                  break;
               case 'load':
                  return (
                     <Dropdown
                        items={this.props.filters.TYRE_LOAD_INDEX ? this.props.filters.TYRE_LOAD_INDEX : ""}
                        optionalValue="Alles"
                        id="load"
                        defaultValue={this.props.load}
                        onChangeCallBack={this.changeHandler}
                        isLoading={this.state.isLoading && this.state.loading}
                     />
                  );
                  break;
            }
         }
      }
   }

   createSelectOf(value) {
      if (this.state.tyreSizes) {
         if (this.state.tyreSizes !== '{}') {
            switch (value) {
               case 'length':
                  if (this.state.tyreSizes.widths)
                     return (
                        <Dropdown
                           items={this.state.tyreSizes.widths}
                           id="length"
                           defaultValue={this.state.values.length}
                           onChangeCallBack={this.changeHandler}
                           isLoading={this.state.isLoading && this.state.loading}
                        />
                     );
               case 'height':
                  if (this.state.tyreSizes.heights)
                     return (
                        <Dropdown
                           defaultValueOnly={true}
                           items={this.state.tyreSizes.heights}
                           id="height"
                           defaultValue={this.state.values.height}
                           onChangeCallBack={this.changeHandler}
                           isLoading={this.state.isLoading && this.state.loading}
                        />
                     );
                  break;
               case 'diameter':
                  if (this.state.tyreSizes.diameters)
                     return (
                        <Dropdown
                           defaultValueOnly={true}
                           items={this.state.tyreSizes.diameters}
                           id="diameter"
                           defaultValue={this.state.values.diameter}
                           onChangeCallBack={this.changeHandler}
                           isLoading={this.state.isLoading && this.state.loading}
                        />
                     );
                  break;
            }
         }
      }
   }

   createSearchString() {
      let compValues = null;
      let filterValues = this.props.filterSelections;
      let mandatorStoreNo;
      if (this.props.firmData && this.props.firmData[0] && this.props.firmData[0].Id)
         mandatorStoreNo = this.props.firmData[0].Id;
      compValues = {
         ...this.state.values,
         filterValues,
         mandatorStoreNo
      };
      if (JSON.stringify(compValues) !== JSON.stringify(this.state.computedValues))
         this.setState({computedValues: compValues});
      let newbuffer = Buffer.from(
         JSON.stringify(compValues)
      )
      .toString('base64');
      let newUrlValue = path + newbuffer;
      this.props.onComputedUrlValue(newUrlValue);
      let newsearchString;
      if (newbuffer !== this.state.searchString) {
         newsearchString = newbuffer;
         url = path + this.state.searchString;
         // url im store speichern
         this.props.onComputedUrlValue(newUrlValue);

         this.setState({searchString: newsearchString});
      } else {
         url = path + this.state.searchString;
         this.props.onComputedUrlValue(newUrlValue);
      }
   }

   fetchRatio(width, vehicleType) {
      setTimeout(
         function () {
            try {
               getRatio(width, vehicleType)
               .then(
                  (res) => {
                     let tyreSizes = this.state.tyreSizes;
                     tyreSizes.heights = res;
                     this.setState({tyreSizes, tyreSizesReady: true})
                  }
               )
            } catch (error) {
               console.log(error);
            }
         }
         .bind(this),
         500
      );
   }

   fetchDiameter(width, ratio, vehicleType) {
      setTimeout(
         function () {
            try {
               getDiameter(width, ratio, vehicleType)
               .then(
                  (res) => {
                     let tyreSizes = this.state.tyreSizes;
                     tyreSizes.diameters = res;
                     this.setState({tyreSizes, tyreSizesReady: true})
                  }
               )
            } catch (error) {
               console.log(error);
            }
         }
         .bind(this),
         500
      );
   }

   render() {
      this.createSearchString();
      return (
         <div className="tyre-search-wrapper">
            {this.props.tyreSearch === 'home' ? (
               <div className="tyresearch-border">
                  <div
                     className={this.state.loading || this.props.isLoading ? 'd-block tyresearch-loading' : 'd-none'}>
                     <div className={'loading-circle'}/>
                  </div>
                  <h2>Reifensuche </h2>
                  <div className="row mb-4">
                     <div className="col d-none d-md-block">
                        <img
                           className="tyre-search-image img-fluid"
                           src="/reifen.png"
                           hspace="24"
                        />
                     </div>
                  </div>
                  <div className="row mb-4">
                     <div className="col-12 col-md-6 col-xl-6">
                        <div className={"mb-2 font-weight-bold"}>Saison</div>
                        <div className="radio-button-wrapper">
                           <button type="radio"
                                   className={'radio-button ' + (this.props.season === 'summer' ? 'active' : '')}
                                   onClick={this.seasonHandler}
                                   name="summer"
                                   data-tip data-for={'summer'}>
                              <span className={'icon-sonne icon-style'}/>
                              <ReactTooltip id='summer'>
                                 <span>
                                    Sommer
                                 </span>
                              </ReactTooltip>
                           </button>
                           <button type="radio"
                                   className={'radio-button ' + (this.props.season === 'winter' ? 'active' : '')}
                                   onClick={this.seasonHandler}
                                   name="winter"
                                   data-tip data-for={'winter'}>
                              <span className={'icon-Schnee icon-style'}/>
                              <ReactTooltip id='winter'>
                                 <span>
                                    Winter
                                 </span>
                              </ReactTooltip>
                           </button>
                           <button type="radio"
                                   className={'radio-button ' + (this.props.season === 'all' ? 'active' : '')}
                                   onClick={this.seasonHandler}
                                   name="all"
                                   data-tip data-for={'all'}>
                              <span className={'icon-allwetter icon-style'}/>
                              <ReactTooltip id='all'>
                                 <span>
                                    Allwetter
                                 </span>
                              </ReactTooltip>
                           </button>
                        </div>
                     </div>
                     <div className="col-12 col-md-6 col-xl-6">
                        <div className={"mb-2 font-weight-bold"}>Fahrzeugart</div>
                        <div className="home radio-button-wrapper">
                           <button type="radio"
                                   className={'radio-button ' + (this.props.vehicleType === 'pkw' ? 'active' : '')}
                                   onClick={this.vehicleTypeHandler}
                                   name="pkw">
                              PKW
                           </button>
                           <button type="radio"
                                   className={'radio-button ' + (this.props.vehicleType === 'lkw' ? 'active' : '')}
                                   onClick={this.vehicleTypeHandler}
                                   name="lkw">
                              LKW
                           </button>
                           <button type="radio"
                                   className={'radio-button ' + (this.props.vehicleType === 'suv' ? 'active' : '')}
                                   onClick={this.vehicleTypeHandler}
                                   name="suv">
                              SUV/4x4
                           </button>
                        </div>
                     </div>
                  </div>

                  <div className="row mb-4">
                     <div className="col-12">
                        <div className={"mb-2 font-weight-bold"}>Reifengröße</div>
                        <div className="row">
                           <div className="col-4">
                              {this.createSelectOf('length')}
                           </div>
                           <div className="col-4">
                              {this.createSelectOf('height')}
                           </div>
                           <div className="col-4">
                              {this.createSelectOf('diameter')}
                           </div>
                        </div>
                     </div>
                  </div>

                  <Link to={url}>
                     <button className="btn btn-primary w-100">Suchen</button>
                  </Link>
               </div>
            ) : (
               <React.Fragment>
                  <div className="sidebar">
                     <div
                        className={this.state.loading || this.props.isLoading ? 'd-block tyresearch-loading' : 'd-none'}>
                        <div className={'loading-circle'}/>
                     </div>
                     <div className="row mb-3">
                        <div className="col">
                           <label>Saison</label>
                           <div className="radio-button-wrapper">
                              <button type="radio"
                                      className={'radio-button ' + (this.props.season === 'summer' ? 'active' : '')}
                                      onClick={this.state.isLoading && this.state.loading ? this.doNothing : this.seasonHandler}
                                      name="summer"
                                      data-tip data-for={'summer'}>
                                 <span className={'icon-sonne icon-style'}/>
                                 <ReactTooltip id='summer'>
                                             <span>
                                                Sommer
                                             </span>
                                 </ReactTooltip>
                              </button>
                              <button type="radio"
                                      className={'radio-button ' + (this.props.season === 'winter' ? 'active' : '')}
                                      onClick={this.state.isLoading && this.state.loading ? this.doNothing : this.seasonHandler}
                                      name="winter"
                                      data-tip data-for={'winter'}>
                                 <span className={'icon-Schnee icon-style'}/>
                                 <ReactTooltip id='winter'>
                                             <span>
                                                Winter
                                             </span>
                                 </ReactTooltip>
                              </button>
                              <button type="radio"
                                      className={'radio-button ' + (this.props.season === 'all' ? 'active' : '')}
                                      onClick={this.state.isLoading && this.state.loading ? this.doNothing : this.seasonHandler}
                                      name="all"
                                      data-tip data-for={'all'}>
                                 <span className={'icon-allwetter icon-style'}/>
                                 <ReactTooltip id='all'>
                                             <span>
                                                Allwetter
                                             </span>
                                 </ReactTooltip>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div className="row mb-3">
                        <div className="col">
                           <label>Fahrzeugart</label>
                           <div className="radio-button-wrapper">
                              <button type="radio"
                                      className={'radio-button ' + (this.props.vehicleType === 'pkw' ? 'active' : '')}
                                      onClick={this.state.isLoading && this.state.loading ? this.doNothing : this.vehicleTypeHandler}
                                      name="pkw">
                                 PKW
                              </button>
                              <button type="radio"
                                      className={'radio-button ' + (this.props.vehicleType === 'lkw' ? 'active' : '')}
                                      onClick={this.state.isLoading && this.state.loading ? this.doNothing : this.vehicleTypeHandler}
                                      name="lkw">
                                 LKW
                              </button>
                              <button type="radio"
                                      className={'radio-button ' + (this.props.vehicleType === 'suv' ? 'active' : '')}
                                      onClick={this.state.isLoading && this.state.loading ? this.doNothing : this.vehicleTypeHandler}
                                      name="suv">
                                 SUV/4x4
                              </button>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col">
                           <label>Reifengröße</label>
                           <div className="select-buttons-tire-size-result">
                              <div className="custom-dd tire-size-dd">
                                 {this.createSelectOf('length')}
                              </div>
                              <div className="custom-dd tire-size-dd">
                                 {this.createSelectOf('height')}
                              </div>
                              <div className="custom-dd tire-size-dd">
                                 {this.createSelectOf('diameter')}
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col d-xl-none">
                           <button className="btn btn-outline-primary w-100" onClick={() => {
                              this.mobileHandler();
                           }}>
                              Suche anpassen...
                           </button>
                        </div>
                        <div className="col d-xl-none">
                           <button className="btn btn-outline-primary w-100" onClick={() => {
                              this.filterHandler();
                           }}>
                              Suche filtern...
                           </button>
                        </div>
                     </div>
                     <div className={this.state.searchHider}>
                        <div className="row">
                           <div className="col-12 col-xl-12 col-sm-6 col-xs-6">
                              <label>Last</label>
                              <div className={'select-buttons-tire-size-result'}>
                                 <div className="custom-dd load-speed">
                                    {this.iterateOverArray('load')}
                                 </div>
                              </div>
                           </div>
                           <div className="col-12 col-xl-12 col-sm-6 col-xs-6">
                              <label>Geschwindigkeit</label>
                              <div className={'select-buttons-tire-size-result'}>
                                 <div className="custom-dd load-speed">
                                    {this.iterateOverArray('speed')}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col">
                              <Link to={url}>
                                 <button onClick={this.setSearchFlag()}
                                         className="btn btn-primary w-100"
                                         disabled={this.state.isLoading && this.state.loading}>
                                    Übernehmen
                                 </button>
                              </Link>
                           </div>
                        </div>
                     </div>
                     <div className={this.state.filterHider}>
                        <Filter values={this.state.values}/>
                     </div>
                  </div>
               </React.Fragment>
            )}
         </div>
      );
   }
}

const mapStateToProps = state => ({
   tyreSizes: state.sizes,
   filterSelections: state.routeChanged.filterSelections,
   filters: state.tyres.filters,
   url: state.routeChanged.url,
   isLoading: state.tyres.isLoading,
   changeFlag: state.routeChanged.changeFlag,
   season: state.routeChanged.season,
   vehicleType: state.routeChanged.vehicleType,
   speed: state.routeChanged.speed,
   load: state.routeChanged.load,
   length: state.routeChanged.length,
   height: state.routeChanged.height,
   diameter: state.routeChanged.diameter,
   firmData: state.firmData
});

const mapDispatchToProps = dispatch => {
   return {
      onChangeRouteFrom: from => dispatch(actionCreators.changeRouteFrom(from)),
      onChangeRouteTo: to => dispatch(actionCreators.changeRouteTo(to)),
      onComputedUrlValue: val => dispatch(actionCreators.newComputedUrlValue(val)),
      onResetFilterSelections: () => dispatch(actionCreators.resetFilterSelections()),
      onResetSetSearchFlag: () => dispatch(actionCreators.resetSearchFlag()),
      onSetSearchFlag: () => dispatch(actionCreators.changeFlag()),
      onSetPage: page => actionCreators.setPage(page),
      onSeasonChanged: season => dispatch(actionCreators.seasonChanged(season)),
      onVehicleTypeChanged: vehicleType => dispatch(actionCreators.vehicleTypeChanged(vehicleType)),
      onSpeedChanged: speed => dispatch(actionCreators.speedChanged(speed)),
      onLoadChanged: load => dispatch(actionCreators.loadChanged(load)),
      onLengthChanged: length => dispatch(actionCreators.lengthChanged(length)),
      onHeightChanged: height => dispatch(actionCreators.heightChanged(height)),
      onDiameterChanged: diameter => dispatch(actionCreators.diameterChanged(diameter))
   };
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Tyresearch);
