import React from 'react';

// own components
import TyreCartPosition from '../../../shared/tyreCartPosition/TyreCartPosition';

const ShoppingCartTyres = props => {
    const tyresList = props.shoppingCartTyres.map( ( selectedTyre, index ) => (
        <div key={ index }>
            <TyreCartPosition
                selectedTyre={ selectedTyre }
                key={ selectedTyre.productId }
                id={ selectedTyre.productId }
                deletable={ props.deletable }
                idx={ index }
                removeButtonHandler={ props.removeButtonHandler }
                amountSelectionHandler={ props.shoppingCartTireAmountSelectionHandler }
            />
        </div>
    ) );
    return <div>{ tyresList }</div>;
};

export default ShoppingCartTyres;
