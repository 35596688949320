import React from 'react';

// own components
import RimCartPosition from '../../../shared/rimCartPosition/RimCartPosition';

const ShoppingCartRims = props => {
    const rimsList = props.shoppingCartRims.map( ( selectedRim, index ) => (
        <div key={ index }>
            <RimCartPosition
                selectedRim={ selectedRim }
                key={ selectedRim.id }
                id={ selectedRim.id }
                deletable={ props.deletable }
                idx={ index }
                removeButtonHandlers={ props.removeButtonHandlers }
                amountSelectionHandler={ props.shoppingCartRimAmountSelectionHandler }
            />
        </div>
    ) );
    return <div>{ rimsList }</div>;
};

export default ShoppingCartRims;
