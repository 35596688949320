import React from 'react';
import {connect} from 'react-redux';
import {getCarTypes, getClasses, getManufacturers, getTypes} from '../../../../../redux-store/api';

class CarByManualSelection extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         loadingManufacturers: false,
         loadingModel: false,
         loadingType: false,
         manufacturers: [],
         filteredmanufacturers: [],
         models: [],
         filteredModel: [],
         types: [],
         filteredTypes: [],
         manufacturerClass: 'col-md-12',
         manufacturervisibility: 'd-block',
         modelClass: 'd-none',
         typeClass: 'd-none',
         manufacturer: this.props.manufacturerId ? this.props.manufacturerId : null,
         typeDetails: null,
         logoid: []
      };
      this.initialState = this.state;

      this.callbackToParent = (e) => {
         this.props.callbackFunction(e);
      };
   }

   rebuildRoute(prop) {
      let _state = this.state
      if (prop == "type") {
         _state.type = null;
         history.pushState('', '', `${this.props.rimPath}/manualSelect/${this.state.manufacturerId}/${this.state.model.id}/`);
      } else if (prop == "model") {
         _state.model = null;
         history.pushState('', '', `${this.props.rimPath}/manualSelect/${this.state.manufacturerId}//`);
      } else if (prop == "manufacturer") {
         _state.manufacturer = null;
         _state.modelClass = 'd-none';
         _state.typeClass = 'd-none';
         _state.manufacturerClass = 'col-md-12';
         history.pushState('', '', this.state.appPath);
      }
      this.setState(_state);
   }

   fetchType(requestBody) {
      this.setState({loadingType: true});
      setTimeout(
         function () {
            getTypes(requestBody)
            .then(
               (res) => {
                  if (res.Error != '' && res.Error != null && res.Error != undefined) {
                     console.log('Error fetching types', res.Error);
                     this.setState({
                        error: res.Error,
                        loadingType: false
                     });
                  } else {
                     this.setState({
                        types: res,
                        filteredTypes: res,
                        loadingType: false
                     });
                  }
               }
            )
            .catch(error => {
               this.setState({
                  error: error,
                  loadingType: false
               });
            });
         }
         .bind(this),
         0
      );
   }

   fetchModel(requestBody) {
      this.setState({loadingModel: true});
      setTimeout(
         function () {
            getClasses(requestBody)
            .then(
               (res) => {
                  if (res.Error != '' && res.Error != null && res.Error != undefined) {
                     console.log('Error fetching models', res.Error);
                     this.setState({
                        error: res.Error,
                        loadingModel: false
                     });
                  } else {
                     this.setState({
                        models: res,
                        filteredModel: res,
                        loadingModel: false
                     });
                     document.getElementById("varManufacturerId").value = requestBody.id;
                  }
               }
            )
            .catch(error => {
               this.setState({
                  error: error,
                  loadingModel: false
               });
            });
         }
         .bind(this),
         500
      );
   };

   fetchCarTypes(requestBody) {
      this.setState({loadingCarTypes: true});
      setTimeout(
         function () {
            getCarTypes(requestBody)
            .then(
               (res) => {
                  if (res.Error != '' && res.Error != null && res.Error != undefined) {
                     console.log('Error fetching models', res.Error);
                     this.setState({
                        error: res.Error,
                        loadingModel: false
                     });
                  } else {
                     this.setState({
                        carTypes: res,
                        filteredCarTypes: res,
                        loadingCarTypes: false
                     });
                     //document.getElementById("varManufacturerId").value = requestBody.id;
                  }
               }
            )
            .catch(error => {
               this.setState({
                  error: error,
                  loadingModel: false
               });
            });
         }
         .bind(this),
         500
      );
   };

   fetchManufacturers(requestBody) {
      this.setState({loadingManufacturers: true});
      setTimeout(
         function () {
            getManufacturers({})
            .then(
               (res) => {
                  if (res.Error != '' && res.Error != null && res.Error != undefined) {
                     console.log('Error fetching manufacturers', res.Error);
                     this.setState({
                        error: res.Error,
                        loadingManufacturers: false
                     });
                  } else {
                     this.setState({
                        manufacturers: res,
                        filteredmanufacturers: res,
                        loadingManufacturers: false
                     });
                  }
                  let _ids = [];
                  res.map((manufacturer, index) => {
                     _ids[manufacturer.name] = manufacturer.id;
                  });
                  // ab hier Fallback
                  if (typeof _ids['Audi'] == 'undefined') {
                     _ids['Audi'] = 4;
                  }
                  if (typeof _ids['BMW'] == 'undefined') {
                     _ids['BMW'] = 8;
                  }
                  if (typeof _ids['Mercedes'] == 'undefined') {
                     _ids['Mercedes'] = 42;
                  }
                  if (typeof _ids['VW'] == 'undefined') {
                     _ids['VW'] = 67;
                  }
                  this.state.logoid = _ids;
               }
            )
            .catch(error => {
               this.setState({
                  error: error,
                  loadingManufacturers: false
               });
            });
         }
         .bind(this),
         500
      );
   }

   resetButton(props) {
      this.props.resetButton(props);
      if (props !== '3') {
         let _state = this.state;
         _state.modelClass = 'd-none';
         _state.typeClass = 'd-none';
         _state.manufacturerClass = 'col-md-12';
         this.setState(_state);
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.text !== this.props.text) {
         this.updateAndNotify();
      }
      if (prevProps.resetButtonValue !== this.props.resetButtonValue) {
         this.rebuildRoute(this.props.resetButtonValue);
      }
      if (this.props.buttonpushed == 'clicked') {
         if ((this.state.model !== null) && (this.state.typeDetails === null)) {
            let _state = this.state;
            _state.model = null;
            this.setState(_state);
            this.resetButton('1');
         } else if ((this.state.manufacturer !== null) && (this.state.typeDetails === null)) {
            let _state = this.state;
            _state.manufacturer = null;
            _state.filteredModel = [];
            this.setState(_state);
            this.resetButton('2');
         } else if ((this.state.manufacturer === null) && (this.state.typeDetails === null)) {
            this.resetButton('3');
         } else if (this.state.type !== null && this.state.typeDetails !== null) {
            let _state = this.state;
            _state.type = null;
            _state.typeDetails = null;
            this.setState(_state);

            this.resetButton('4');
         }
      }
      if (this.props.type && this.state.types.length > 0 && this.state.typeDetails === null) {
         this.setType(this.props.type);
      }
      let _state = this.state;
      if (_state.manufacturervisibility != this.props.manufacturervisibility) {
         _state.manufacturervisibility = this.props.manufacturervisibility;
         this.setState(_state);
      }
      if (this.props.resetManualSelection) {
         let _state = this.initialState;
         _state.manufacturerClass = 'col-md-4';
         _state.modelClass = 'd-block';
         _state.typeClass = 'd-block';
         _state.model = null;
         this.setState(_state);
         this.fetchManufacturers({});
         this.setManufacturer(null);
      }

   }

   filterManufacturers() {
      const manufacturers = document.getElementById('manufacturersbox').value;
      let regex = new RegExp(manufacturers, 'i');
      let result = this.state.manufacturers.filter(manufacturer => manufacturer.name.match(regex));
      let _state = this.state;
      _state.filteredmanufacturers = result;
      this.setState(_state);

   }

   filterModel() {
      const models = document.getElementById('modelbox').value;
      let regex = new RegExp(models, 'i');
      let result = this.state.models.filter(model => model.name.match(regex));
      let _state = this.state;
      _state.filteredModel = result;
      this.setState(_state);

   }

   filterType() {
      const types = document.getElementById('typebox').value;
      let regex = new RegExp(types, 'i');
      let result = this.state.types.filter(type => type.type.match(regex));
      let _state = this.state;
      _state.filteredTypes = result;
      this.setState(_state);

   }

   setManufacturer(manufacturer) {
      let _state = this.state;
      if (manufacturer === null) {
         _state.manufacturer = manufacturer;
      } else if (manufacturer && !manufacturer.id) {
         _state.manufacturerId = manufacturer;
      } else {
         _state.manufacturer = manufacturer.name;
         _state.manufacturerId = manufacturer.id;
      }
      _state.manufacturerClass = 'col-md-4';
      _state.modelClass = 'd-block';
      _state.typeClass = 'd-block';
      _state.model = null;
      this.setState(_state);
      this.props.classCallback('col-md-12');
      this.props.middleCallback('d-none');
      if (manufacturer !== null) {
         /**
          * Manufacturer.id is the id of the manufacturer
          * not the whole manufacturer should be sent if the key/id is not available
          * The backend need an id to execute the request against the EIOS server
          * Send null/undefined or 0
          */
         this.fetchModel({id: manufacturer.id ? manufacturer.id : null});
      }
      history.pushState('', '', `${this.props.rimPath}/manualSelect/${this.state.manufacturerId}//`);

   }

   setModel(id) {
      let _state = this.state;
      _state.model = id;
      this.setState(_state);
      this.fetchType({
         class: _state.model,
         manufacturer: _state.manufacturerId
      });
      history.pushState('', '', `${this.props.rimPath}/manualSelect/${this.state.manufacturerId}/${this.state.model.id ? this.state.model.id : this.state.model}/`);
   }

   setType(type) {
      let _state = this.state;
      if (type.id) {
         _state.type = type.id;
         _state.typeDetails = type;
      } else {
         let types = _state.types.filter(x => x.id.constructionSeriesId === type);
         _state.type = types[0].id;
         _state.typeDetails = types[0];

      }
      this.setState(_state);
      if (this.state.type != null) {
         this.callbackToParent({
            type: this.state.type,
            typeDetails: this.state.typeDetails
         });
      }
      history.pushState('', '', `${this.props.rimPath}/manualSelect/${this.state.manufacturerId}/${this.state.model.id ? this.state.model.id : this.state.model}/${this.state.type.constructionSeriesId}`);
   }

   componentDidMount() {
      this.fetchManufacturers({});
      if (this.props.manufacturerId) {
         this.setManufacturer(this.props.manufacturerId);
      }
      if (this.props.model) {
         let model = {};
         model.id = this.props.model
         this.setModel(model);
      }
   }

   render() {
      let loadingBoxes = [];
      for (let i = 0; i < 25; i++) {
         loadingBoxes.push(
            <div className={'loading boxitem linkbox '} key={i + "_loading_cards"}>
               <div className={' loading-animation '}/>
            </div>
         );
      }
      return (
         <div className="row manual-selection-wrapper">
            <input type="hidden" id="varManufacturerName"/>
            <input type="hidden" id="varManufacturerId"/>
            <input type="hidden" id="varModell"/>
            <input type="hidden" id="varType"/>
            <div className={this.state.manufacturerClass + ' ' + this.state.manufacturervisibility}
                 id="selectmanufacturer">
               <div className={' bg-milky'}>
                  <div className="boxitem"><h3>Über die Fahrzeugauswahl</h3></div>
                  <div className="boxitem innerbox innerform search">
                     <input type="text" id="manufacturersbox" placeholder="Marke suchen..."
                            onChange={() => this.filterManufacturers()}
                            className={'search-field'}/>
                  </div>
                  <div className=" boxitem">
                     <div className="d-inline-block audi" onClick={() => this.setManufacturer({
                        name: 'Audi',
                        key: this.state.logoid['Audi']
                     })}>
                        <img className={'manufacturericon audi'} src="/audi.png"/>
                     </div>
                     <div className="d-inline-block bmw" onClick={() => this.setManufacturer({
                        name: 'BMW',
                        key: this.state.logoid['BMW']
                     })}>
                        <img className={'manufacturericon'} src="/BMW.png"/>
                     </div>
                     <div className="d-inline-block mercedes" onClick={() => this.setManufacturer({
                        name: 'Mercedes',
                        key: this.state.logoid['Mercedes']
                     })}>
                        <img className={'manufacturericon'} src="/mercedes.png"/>
                     </div>
                     <div className="d-inline-block vw" onClick={() => this.setManufacturer({
                        name: 'VW',
                        key: this.state.logoid['VW']
                     })}>
                        <img className={'manufacturericon'} src="/vw.svg"/>
                     </div>
                  </div>
                  <div className="scrollbox innerbox">
                     {(this.state.loadingManufacturers == false) ?
                        <div>
                           {this.state.filteredmanufacturers.map((manufacturer, index) => {
                              return (
                                 <div className={' boxitem linkbox clickable'
                                 + ((this.state.manufacturer && (this.state.manufacturerId == manufacturer.id)) ? ' selected' : '')
                                 + ((this.state.manufacturer && (this.state.manufacturerId != manufacturer.id)) ? ' d-none d-md-block' : '')
                                 } onClick={() => this.setManufacturer(manufacturer)}
                                      key={'manufacturer_' + index}>
                                    <div className={'clickable carlogo ' + manufacturer.name}>
                                       {manufacturer.name}
                                    </div>
                                 </div>
                              );
                           })
                           }
                        </div>
                        :
                        <div>
                           {loadingBoxes}
                        </div>
                     }
                  </div>
               </div>
            </div>
            <div className={this.state.modelClass + ' col-md-4'} id="selectmodel">
               {this.state.manufacturer == null ?
                  <div className="placeholder-outer">
                     <div className="placeholder-inner">MODELL</div>
                  </div>
                  :
                  <div className="bg-milky">
                     <div className="boxitem">
                        <h3>Modell</h3>
                     </div>
                     <div className="boxitem innerbox innerform search">
                        <input type="text" id="modelbox" placeholder="Modell suchen..."
                               onChange={() => this.filterModel()}
                               className={'search-field'}/>
                     </div>
                     <div className={'innerbox scrollbox'}>
                        {this.state.models.length == 0 && this.state.loadingModel == false ?
                           <div>
                              <div className="row boxitem">
                                 keine Modelle gefunden!
                              </div>
                           </div>
                           :
                           ''
                        }
                        {(this.state.loadingModel == false) ?
                           <div>
                              {this.state.filteredModel.map((model, index) => {
                                 return (
                                    <div className={'row boxitem linkbox clickable'
                                    + ((this.state.model && (this.state.model.id == model.id)) ? ' selected' : '')
                                    + ((this.state.model && (this.state.model.id != model.id)) ? ' d-none d-md-block' : '')
                                    } key={'model_' + index} onClick={() => this.setModel(model)}>
                                       <div className="col-md-12 link">
                                          {model.name}
                                       </div>
                                    </div>
                                 );
                              })
                              }
                           </div>
                           :
                           <div>
                              {loadingBoxes}
                           </div>
                        }
                     </div>
                  </div>
               }
            </div>
            <div className={this.state.typeClass + ' col-md-4'} id="selecttype">
               {
                  this.state.model == null ?
                     <div className="placeholder-outer">
                        <div className="placeholder-inner">BAUREIHE</div>
                     </div>
                     :
                     <div className="bg-milky">
                        <div className="boxitem">
                           <h3>Baureihe</h3>
                        </div>
                        <div className="boxitem innerbox innerform search">
                           <input type="text" id="typebox" placeholder="Filtern"
                                  onChange={() => this.filterType()}
                                  className={'search-field'}/>
                        </div>
                        <div className={'innerbox scrollbox'}>
                           {this.state.types.length == 0 && this.state.loadingType == false ?
                              <div className="row boxitem">
                                 keine Baureihen gefunden!
                              </div>
                              :
                              ''
                           }
                           {(this.state.loadingType == false) ?
                              <div>
                                 {this.state.filteredTypes.map((type, index) => {
                                    return (
                                       <div className="row boxitem linkbox clickable"
                                            key={'type_' + index} onClick={() => this.setType(type)}>
                                          <div className="col-md-12 link">
                                             <div className={'row'}>
                                                <div className={'col'}>
                                                   {type.name}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    );
                                 })}
                              </div>
                              :
                              <div>
                                 {loadingBoxes}
                              </div>
                           }
                        </div>
                     </div>
               }
            </div>
         </div>
      );
   }
}

CarByManualSelection.serverFetch = '';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
//    tyreDetailAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CarByManualSelection);
