import * as actionTypes from '../actions/actionTypes';
import selectRim from '../../modules/Rimconfigurator/pages/rims/SelectRim';

const initialState = {
    shoppingCartTyres: [],
    shoppingCartRims: [],
    amountSelectionChanged: null,
    totalCount: 0,
    totalPrice: 0,
    buttonState: null,
    timestamp: null,
    assemblyCost: 0,
    assemblyCosts: []
};

const reducer = ( state, action ) => {
    state = state || initialState;

    switch ( action.type ) {
        case actionTypes.SET_SELECTED_CART_selectedRim:
            const _shoppingCartRims = state.shoppingCartRims.slice( 0 );
            const arr1 = [];

            if ( _shoppingCartRims.length === 0 ) {
                const _rims = {};
                ( _rims.image = action.selectedRim.imageUrl );
                ( _rims.id = action.selectedRim.id );
                ( _rims.bmfRimId = action.selectedRim.bmfRimId );
                ( _rims.brandName = action.selectedRim.brandName );
                ( _rims.color = action.selectedRim.color );
                ( _rims.design = action.selectedRim.design );
                ( _rims.holeCircle = action.selectedRim.holeCircle );
                ( _rims.holeCircle2 = action.selectedRim.holeCircle2 );
                ( _rims.hubBoreHole = action.selectedRim.hubBoreHole );
                ( _rims.diameter = action.selectedRim.diameter );
                ( _rims.et = action.selectedRim.et );
                ( _rims.amount = action.amount );
                ( _rims.width = action.selectedRim.width );
                ( _rims.winterProof = action.selectedRim.winterProof );
                ( _rims.totalAmount = action.selectedRim.totalAmount );
                ( _rims.minPrice = action.selectedRim.minPrice );
                ( _rims.endUserCalcResult = action.selectedRim.endUserCalcResult );
                ( _rims.ipc = action.selectedRim.ipc );
                ( _rims.gtin = action.selectedRim.gtin );
                ( _rims.abe = action.selectedRim.abe );
                ( _rims.ece = action.selectedRim.ece );
                ( _rims.abeCertificateId = action.selectedRim.abeCertificateId );
                ( _rims.certId = action.selectedRim.certId );
                ( _rims.serialMountingKit = action.selectedRim.serialMountingKit );
                _shoppingCartRims.push( _rims );
            } else {
                _shoppingCartRims.forEach( element => {
                    arr1.push( element.id );
                } );
                if ( arr1.includes( action.selectedRim.id ) ) {
                    _shoppingCartRims.forEach( element => {
                        if ( element.id == action.selectedRim.id ) {
                            element.amount = action.amount;
                        }
                    } );
                } else {
                    const _rims = {};
                    ( _rims.image = action.selectedRim.imageUrl );
                    ( _rims.id = action.selectedRim.id );
                    ( _rims.bmfRimId = action.selectedRim.bmfRimId );
                    ( _rims.brandName = action.selectedRim.brandName );
                    ( _rims.color = action.selectedRim.color );
                    ( _rims.design = action.selectedRim.design );
                    ( _rims.holeCircle = action.selectedRim.holeCircle );
                    ( _rims.holeCircle2 = action.selectedRim.holeCircle2 );
                    ( _rims.hubBoreHole = action.selectedRim.hubBoreHole );
                    ( _rims.diameter = action.selectedRim.diameter );
                    ( _rims.et = action.selectedRim.et );
                    ( _rims.amount = action.amount );
                    ( _rims.width = action.selectedRim.width );
                    ( _rims.winterProof = action.selectedRim.winterProof );
                    ( _rims.totalAmount = action.selectedRim.totalAmount );
                    ( _rims.minPrice = action.selectedRim.minPrice );
                    ( _rims.endUserCalcResult = action.selectedRim.endUserCalcResult );
                    ( _rims.ipc = action.selectedRim.ipc );
                    ( _rims.gtin = action.selectedRim.gtin );
                    ( _rims.abe = action.selectedRim.abe );
                    ( _rims.ece = action.selectedRim.ece );
                    ( _rims.abeCertificateId = action.selectedRim.abeCertificateId );
                    ( _rims.certId = action.selectedRim.certId );
                    ( _rims.serialMountingKit = action.selectedRim.serialMountingKit );
                    _shoppingCartRims.push( _rims );
                }
            }

            return Object.assign( {}, state, {
                shoppingCartRims: _shoppingCartRims
            } );

        case actionTypes.SET_SELECTED_CART_selectedTyre:
            const _shoppingCartTyres = state.shoppingCartTyres.slice( 0 );
            const arr = [];

            if ( _shoppingCartTyres.length == 0 ) {
                const _tire = {};
                _tire.image = action.selectedTyre.productImageUrl;
                ( _tire.producer = action.selectedTyre.brand );
                ( _tire.name = action.selectedTyre.name );
                ( _tire.tread = action.selectedTyre.tread );
                ( _tire.description = action.selectedTyre.description );
                ( _tire.price = action.selectedTyre.B2CPrice );
                ( _tire.productId = action.selectedTyre.productId );
                ( _tire.amount = action.amount );
                _tire.assemblyCostSteel = action.selectedTyre.response.assemblyCostSteel;
                _tire.assemblyCostAlloy = action.selectedTyre.response.assemblyCostAlloy;
                _tire.assemblyCostRft = action.selectedTyre.response.assemblyCostRft;
                _tire.productCategoryName = action.selectedTyre.productCategoryName;
                ( _tire.brand = action.selectedTyre.brand );
                _tire.noiseDb = action.selectedTyre.noiseDb;
                _tire.wetGrip = action.selectedTyre.wetGrip;
                _tire.rollingResistance = action.selectedTyre.rollingResistance;
                _tire.vehicleType = action.selectedTyre.vehicleTypes;

                _shoppingCartTyres.push( _tire );
            } else {
                _shoppingCartTyres.forEach( element => {
                    arr.push( element.productId );
                } );
                if ( arr.includes( action.selectedTyre.productId ) ) {
                    _shoppingCartTyres.forEach( element => {
                        if ( element.productId == action.selectedTyre.productId ) {
                            element.amount = action.amount;
                        }
                    } );
                } else {
                    const _tire = {};
                    _tire.image = action.selectedTyre.productImageUrl;
                    ( _tire.producer = action.selectedTyre.brand );
                    ( _tire.name = action.selectedTyre.name );
                    ( _tire.tread = action.selectedTyre.tread );
                    ( _tire.description = action.selectedTyre.description );
                    ( _tire.price = action.selectedTyre.B2CPrice );
                    ( _tire.productId = action.selectedTyre.productId );
                    ( _tire.amount = action.amount );
                    _tire.assemblyCostSteel = action.selectedTyre.response.assemblyCostSteel;
                    _tire.assemblyCostAlloy = action.selectedTyre.response.assemblyCostAlloy;
                    _tire.assemblyCostRft = action.selectedTyre.response.assemblyCostRft;
                    _tire.productCategoryName = action.selectedTyre.productCategoryName;
                    ( _tire.brand = action.selectedTyre.brand );
                    _tire.noiseDb = action.selectedTyre.noiseDb;
                    _tire.wetGrip = action.selectedTyre.wetGrip;
                    _tire.rollingResistance = action.selectedTyre.rollingResistance;
                    _tire.vehicleType = action.selectedTyre.vehicleTypes;
                    _shoppingCartTyres.push( _tire );
                }
            }
            return Object.assign( {}, state, {
                shoppingCartTyres: _shoppingCartTyres
            } );

        case actionTypes.SET_SELECTED_AMOUNT:
            const _scTyres = state.shoppingCartTyres.slice( 0 );
            const _arr = [];
            _scTyres.forEach( element => {
                _arr.push( element.productId );
            } );

            if ( _arr.includes( action.tyreId ) ) {
                _scTyres.forEach( element => {
                    if ( element.productId == action.tyreId ) {
                        element.computedAmount = action.computedAmount;
                    }
                } );
            }

            return Object.assign( {}, state, {
                shoppingCartTyres: _scTyres
            } );

        case actionTypes.CREATE_TIME_STAMP:
            let _timestamp = new Date();
            _timestamp = _timestamp.setMinutes( _timestamp.getMinutes() + 24 );
            return Object.assign( {}, state, {
                timestamp: _timestamp
            } );

        case actionTypes.SET_SHOPPING_CART_TIRE_CHANGED:
            const tyres = state.shoppingCartTyres;
            tyres.forEach( function ( tyre ) {
                if ( tyre.productId === action.selectedTyre.productId ) {
                    tyre = action.selectedTyre;
                    // if (selectedTyre.assemblyCosts === 'Runflat') {
                    //   selectedTyre.assemblyCost = selectedTyre.assemblyCostRft;
                    // }
                }
            } );
            return Object.assign( {}, state, {
                shoppingCartTyres: tyres
            } );

        case actionTypes.SET_SHOPPING_CART_RIM_CHANGED:
            const rims = state.shoppingCartRims;
            rims.forEach( function ( rim ) {
                if ( rim.id === action.selectedRim.id ) {
                    rim = action.selectedRim;
                    // if (selectedTyre.assemblyCosts === 'Runflat') {
                    //   selectedTyre.assemblyCost = selectedTyre.assemblyCostRft;
                    // }
                }
            } );
            return Object.assign( {}, state, {
                shoppingCartRims: rims
            } );

        case actionTypes.SET_SHOPPING_CART_TIRE_SELECTED_AMOUNT:
            const __shoppingCartTyres = state.shoppingCartTyres;
            __shoppingCartTyres.forEach( function ( element ) {
                if ( element.productId === action.tyreId ) {
                    element.amount = action.amount;
                    element.computedAmount = action.amount;
                }
            } );
            return Object.assign( {}, state, {
                shoppingCartTyres: __shoppingCartTyres
            } );

        case actionTypes.DELETE_CART_TIRE:
            const updatedResults = state.shoppingCartTyres.slice( 0 );
            const indx = action.cartTireIndex;
            if ( indx >= 0 ) {
                updatedResults.splice( indx, 1 );
                return Object.assign( {}, state, {
                    shoppingCartTyres: updatedResults
                } );
            }

        case actionTypes.DELETE_CART_RIM:
            const updatedResult = state.shoppingCartRims.slice( 0 );
            const indeex = action.cartRimIndex;
            if ( indeex >= 0 ) {
                updatedResult.splice( indeex, 1 );
                return Object.assign( {}, state, {
                    shoppingCartRims: updatedResult
                } );
            }

        case actionTypes.AMOUNT_SELECTION_CHANGED:
            let _amountSelectionChanged = state.amountSelectionChanged;
            _amountSelectionChanged = action.amountSelectionChanged;
            return Object.assign( {}, state, {
                amountSelectionChanged: _amountSelectionChanged
            } );

        case actionTypes.SAVE_BUTTON_STATE:
            let _buttonState = state.buttonState;
            _buttonState = action.buttonState;
            return Object.assign( {}, state, {
                buttonState: _buttonState
            } );

        case actionTypes.RESET_SC:
            let scTyres = state.shoppingCartTyres;
            let scRims = state.shoppingCartRims;
            let total_Price = state.totalPrice;
            let total_Count = state.totalCount;
            let time_Stamp = state.timestamp;
            const assembly_Cost = state.assemblyCost;
            scTyres = [];
            scRims = [];
            total_Price = 0;
            total_Count = 0;
            time_Stamp = null;
            return Object.assign( {}, state, {
                totalCount: total_Count,
                totalPrice: total_Price,
                shoppingCartTyres: scTyres,
                shoppingCartRims: scRims,
                timestamp: time_Stamp,
                assemblyCost: assembly_Cost
            } );

        case actionTypes.AMOUNT_COUNT:
            let _totalCount = state.totalCount;
            let count = 0;
            state.shoppingCartTyres.map( tire => {
                count = parseInt( count ) + parseInt( tire.amount );
                _totalCount = count;
            } );
            state.shoppingCartRims.map( rim => {
                count = parseInt( count ) + parseInt( rim.amount );
                _totalCount = count;
            } );
            if ( state.shoppingCartTyres.length === 0 && state.shoppingCartRims.length === 0 ) {
                _totalCount = 0;
            }
            return Object.assign( {}, state, {
                totalCount: _totalCount
            } );
        case actionTypes.SELECTED_ASSEMBLY_COST:
            const ___shoppingCartTyres = state.shoppingCartTyres;
            ___shoppingCartTyres.forEach( function ( element ) {
                if ( element.productId === action.tireId ) {
                    element.assemblyCosts = action.assemblyCosts;
                    element.selectedAssemblyCosts = action.selectedAssemblyCosts;
                }
            } );
            return Object.assign( {}, state, {
                shoppingCartTyres: ___shoppingCartTyres
            } );
        case actionTypes.ASSEMBLY_COSTS:
            let _assemblyCosts = state.assemblyCosts;
            _assemblyCosts = action.assemblyCosts;
            return Object.assign( {}, state, {
                assemblyCosts: _assemblyCosts
            } );
        case actionTypes.UPDATE_TOTAL_PRICE:
            let _totalPrice = state.totalPrice;
            let total = null;
            let totalAssemblyCosts = null;
            state.shoppingCartTyres.map( tire => {
                total += tire.price * tire.amount;
                if ( tire.assemblyType ) {
                    if ( tire.assemblyType === 'Stahlfelge' ) {
                        tire.assemblyCosts = tire.assemblyCostSteel;
                    } else if ( tire.assemblyType === 'Runflat' ) {
                        tire.assemblyCosts = tire.assemblyCostRft;
                    } else if ( tire.assemblyType === 'Leichtmetall' ) {
                        tire.assemblyCosts = tire.assemblyCostAlloy;
                    } else {
                        tire.assemblyCosts = 0;
                    }
                    totalAssemblyCosts = tire.assemblyCosts * tire.amount;
                    total += totalAssemblyCosts;
                }
                _totalPrice = total;
            } );
            state.shoppingCartRims.map( rim => {
                total += rim.minPrice * rim.amount;
                // if ( rim.assemblyType ) {
                //     if ( tire.assemblyType === 'Stahlfelge' ) {
                //         tire.assemblyCosts = tire.assemblyCostSteel;
                //     } else if ( tire.assemblyType === 'Runflat' ) {
                //         tire.assemblyCosts = tire.assemblyCostRft;
                //     } else if ( tire.assemblyType === 'Leichtmetall' ) {
                //         tire.assemblyCosts = tire.assemblyCostAlloy;
                //     } else {
                //         tire.assemblyCosts = 0;
                //     }
                //     totalAssemblyCosts = tire.assemblyCosts * tire.amount;
                //     total += totalAssemblyCosts;
                // }
                _totalPrice = total;
            } );
            if ( state.shoppingCartTyres.length === 0 && state.shoppingCartRims.length === 0 ) {
                _totalPrice = 0;
            }
            return Object.assign( {}, state, {
                totalPrice: _totalPrice
            } );
    }
    return state;
};

export default reducer;
