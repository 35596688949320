import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

class CheckoutConfirmed extends React.Component {
   constructor(props) {
      super(props);
      // initial state
      this.state = {
         steps: 'CheckoutConfirmed',
         step: 4
      };
   }

   render() {
      return (
         <div className={'text-center'}>
            <img src={"/thumbsup.png"} className={"thumbsup"}/>
            <div className={"checkout-text-large"}>
               Vielen Dank für Ihre Anfrage,
            </div>
            <div className={"checkout-text-small"}>
               Sie werden in Kürze eine Email mit weiteren Details erhalten.
            </div>
            <Link className="btn btn-primary" to={'/'}>
               Zur Suche
            </Link>
         </div>
      );
   }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutConfirmed);
