import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import AddressFooter from "../shared/address/AddressFooter";

const Footer = props => (
   <div className="footer">
      <div className="container">
         <div className="footer-wrapper">
            <div className={"footer-logo"}/>
            <b>{props.firmData[0] ? props.firmData[0].AllgemeineDaten.Name : ''}</b>
            <AddressFooter
               firmData={props.firmData}
               openingTimesCount="hidden"
               hauptsitz={true}
            />
            {props.firmData ?
               <div className="links">
                  <Link to="/contact"> Kontakt </Link>
                  <div className="space"> |</div>
                  <a target="_blank" href={props.firmData[0].AllgemeineDaten.Datenschutz}>Datenschutzerklärung</a>
                  <div className="space"> |</div>
                  <Link to="/imprint"> Impressum </Link>
               </div>
               :
               <div></div>
            }
         </div>
      </div>
   </div>
);

const mapStateToProps = state => ({
   loggedIn: state.loggedIn,
   firmData: state.firmData
});
export default connect(mapStateToProps)(Footer);
