export const STORE_selectedTyreS = 'TYRES_STORE';
export const FETCH_selectedTyreS_ERROR = 'TYRES_ERROR_FETCH';
export const STORE_SPECIAL_OFFER_selectedTyreS = 'TYRES_SPECIAL_OFFER_STORE';
// server side
export const STORE_selectedTyreSIZES = 'TYRESIZES_STORE';
export const STORE_selectedTyre = 'TYRE_STORE';
export const LOAD_selectedTyre = 'LOAD_STORE';
export const LOAD_selectedTyre_ERROR = 'LOAD_selectedTyre_ERROR';
export const SERVER_SET_NAVIGATION = 'SERVER_SET_NAVIGATION';
// client side
export const FETCH_TIRE_SIZES = 'TIRE_SIZES_FETCH';
export const FETCH_TIRE_SIZES_ERROR = 'TIRE_SIZES_FETCH_ERROR';

// BRANDS
export const FETCH_BRANDS = 'BRANDS_FETCH';
export const FETCH_BRANDS_ERROR = 'BRANDS_FETCH_ERROR';

export const STORE_ORDER = 'ORDER_STORE';
export const ORDER_SENT = 'ORDER_SENT';
export const RESET_SENT = 'RESET_SENT';
export const SEND_ORDER_ERROR = 'ERROR_SEND_ORDER';

export const SET_TIRE_FITTING = 'TIRE_FITTING_SET';
export const RESET_TIRE_FITTING = 'TIRE_FITTING_RESET';
export const SET_TERMS_OF_USE = 'TERMS_OF_USE_SET';
export const RESET_TERMS_OF_USE = 'TERMS_OF_USE_RESET';
export const SET_DATA_PROTECTION = 'DATA_PROTECTION_SET';
export const RESET_DATA_PROTECTION = 'DATA_PROTECTION_RESET';
export const SET_WHEEL_ALIGNMENT = 'WHEEL_ALIGNMENT_SET';
export const RESET_WHEEL_ALIGNMENT = 'WHEEL_ALIGNMENT_RESET';

export const SET_SELECTED_CART_selectedTyre = 'CART_TIRE_SET_SELECTED';
export const SET_SELECTED_CART_selectedRim = 'CART_RIM_SET_SELECTED';
export const DELETE_CART_selectedTyre = 'CART_TIRE_DELETE';
export const SET_SELECTED_AMOUNT = 'SELECTED_AMOUNT_SET';
export const SET_SHOPPING_CART_TIRE_SELECTED_AMOUNT =
  'SHOPPING_CART_TIRE_AMOUNT_SET_SELECTED';
export const SET_SHOPPING_CART_TIRE_CHANGED = 'SET_SHOPPING_CART_TIRE_CHANGED';
export const SET_SHOPPING_CART_RIM_CHANGED = 'SET_SHOPPING_CART_RIM_CHANGED';
export const DELETE_CART_TIRE = 'CART_TIRE_DELETE';
export const DELETE_CART_RIM = 'CART_RIM_DELETE';
export const AMOUNT_SELECTION_CHANGED = 'AMOUNT_SELECTION_CHANGED';
export const AMOUNT_COUNT = 'COUNT_AMOUNT';
export const SAVE_BUTTON_STATE = 'BUTTON_STATE_SAVE';
export const UPDATE_TOTAL_PRICE = 'TOTAL_PRICE_UPDATE';
export const RESET_SC = 'SC_RESET';
export const LOAD_selectedTyreS = 'LOAD_selectedTyreS';
export const COOKIE_ACCEPTED = 'COOKIE_ACCEPTED';
export const SET_ROUTE_CHANGED_FROM = 'ROUTE_CHANGED_FROM';
export const SET_ROUTE_CHANGED_TO = 'ROUTE_CHANGED_TO';
export const SET_NAVIGATION = 'NAVIGATION_SET';
export const SET_PAGE = 'PAGE_SET';
export const FILTER_SELECTIONS = 'SELECTIONS_FILTER';
export const SET_FILTER_SELECTIONS = 'FILTER_SELECTIONS_SET';
export const RESET_FILTER_SELECTIONS = 'FILTER_SELECTIONS_RESET';
export const COMPUTED_URL_VALUE = 'URL_VALUE_COMPUTED';
export const SELECTED_SORT_VALUE = 'SORT_VALUE_SELECTED';
export const FLAG_CHANGED = 'FLAG_CHANGED';
export const RESET_SEARCH_FLAG = 'SEARCH_FLAG_RESET';
export const SET_SELECTED_SEASON = 'SEASON_SELECTED_SET';
export const SET_SELECTED_VEHICLE_TYPE = 'VEHICLE_TYPE_SELECTED_SET';
export const SET_SELECTED_SPEED = 'SPEED_SELECTED_SET';
export const SET_SELECTED_LOAD = 'LOAD_SELECTED_SET';
export const SET_HOME_SEARCH = 'SET_HOME_SEARCH';
export const RESET_HOME_SEARCH = 'RESET_HOME_SEARCH';
export const SET_SELECTED_LENGTH = 'SET_SELECTED_LENGTH';
export const SET_SELECTED_HEIGHT = 'SET_SELECTED_HEIGHT';
export const SET_SELECTED_DIAMETER = 'SET_SELECTED_DIAMETER';
export const CREATE_TIME_STAMP = 'TIME_STAMP_CREATE';
export const SELECTED_ASSEMBLY_COST = 'ASSEMBLY_COST_SELECTED';
export const ASSEMBLY_COSTS = 'COSTS_ASSEMBLY';
export const TEST = 'TEST';

// RIM CONFIGURATOR
export const LOAD_CAR = 'LOAD_CAR';
export const LOAD_CAR_ERROR = 'LOAD_CAR_ERROR';
export const STORE_CAR = 'STORE_CAR';
export const SAVE_FAVRIMS = 'SAVE_FAVRIMS';
// test
export const LIST_REQUEST = 'LIST_REQUEST';
