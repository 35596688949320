import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import * as actionCreators from '../../../../redux-store/actions';
import {connect} from 'react-redux';

class RimSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firm: this.props.firm,
            selectedRim: 'No data received from IFrame, yet.',
            computedAmount: 4
        };
    }


    render() {
        return (
            <div className="rim-search">
                <IframeResizer
                    heightCalculationMethod="bodyScroll"
                    src={process.env.REACT_APP_RIMCONFIGURATOR_URL + '/' + this.state.firm[0].Id}
                    frameBorder={0}
                    style={{width: '1px', minWidth: '100%'}}
                />
            </div>
        );
    }

    componentDidMount() {
        window.addEventListener(
            'message',
            (ev) => {
                if (ev.data.selectedRim && this.state.selectedRim) {
                    this.setState({
                        selectedRim: ev.data.selectedRim
                    });
                }
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            this.props.onResetTermsOfUse();
            this.props.onResetDataProtection();
            this.props.onAddRimToCart(this.state.selectedRim, this.state.computedAmount);
            this.props.onUpdateTotalPrice();
        }
    }
}

const mapStateToProps = state => ({
    selectedRim: state.selectedRim,
    shoppingCartRims: state.shoppingcart.shoppingCartRims,
    firm: state.firmData
});

const mapDispatchToProps = dispatch => ({
    onAddRimToCart: (selectedRim, amount) => dispatch(actionCreators.setSelectedCartRims(selectedRim, amount)),
    onResetTermsOfUse: () => actionCreators.resetTermsOfUse(),
    onResetDataProtection: () => actionCreators.resetDataProtection(),
    onUpdateTotalPrice: () => dispatch(actionCreators.updateTotalPrice())
});

export default connect(mapStateToProps, mapDispatchToProps)(RimSearch);
