import React from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import CarByManualSelection from './components/CarByManualSelection';
import CarByHsnTsn from './components/CarByHsnTsn';
import CarDetails from './components/CarDetails';
import CarSelectionHeader from './components/CarSelectionHeader';
import {createBrowserHistory} from 'history';

let history;

class CarSelection extends React.Component {

   constructor(props) {
      super(props);
      this.manualSelectionClass = 'col-12 col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 order-xs-last ';
      this.middleSpacerClass = 'd-none d-md-block';
      this.manualSelectionVisibility = 'd-block';
      this.manufacturervisibility = 'd-block';
      this.hsntsnSize = 'col-12 col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 ';
      this.hsntsnClass = 'd-block';
      this.detailClass = 'd-none';
      this.headerClass = 'd-block';
      this.state = {
         manualSelectionClass: this.manualSelectionClass,
         manualSelectionVisibility: this.manualSelectionVisibility,
         manufacturervisibility: this.manufacturervisibility,
         middleSpacerClass: this.middleSpacerClass,
         detailClass: this.detailClass,
         hsntsnClass: this.hsntsnClass,
         firmData: this.props.firmData,
         carlist: null,
         method: null,
         selectedCar: null,
         hsntsnSize: this.hsntsnSize,
         headerClass: this.headerClass,
         buttonpushed: null,
         resetButtonValue: '',
         rimPath: ''
      };
      this.switchmanufacturercallback = params => {
         let _state = this.state;
         _state.manufacturervisibility = params;
         this.setState(_state);
      };

      this.DetailCallback = params => {
         if (params.visible = true) {
            let _state = this.state;
            _state.middleSpacerClass = 'd-none';
            _state.hsntsnClass = 'd-none';
            _state.detailClass = 'd-block';
            _state.manualSelectionVisibility = 'd-none';
            this.setState(_state);
         }
      };

      this.HsnTsnCallback = params => {
         if (params.id) {
            this.props.history.push(this.state.rimPath + '/car/' + params.id)
         } else {
            let selectedCar = params;
            let _state = this.state;
            _state.headerClass = 'd-none';
            _state.selectedCar = selectedCar;
            _state.detailClass = 'd-block';
            this.setState(_state);
         }
      };
      this.ManualCallback = params => {
         let selectedCar = params.type;
         let _state = this.state;
         _state.selectedCar = selectedCar;
         _state.selectedCarDetails = params.typeDetails;
         this.setState(_state);
      };
      this.ManualMiddleCallback = params => {
         let _state = this.state;
         _state.middleSpacerClass = params;
         _state.hsntsnClass = params;
         _state.headerClass = params;
         this.setState(_state);
      };
      this.HsnTsnMiddleCallback = params => {
         let _state = this.state;
         _state.middleSpacerClass = params;
         _state.headerClass = params;
         this.setState(_state);
      };
      this.hsntsnSizeCallback = params => {
         let _state = this.state;
         _state.hsntsnSize = params;
         this.setState(_state);
      };

      this.ManualClassCallback = params => {
         let _state = this.state;
         _state.manualSelectionClass = params;
         this.setState(_state);
      };

      this.myCallback = carlist => {
         let newstate = this.state;
         newstate.carlist = carlist;
         this.setState(newstate);
         /*
         * TODO: if(carlist.length === 1) { zu car/carlist[0].id navigieren }
         * */
      };

      this.buttonCallback = (props) => {
         let newstate = this.state;
         newstate.buttonpushed = 'clicked';
         this.setState(newstate);
      };

      this.resetButton = (props) => {


      };
      this.resetStateCallback = (param) => {
         let _state = this.state;
         if (param == 'manufacturer') {
            _state.hsntsnClass = 'd-block';
            _state.middleSpacerClass = 'd-block';
            _state.manualSelectionClass = 'col-md-4';
            _state.manualSelectionVisibility = 'd-block';
            _state.detailClass = 'd-none';
         } else if (param == 'type') {
            _state.selectedCar = null;
            _state.selectedCarDetails = null;
            _state.detailClass = 'd-none';
            _state.manualSelectionVisibility = 'd-block';
         }
         _state.resetButtonValue = param;
         this.setState(_state);

      };

   }

   componentDidMount() {
      history = createBrowserHistory();
      if (process.env.REACT_APP_CLUB == "ps-rims" || process.env.REACT_APP_CLUB == "am-rims") {
         this.setState({rimPath: ''});
      } else if (process.env.REACT_APP_CLUB == "points" || process.env.REACT_APP_CLUB == "automeister" || process.env.REACT_APP_CLUB == "anag") {
         this.setState({rimPath: '/rimconfigurator'});
      }
   }

   render() {
      return (
         <div className="rimconfigurator-wrapper">
            <CarSelectionHeader
               history={history}
               headerClass={this.state.headerClass}
               buttonCallback={this.buttonCallback}
               resetStateCallback={this.resetStateCallback}
            />
            <div className="container car-selection">
               <div className="row">
                  <div className={this.state.manualSelectionClass + ' ' + this.state.manualSelectionVisibility}
                       id="manualSelection">
                     <CarByManualSelection
                        rimPath={this.state.rimPath}
                        context="start"
                        classCallback={this.ManualClassCallback}
                        middleCallback={this.ManualMiddleCallback}
                        callbackFunction={this.ManualCallback}
                        manufacturervisibility={this.state.manufacturervisibility}
                        buttonpushed={this.state.buttonpushed}
                        resetButton={this.resetButton}
                        history={this.props.history}
                        routes={this.props.routes}
                        manufacturerId={this.props.match && this.props.match.params.manufacturerId ? this.props.match.params.manufacturerId : null}
                        model={this.props.match && this.props.match.params.modelId ? this.props.match.params.modelId : null}
                        type={this.props.match && this.props.match.params.typesId ? this.props.match.params.typesId : null}
                        resetButtonValue={this.state.resetButtonValue}
                     />
                  </div>
                  <div
                     className={this.state.middleSpacerClass + ' col middle-spacer'}>
                     <div className="vertical-separator">
                        <div className="borderbox"/>
                     </div>
                     <div className="separator-text d-none d-md-block">
                        oder
                     </div>
                     <div className="vertical-separator">
                        <div className="borderbox"/>
                     </div>
                  </div>
                  <div className={this.state.hsntsnClass + ' ' + this.state.hsntsnSize} id="hsntsnSelection">
                     <CarByHsnTsn
                        context="start"
                        callbackFunction={this.HsnTsnCallback}
                        middleCallback={this.HsnTsnMiddleCallback}
                        hsntsnSizeCallback={this.hsntsnSizeCallback}
                        ManualClassCallback={this.ManualClassCallback}
                        switchmanufacturercallback={this.switchmanufacturercallback}
                     />
                  </div>
               </div>
               <div className={this.state.detailClass}>
                  <CarDetails
                     rimPath={this.state.rimPath}
                     selectedCar={this.state.selectedCar}
                     selectedCarDetails={this.state.selectedCarDetails}
                     context="start"
                     callbackFunction={this.DetailCallback}
                     buttonpushed={this.state.buttonpushed}
                  />
               </div>
            </div>
            <Helmet>
               <title>Felgenkonfigurator</title>
            </Helmet>
         </div>
      );
   }
}

const mapStateToProps = state => ({
   firmData: state.firmData
});
const mapDispatchToProps = {
   // TODO
   // fetchCarsSSR
};
export default connect(mapStateToProps, mapDispatchToProps)(CarSelection);
