import {getTyreDetails, getTyres} from '../api';
import * as actionTypes from "./actionTypes";
import axios from "axios";
import {load} from 'dotenv';

const storeTyres = tyres => ({
   type: actionTypes.STORE_selectedTyreS,
   tyres: tyres.tires,
   filters: tyres.filters
});

export const loadTyres = () => ({
   type: actionTypes.LOAD_selectedTyreS
});


const fetchTyresError = (error) => ({
   type: actionTypes.FETCH_selectedTyreS_ERROR,
   error: error
});


// SSR Search
export const fetchTyresSSR = (requestBody, env, page, sort, club) => dispatch => {
   const values = JSON.parse(
      Buffer.from(requestBody.searchString, "base64").toString("utf8")
   );
   let environment = env;
   let pageSize = 25;
   let mandatorStoreNo = values.mandatorStoreNo;
   let pageNr = page;
   let width = values.length;
   let height = values.height;
   let diameter = values.diameter;
   let sortValue = sort;
   if (sortValue === {} || sortValue === undefined) {
      sortValue = {sortDir: "ASC", sortField: "minPrice"};
   }
   let limit = values.limit ? values.limit : 20;
   limit = String(limit);
   let dimension = String(width) + String(height) + String(diameter);
   let seasons = values.season;
   if (seasons === "summer") {
      seasons = "season_summer";
   } else if (seasons === "winter") {
      seasons = "season_winter";
   } else if (seasons === "all") {
      seasons = "season_all";
   } else {
      // fallback
      seasons = "season_summer";
   }
   let vehicleType = values.vehicleType;
   if (vehicleType === "pkw") {
      vehicleType = "vehicle_pc";
   } else if (vehicleType === "lkw") {
      vehicleType = "vehicle_t";
   } else if (vehicleType === "suv") {
      vehicleType = "vehicle_suv";
   } else {
      // fallback
      vehicleType = "vehicle_pc";
   }
   let vehicleTypeSelection = null;
   vehicleTypeSelection = {id: "VEHICLE_TYPE", values: [vehicleType]};
   let loadIndex = values.load;
   let loadIndexSelection = null;
   if (loadIndex) {
      if (loadIndex === "Alles") {
         // loadIndexSelection = {"id":"LOAD_INDEX","values": ["89", "91", "94", "98"]};
      } else {
         loadIndexSelection = {id: "LOAD_INDEX", values: [loadIndex]};
      }
   }
   let speedIndex = values.speed;
   let speedIndexSelection = null;
   if (speedIndex) {
      if (speedIndex === "Alles") {
      } else {
         speedIndexSelection = {id: "SPEED_INDEX", values: [speedIndex]};
      }
   }
   let filterSelections = null;
   filterSelections = values.filterValues;
   let resultArray = null;
   let result = null;
   if (values.filterValues && values.filterValues.length !== 0) {
      resultArray = values.filterValues;
      resultArray = {
         ...filterSelections,
         vehicleTypeSelection
      };
      if (loadIndexSelection) {
         resultArray = {
            ...resultArray,
            loadIndexSelection
         };
      }
      if (speedIndexSelection) {
         resultArray = {
            ...resultArray,
            speedIndexSelection
         };
      }
      result = Object.values(resultArray);
   } else {
      result = [vehicleTypeSelection];
   }

   let requestJson;
   if (process.env.CLUB || club === 'anag') {
      let ggFilterDTO = {};
      for (let item in filterSelections) {
         if (filterSelections[item].values && filterSelections[item].values.length > 0) {
            let name = 'TYRE_' + filterSelections[item].id;
            if ((filterSelections[item].id === 'RFT')) {
               name = 'TYRE_RUN_FLAT'
            }
            ggFilterDTO[name] = [];
            for (let x in filterSelections[item].values) {
               let value = filterSelections[item].values[x];
               if (name == 'TYRE_BRAND') {
                  value = value.toUpperCase()
               }

               ggFilterDTO[name].push(value)
            }
         }
      }
      let ggVehicleTypeSelection;
      if (vehicleTypeSelection.values && vehicleTypeSelection.values.length > 0) {
         ggFilterDTO.TYRE_PRODUCT_CATEGORY = [];
         for (let x of vehicleTypeSelection.values) {
            if (x == 'vehicle_pc') {
               ggVehicleTypeSelection = 'category_pc'
            } else if (x == 'vehicle_suv') {
               ggVehicleTypeSelection = 'category_suv'
            } else {
               ggVehicleTypeSelection = 'category_lt'
            }
         }
      }
      if (loadIndex && loadIndex !== '' && loadIndex !== 'Alles')
         ggFilterDTO.TYRE_LOAD_INDEX = [loadIndex];
      if (speedIndex && speedIndex !== '' && speedIndex !== 'Alles')
         ggFilterDTO.TYRE_SPEED_INDEX = [speedIndex];
      let ggSeason;
      if (seasons) {
         ggSeason = [seasons.substring(7).toUpperCase()];
         ggFilterDTO.TYRE_PRODUCT_CATEGORY[0] = ggVehicleTypeSelection + "_" + ggSeason;
         ggFilterDTO.TYRE_PRODUCT_CATEGORY[0] = ggFilterDTO.TYRE_PRODUCT_CATEGORY[0].toLowerCase();
      }
      requestJson = {
         "page": pageNr,
         "pageSize": pageSize,
         "sortData": null,
         "width": width,
         "section": height,
         "diameter": diameter,
         "brandNames": null,
         "matchCode": width + height + diameter,
         "mandatorStoreNumber": mandatorStoreNo,
         "minAvailableAmount": 4,
         "speedIndeces": null,
         "loadIndeces": null,
         "productCategories": null,
         "productCategoryIds": null,
         "productCategoryName": null,
         "qualities": null,
         "tread": null,
         "oeMark": null,
         "oeMarkCarProducers": null,
         "tpmsProductId": null,
         "xl": null,
         "commercial": null,
         "runflat": null,
         "mAndSmark": null,
         "allSeason": null,
         "activeFiltersValues": null,
         "withTest": false,
         "onlyMarketPlace": false,
         "showDOT": false,
         "showRETREADED": true,
         "minRollingResistance": null,
         "maxRollingResistance": null,
         "minWetGrip": null,
         "maxWetGrip": null,
         "minNoiseDb": null,
         "maxNoiseDb": null,
         "rimId": null,
         "frontRimReady": false,
         "carId": null,
         "minLoadIndex": null,
         "useAllBrandNames": false,
         "hideDiscontinuedTyres": false,
         "searchBulkOrderSuppliers": null,
         "useRearTyreSearchLogic": false,
         "tyreProductId": null,
         "fleetCustomer": false,
         "filterValues": ggFilterDTO
      }
   } else requestJson = {
      debitorNo: mandatorStoreNo,
      width: width,
      height: height,
      diameter: diameter,
      sort:
         sortValue === {} ? {sortDir: "ASC", sortField: "minPrice"} : sortValue,
      page: pageNr,
      pageSize: pageSize,
      season: seasons,
      showDotAndDemo: 'false',
      b2c: 'true',
      showQualityGroup: 'true',
      filterSelections: result
   };

   requestJson = JSON.stringify(requestJson);
   //dispatch(loadTyres());
   return getTyres(requestJson, environment).then(
      function (res) {
         if (res.Error != "" && res.Error != null && res.Error != undefined) {
            dispatch(fetchTyresError(res.Error));
         } else {
            dispatch(storeTyres(res));
         }
      }
   );
};

