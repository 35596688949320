import React from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import * as actionCreators from '../../../../redux-store/actions/index';
import Address from '../../shared/address/Address';
import MapContainer from '../../shared/Maps/Maps';

class Contact extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         passedvalues: {
            counter: 0,
            address: ''
         },
         mapData: 0,
         mapReloaded: false
      };
   }

   componentDidMount() {
      this.props.onResetTermsOfUse();
      this.props.onResetDataProtection();
      if (this.props && this.props.callBackFromParent) {
         this.props.callBackFromParent(this.state);
      }
      this.props.onSetNavigation('/contact');
   }

   fillData(dataFromChild) {
      this.setState({passedvalues: dataFromChild});
   }

   myCallback(dataFromChild) {
      this.setState({passedvalues: dataFromChild});
      if (this.props && this.props.callBackFromParent) {
         this.props.callBackFromParent(this.state);
      }
   }

   mapCallback(dataFromChild) {
      this.setState({mapData: dataFromChild});
   }

   navigationCallBack(data) {
      this.props.navigationCallBack();
   }

   mapHandler() {
      return (
         <div className="map-wrapper">
            <MapContainer
               callBackFromParent={this.mapCallback.bind(this)}
               firmData={this.props.firmData}
               data={this.state.passedvalues}
            />
         </div>
      );
   }

   render() {
      if (this.props && this.props.callBackFromParent) {
         this.props.onSetNavigation('/shoppingcart');
         this.props.callBackFromParent(this.state);
      } else {
         this.props.onSetNavigation('/contact');
      }
      return (
         <div className="container">
            <div className="contact-wrapper">
               {this.props.history ?
                  <h1>Kontakt</h1>
                  :
                  <div className="row">
                     <div className="col-12 col-md-6 offset-md-6">
                        <h1 className="mb-3">Wählen Sie Ihr Autohaus aus</h1>
                     </div>
                  </div>
               }
               <div className="row">
                  <div className="col-12 col-md-6 order-md-last">
                     <Address
                        firmData={this.props.firmData}
                        passedValues={this.state.passedvalues}
                        callBackFromParent={this.myCallback.bind(this)}
                        mapCounter={this.state.mapData}
                        isCheckout={this.props.callBackFromParent ? true : false}
                        navigationCallBack={this.navigationCallBack.bind(this)}
                     />
                  </div>
                  <div className="col-11 d-none d-md-block col-md-6 order-md-first">{this.mapHandler()}</div>
               </div>
               <Helmet>
                  <title>Kontakt</title>
                  <meta name="description"
                        content="This is a proof of concept for React SSR"
                  />
               </Helmet>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => ({
   firmData: state.firmData,
   tyres: state.tyres
});

const mapDispatchToProps = {
   onSetNavigation: location => actionCreators.setNavigation(location),
   onResetTermsOfUse: () => actionCreators.resetTermsOfUse(),
   onResetDataProtection: () => actionCreators.resetDataProtection()
};
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
