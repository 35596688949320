import React from 'react';

let i;
let arr = [];
const data = {
   address: [],
   counter: 0
};
let buff = -1;
let markerPriority = false;

class Address extends React.Component {
   constructor(props) {
      super(props);
      this.state = {counter: 0};
   }

   openingTimesHider(e) {
      markerPriority = true;
      const newstate = this.state;
      newstate.counter = e.currentTarget.id;
      data.counter = e.currentTarget.id;
      this.setState(newstate);
      this.props.callBackFromParent(data);
   }

   contactCardsActiveHandler(ii) {
      let clsnm = 'contact-cards';
      if (this.state.counter == ii) {
         clsnm = 'contact-cards active';
      }
      return clsnm;
   }

   contactPageBuilder() {
      arr = [];

      if (this.props && this.props.firmData && this.props.firmData[0] && this.props.firmData[0].AllgemeineDaten) {
         if (this.props.mapCounter != this.state.counter) {
            if (markerPriority === false) {
               buff = this.state.counter;
               this.setState({counter: this.props.mapCounter});
            } else {
               markerPriority = false;
               buff = this.props.mapCounter;
            }
         }

         let ii = 0;
         let keys = 1;

         if (this.props && this.props.hauptsitz !== true) {
            keys = Object.keys(this.props.firmData).length;
         }
         while (ii < keys) {
            let Clss = 'hidden';
            if (this.state.counter == ii && this.props.openingTimesCount !== 'hidden') {
               Clss = 'visible';
            }
            i = this.props.firmData[ii].AllgemeineDaten;
            data.address[ii] = (`${i.Strasse} , ${i.Plz} , ${i.Ort} , ${i.Land}`);
            arr[ii] = (
               <div className={`${this.contactCardsActiveHandler(ii)}`} key={ii} id={ii}
                    onClick={this.openingTimesHider.bind(this)}>
                  <div className="contact-info-name-highlight w-100 mb-3">{i.Name}</div>
                  <div className="row">
                     <div className="col-12">
                        <div className="contact-info-text"> {i.Strasse} </div>
                        <div className="contact-info-text"> {i.Plz} {i.Ort} </div>
                        {(() => {
                           if (i.Land === 'DE') {
                              return <div className="contact-info-text"> Deutschland </div>
                           }
                        })()}
                        <div className="contact-info-text mt-3">
                           <i className="fas fa-phone-alt mr-2"/>
                           {i.Telefon}
                        </div>
                        <div className="contact-info-text mt-2">
                           <i className="far fa-envelope mr-2"/>
                           {i.Email}
                        </div>
                     </div>
                     {(Clss == 'visible') ?
                        <div className="col-12 text-right">
                           {this.props.isCheckout === true ?
                              <div>
                                 <button onClick={this.props.navigationCallBack} className="btn btn-primary">
                                    Auswahl
                                 </button>
                              </div>
                              : ''
                           }
                        </div>
                        : ''
                     }
                  </div>
               </div>
            );
            ii++;
         }
         buff = this.state.counter;
         return arr;
      }
      return (
         <div>Konnte keine Händlerdaten finden</div>
      );
   }

   render() {
      return (
         <div className={"overflow-y-hidden"}>
            {this.contactPageBuilder()}
         </div>
      );
   }
}

export default Address;
