import React from 'react';
import Magnifier from 'react-magnifier';

class CarImage extends React.Component {
   constructor(props) {
      super(props);
      this.getBackgroundStyle = this.getBackgroundStyle.bind(this);
   }

   getBackgroundStyle() {
      return {
         backgroundImage: 'url(' + this.props.images.backgroundImageUrl + ')',
         backgroundRepeat: 'no-repeat',
         backgroundPosition: 'center',
         backgroundSize: 'auto 100%',
         height: '100%'
      };
   }

   render() {
      return (
         <div style={this.getBackgroundStyle()} className={'car-view-background'}>
            <div className={'car-view-rims'}>
               {this.props.base64img ?
                  <Magnifier style={{'position': 'relative', 'top': this.props.lowering}}
                             mgWidth={220} mgHeight={220} mgTouchOffsetX={15} mgTouchOffsetY={0}
                             zoomFactor={0.9} src={this.props.carImage}
                             zoomImgSrc={this.props.base64img}
                             className={'car-view-image'}
                             mgShape='square'/>
                  :
                  <img src={`/carImage/${this.props.carId}/${this.props.rimId}/${this.props.color}`}
                       className={'car-view-image'}
                       style={{'position': 'relative', 'top': this.props.lowering}}/>
               }
            </div>
         </div>
      );
   }
}

CarImage.serverFetch = '';
const mapStateToProps = state => ({});

export default CarImage;
